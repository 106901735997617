import { MaterialReactTable } from "material-react-table";

import ColumnsRiskForProjectBaseline from "@/features/risk/columns/ColumnsForRiskTable";
import RiskActions from "@/features/risk/components/RiskActions";
import { RiskBaseModel } from "@/features/risk/models/riskModels";
import { OverviewItemsModel } from "@/shared/components/baselineOverview";

type TableForRiskProps = {
  risks: RiskBaseModel[];
  handleSaveCell: (original: RiskBaseModel, name: string, value: string) => void;
  refetchRisk: () => void;
  overviewItems: OverviewItemsModel[];
};

export default function TableForRisk({
  risks,
  handleSaveCell,
  refetchRisk,
  overviewItems,
}: TableForRiskProps) {
  const columns = ColumnsRiskForProjectBaseline({ overviewItems });
  return (
    <MaterialReactTable
      columns={columns}
      layoutMode="grid"
      data={risks}
      initialState={{
        density: "compact",
        columnPinning: {
          right: ["mrt-row-actions"],
        },
      }}
      positionToolbarAlertBanner="none"
      muiTableHeadCellProps={{
        sx: () => ({
          background: "var(--primary-color)",
          align: "center",
          color: "#fff",
          fontSize: "12px",
          overflowX: "hidden",
        }),
      }}
      muiTableBodyCellProps={{
        align: "right",
        sx: {
          border: "none",
          background: "#fff",
        },
      }}
      muiTableFooterCellProps={{
        sx: {
          background: "#fff",
        },
      }}
      muiBottomToolbarProps={{
        sx: {
          background: "#fff",
        },
      }}
      muiTableProps={{
        sx: {
          tableLayout: "fixed",
        },
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: 700,
          overflow: "auto",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px !important",
            backgroundColor: "#f5f5f5",
            height: "12px !important",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "#ccc",
            "&:hover": {
              backgroundColor: "#b3b3b3",
            },
            "&:active": {
              backgroundColor: "#808080",
            },
          },
        },
      }}
      editDisplayMode="cell"
      enableGrouping
      enableEditing
      enableColumnResizing
      enableColumnActions={false}
      enableColumnFilters={false}
      enableTopToolbar={false}
      muiEditTextFieldProps={({ row }) => ({
        onBlur: (event) => {
          const { name, value } = event.target;

          handleSaveCell(row.original, name, value);
        },
      })}
      enablePagination
      enableSorting
      enableRowActions
      renderRowActions={({ row }) => (
        <RiskActions
          riskId={row.original.id}
          onRiskDeleted={() => {
            refetchRisk();
            // refetchProjectWBS();
          }}
        />
      )}
    />
  );
}
