import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function BreadcrumbSkeleton() {
  const SkeletonStyles = {
    bgcolor: "var(--ecerto-cool-grey-light)",
    "&::after": {
      bgcolor: "text.primary",
    },
    marginBottom: 2,
    marginLeft: 0.1,
    marginRight: 0.1,
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
      <Skeleton
        sx={{ ...SkeletonStyles, mx: 2.3 }}
        variant="text"
        width="10%"
        height={20}
      />
      <Skeleton sx={SkeletonStyles} variant="circular" width={15} height={15} />
      <Skeleton
        sx={{ ...SkeletonStyles, mx: 2.3 }}
        variant="text"
        width="20%"
        height={20}
      />
      <Skeleton sx={SkeletonStyles} variant="circular" width={15} height={15} />
      <Skeleton
        sx={{ ...SkeletonStyles, mx: 2.3 }}
        variant="text"
        width="20%"
        height={20}
      />
      <Skeleton sx={SkeletonStyles} variant="circular" width={15} height={15} />
      <Skeleton
        sx={{ ...SkeletonStyles, mx: 2.3 }}
        variant="text"
        width="20%"
        height={20}
      />
    </Box>
  );
}
