import { Paper } from "@mui/material";

import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";

import OverviewDetailCard from "./OverviewDetailCard";
import OverviewTable from "./OverviewTable";

type OverviewDetailCardTypes = {
  wbsEntry: WBSEntryModel;
};

export default function OutcomeOverview({ wbsEntry }: OverviewDetailCardTypes) {
  return (
    <Paper
      elevation={6}
      sx={{
        display: "flex",
        borderRadius: "0.3rem",
        overflow: "hidden",
      }}>
      <OverviewDetailCard wbsEntry={wbsEntry} />
      <OverviewTable wbsEntry={wbsEntry} />
    </Paper>
  );
}
