import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./userSlice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
});

// Export actions of the various slices
export const { setUser, clearUser } = userSlice.actions;
// ... other slices ...

export default store;

// https://react-redux.js.org/using-react-redux/usage-with-typescript
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
