import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import ListItemWithIcon from "@/features/tenantManagement/components/ListItemWithIcon";
import { avatarStyles } from "@/features/tenantManagement/styles/avatarStyles";
import { sideMenuStyles } from "@/features/tenantManagement/styles/sideMenuStyles";
import RListButton from "@/shared/components/RListButton";
import { useTenantManagement } from "@/shared/context/tenantManagementCtx/useManagementTenant";
import TenantManagementView from "@/shared/enums/tenantManagement";
import { User } from "@/shared/types/userInfoModels";
import { useAuth } from "@/shared/utils/auth";
import { stringAvatar } from "@/shared/utils/formattingFunctions";

type SideMenuProps = {
  user: User;
};

export default function SideMenu({ user }: SideMenuProps) {
  const { logOut } = useAuth();
  const { activeContent, updateActiveContent } = useTenantManagement();

  const logOutHandler = () => {
    void logOut();
  };

  return (
    <Box sx={sideMenuStyles.box}>
      {/* Profile and User Information Section */}
      <List>
        <ListItem sx={sideMenuStyles.listItem}>
          <Avatar sx={avatarStyles.avatar}>
            <Typography sx={avatarStyles.avatarText}>
              {stringAvatar(user.fullName)}
            </Typography>
          </Avatar>

          <ListItemText
            sx={sideMenuStyles.listItemText}
            primary={
              <Typography sx={sideMenuStyles.profileName}>{user.fullName}</Typography>
            }
            secondary={
              <Typography sx={sideMenuStyles.roleName} component="span" variant="body2">
                {user.role}
              </Typography>
            }
          />
        </ListItem>

        {/* User Contact and Details */}
        <ListItemWithIcon icon={<EmailIcon />} text={user.emailAddress} />
        <ListItemWithIcon icon={<BusinessOutlinedIcon />} text="eCERTO" />
        <ListItemWithIcon icon={<GroupsOutlinedIcon />} text="eTECHNOLOGY" />
        <ListItemWithIcon icon={<WorkOutlineOutlinedIcon />} text={user.jobTitle} />
        <ListItemWithIcon icon={<LocationOnOutlinedIcon />} text={user.officeLocation} />

        {/* Divider for separating sections */}
        <Divider sx={sideMenuStyles.divider} variant="middle" flexItem />

        {/* Buttons for managing account and users */}
        <RListButton
          icon={<SettingsIcon />}
          onClick={() => {
            updateActiveContent(TenantManagementView.Profile); // Open UserForm
          }}
          textSize="1rem"
          active={activeContent === TenantManagementView.Profile}>
          Manage Your Account
        </RListButton>
        <RListButton
          icon={<ManageAccountsIcon />}
          onClick={() => {
            updateActiveContent(TenantManagementView.ManageUsers); // Open UserManagementTable
          }}
          textSize="1rem"
          active={activeContent === TenantManagementView.ManageUsers}>
          Manage Users
        </RListButton>
      </List>

      {/* Logout Button */}
      <Box sx={sideMenuStyles.logOutBox}>
        <Button
          onClick={logOutHandler}
          variant="outlined"
          sx={sideMenuStyles.logOutButton}
          startIcon={<LogoutIcon />}>
          Log out
        </Button>
      </Box>
    </Box>
  );
}
