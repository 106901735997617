import { SxProps, Theme } from "@mui/material";
import Button from "@mui/material/Button";

type PrimaryButtonProps = {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  width?: string;
  sx?: SxProps<Theme>;
  icon?: React.ReactNode;
};

const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement>) => {
  const { currentTarget } = e;
  currentTarget.style.transform = "scale(1.05)";
};

const handleMouseOut = (e: React.MouseEvent<HTMLButtonElement>) => {
  const { currentTarget } = e;
  currentTarget.style.transform = "";
};

export default function PrimaryButton({
  onClick,
  children,
  width = "fit-content",
  sx,
  icon,
}: PrimaryButtonProps) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        width,
        maxWidth: width,
        marginTop: "24px",
        transition: "transform 0.2s",
        "&:focus": {
          outline: "none",
        },
        "&:hover": {
          color: "white",
        },
        marginLeft: "0.5rem",
        ...sx,
      }}
      onMouseOver={(e) => handleMouseOver(e)}
      onMouseOut={(e) => handleMouseOut(e)}>
      <span style={{ display: "flex", alignItems: "center" }}>
        {icon && (
          <span
            style={{
              marginRight: "5px",
              display: "flex",
              alignItems: "center",
            }}>
            {icon}
          </span>
        )}
        {children}
      </span>
    </Button>
  );
}
