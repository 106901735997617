import { Tabs, useTheme } from "@mui/material";
import { useParams, useLoaderData } from "react-router-dom";

import { CostTable, CostModelForProjectBaseline } from "@/features/cost";
import { ParametricModelTable } from "@/features/parametric";
import { RiskTable, RiskBaseModel } from "@/features/risk";
import CustomTab from "@/shared/components/CustomTab";
import CustomTabPanel from "@/shared/components/CustomTabPanel";
import { BaselineTab } from "@/shared/context/conceptContext/ConceptContext";
import useConceptContext from "@/shared/context/conceptContext/useProjectContext";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { ParameterModels } from "@/shared/types/parameterModels";

export type ProjectBaselineRouteProps = {
  parametric: ParameterModels[];
  cost: CostModelForProjectBaseline[];
  risks: RiskBaseModel[];
};

export default function ProjectBaseline() {
  const theme = useTheme();
  // This is used to display error messages when the data in the table is not valid.

  // These are used to determine which data to display and which tab to highlight in the UI.
  const { outcome: wbsEntryId } = useParams();

  // This data is used to populate the tables in the UI.
  const { parametric, cost, risks } = useLoaderData() as ProjectBaselineRouteProps;

  // This is used to switch between different tabs in the UI. If no tab is specified in the URL, it defaults to 'parametric'.
  const { updateBaselineTab, baselineTab } = useConceptContext();

  const { getWBSEntryById } = useProjectWBSContext();

  const WBSEntry = getWBSEntryById(wbsEntryId);
  if (!WBSEntry) return null;

  if (WBSEntry.level < 3) {
    return <ParametricModelTable parameters={parametric} includeOnlyOutcome />;
  }

  if (WBSEntry.level === 3) {
    return (
      <>
        <Tabs
          onChange={(_e, newValue: BaselineTab) => updateBaselineTab(newValue)}
          value={baselineTab}
          aria-label="tabs"
          TabIndicatorProps={{
            style: {
              background: theme.palette.primary.main,
            },
          }}>
          <CustomTab<BaselineTab>
            label="Parametric"
            value="parametric"
            to={`../project-baseline/${wbsEntryId}/parametric`}
            setActiveTab={updateBaselineTab}
          />
          <CustomTab<BaselineTab>
            label="Cost"
            to={`../project-baseline/${wbsEntryId}/cost`}
            value="cost"
            setActiveTab={updateBaselineTab}
          />
          <CustomTab<BaselineTab>
            label="Risk"
            to={`../project-baseline/${wbsEntryId}/risk`}
            value="risk"
            setActiveTab={updateBaselineTab}
          />
        </Tabs>
        <CustomTabPanel name="parametric" value={baselineTab}>
          <ParametricModelTable parameters={parametric} />
        </CustomTabPanel>
        <CustomTabPanel name="cost" value={baselineTab}>
          <CostTable data={cost} parametricData={parametric} />
        </CustomTabPanel>
        <CustomTabPanel name="risk" value={baselineTab}>
          <RiskTable risksData={risks} parametricData={parametric} />
        </CustomTabPanel>
      </>
    );
  }
}
