import { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

import { RiskBaseModel } from "@/features/risk/models/riskModels";
import { OverviewItemsModel } from "@/shared/components/baselineOverview";
import Footer from "@/shared/components/FooterForColumns";
import { formatCurrency } from "@/shared/utils";

const ColumnsForRiskTable = ({
  overviewItems,
}: {
  overviewItems: OverviewItemsModel[];
}) =>
  useMemo<MRT_ColumnDef<RiskBaseModel>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        minSize: 90,
        size: 150,
        maxSize: 250,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ cell }) => (
          <div
            style={{
              fontWeight: "bold",
            }}>
            {`${cell.getValue<string>()}`}
          </div>
        ),
      },
      {
        accessorKey: "probabOfOccurrence",
        header: "Probability",
        size: 70,
        enableClickToCopy: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell }) => (
          <div
            style={{
              fontWeight: "inherit",
            }}>
            {`${cell.getValue<number>()}%`}
          </div>
        ),
      },
      {
        accessorKey: "modeDuration",
        header: "Duration (days)",
        size: 85,
        muiTableBodyCellProps: {
          align: "right",
        },
      },
      {
        accessorKey: "modeRemedialCost",
        header: "Remedial Cost",
        minSize: 75,
        size: 90,
        maxSize: 150,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ cell }) => (
          <div
            style={{
              fontWeight: "inherit",
            }}>
            {formatCurrency(cell.getValue<number>())}
          </div>
        ),
      },
      {
        accessorKey: "undefined",
        header: "Emissions",
        size: 60,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: () => (
          <div
            style={{
              fontWeight: "inherit",
            }}>
            0.00
          </div>
        ),
      },
      {
        accessorKey: "impactCost",
        header: "Impact: Cost",
        size: 100,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-red)" },
          sx: {
            "& .MuiDivider-root": {
              border: "2px solid var(--ecerto-red-dark) !important",
            },
          },
        }),

        Cell: ({ cell }) => (
          <div
            style={{
              fontWeight: "inherit",
            }}>
            {formatCurrency(cell.getValue<number>())}
          </div>
        ),
        Footer: () => <Footer id="impactCost" items={overviewItems} />,
      },
      {
        accessorKey: "impactDuration",
        header: "Impact: Duration (days)",
        size: 110,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-red)" },
          sx: {
            "& .MuiDivider-root": {
              border: "2px solid var(--ecerto-red-dark) !important",
            },
          },
        }),
        Cell: ({ cell }) => (
          <div
            style={{
              fontWeight: "inherit",
            }}>
            {`${cell.getValue<number>().toFixed(2)}`}
          </div>
        ),
        Footer: () => <Footer id="impactDuration" items={overviewItems} />,
      },
      {
        accessorKey: "none",
        header: "Impact: Emissions",
        size: 100,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        muiTableHeadCellProps: () => ({
          style: { backgroundColor: "var(--ecerto-red)" },
          sx: {
            "& .MuiDivider-root": {
              border: "2px solid var(--ecerto-red-dark) !important",
            },
          },
        }),
        Cell: () => (
          <div
            style={{
              fontWeight: "inherit",
            }}>
            0.00
          </div>
        ),
        Footer: () => <Footer id="impactEmissions" items={overviewItems} />,
      },
    ],
    [overviewItems]
  );

export default ColumnsForRiskTable;
