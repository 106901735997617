import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { FormikErrors } from "formik";
import { useState, ChangeEvent, FocusEvent } from "react";

type CustomTextFieldProps = {
  name: string;
  label: string;
  value: string | number | null;
  type: string;
  errors: Record<string, string>;
  touched: Record<string, boolean>;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleBlur: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setFieldTouched: (
    field: string,
    isTouched: boolean,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<unknown>>;
  validateField: (field: string) => Promise<void> | Promise<string | undefined>;
  maxLength: number;
  InputProps?: string | number | null;
  required?: boolean;
};

export default function CustomTextField({
  name,
  label,
  value,
  type,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldTouched,
  validateField,
  maxLength,
  InputProps,
  required,
}: CustomTextFieldProps) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <TextField
      required={required}
      type={type}
      label={label + (touched[name] && errors[name] ? ` ${errors[name]}` : "")}
      name={name}
      value={value ?? ""}
      onBlur={(event) => {
        handleBlur(event);
        setIsFocused(false);
      }}
      onFocus={() => setIsFocused(true)}
      onChange={(event) => {
        handleChange(event);
        void setFieldTouched(name, true, true); // Use void to explicitly ignore the returned promise
        setTimeout(() => {
          void validateField(name); // Use void to explicitly ignore the returned promise
        });
      }}
      error={touched[name] && Boolean(errors[name])}
      InputProps={{
        endAdornment: <InputAdornment position="end">{InputProps}</InputAdornment>,
      }}
      helperText={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <FormHelperText style={{ textAlign: "right" }}>
            {isFocused || (touched[name] && Boolean(errors[name]))
              ? `${value?.toString().length}/${maxLength}`
              : " "}
          </FormHelperText>
        </div>
      }
      fullWidth
    />
  );
}
