import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Formik, Form, FormikProps } from "formik";
import { MRT_Row } from "material-react-table";
import { RefObject } from "react";
import * as Yup from "yup";

import LifeCycleStage from "@/shared/enums/lifeCycleStage";
import Risk, { getRiskColorFromIndex } from "@/shared/enums/risk";
import ProjectScenarios from "@/shared/enums/scenarios";
import { getTextColorTemp } from "@/shared/utils";

import { EditConceptModel } from "../../models/editConceptModel";
import { PortfolioRowModel } from "../../models/portfolioModel";
import editConcept from "../../services/editConceptService";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .max(100, "Name should not be longer than 75 characters"),
  selectedScenario: Yup.string().required("Please select a scenario"),
  lifeCycleStage: Yup.number().required("Please select a life cycle stage"),
  riskLevel: Yup.number().required("Please select a risk level"),
  projectCode: Yup.number().required("Project Code is required"),
});

export type EditConceptFormProps = {
  row: MRT_Row<PortfolioRowModel>;
  onClose: () => void;
  refetchProjects: () => Promise<void>;
  innerRef: RefObject<FormikProps<EditConceptModel>>;
};

export default function EditConceptForm({
  row,
  onClose,
  refetchProjects,
  innerRef,
}: EditConceptFormProps) {
  const theme = useTheme();

  const initialValues: EditConceptModel = {
    id: row.original.id,
    type: row.original.type,
    name: row.original.name,
    programmeName: row.original.programmeName,
    selectedScenario: row.original.selectedScenario,
    region: row.original.region,
    lifeCycleStage: row.original.lifeCycleStage,
    riskLevel: row.original.riskLevel,
    netPresentValue: row.original.netPresentValue,
    internalRateOfReturn: row.original.internalRateOfReturn,
    projectCode: row.original.projectCode || 1,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          await editConcept(values);
          onClose();
          await refetchProjects(); // Ensure this promise is awaited
        } catch (err) {
          if (err instanceof Error) {
            // Throw the error to be handled by a higher-level error boundary or handler
            throw new Error(`Failed to edit concept: ${err.message}`);
          } else {
            // Throw a generic error if the error is not an instance of Error
            throw new Error("Failed to edit concept: An unknown error occurred");
          }
        }
      }}
      innerRef={innerRef}>
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <Box
            sx={{ "& .MuiTextField-root": { mb: 2, width: "100%" }, paddingTop: "1rem" }}>
            <TextField
              name="name"
              label="Concept Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              name="selectedScenario"
              label="Commercial Scenario"
              select
              value={values.selectedScenario}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.selectedScenario && Boolean(errors.selectedScenario)}
              SelectProps={{
                // @check add check for license type from backend (in user info right now)
                renderValue: (selected: unknown) => {
                  const selectedValue = selected as string;

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      {selectedValue}
                      {selectedValue !== ProjectScenarios[0] && (
                        <Chip
                          label="Pro"
                          size="small"
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                          }}
                        />
                      )}
                    </div>
                  );
                },
              }}
              helperText={touched.selectedScenario && errors.selectedScenario}>
              {Object.values(ProjectScenarios)
                .filter((value) => typeof value === "string")
                .map((value) => (
                  <MenuItem
                    key={value}
                    value={value}
                    disabled={
                      value !== ProjectScenarios[ProjectScenarios.Transactional] &&
                      value !== String(values.selectedScenario)
                    }
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    {value}
                    {value !== ProjectScenarios[ProjectScenarios.Transactional] && (
                      <Chip
                        label="Pro"
                        size="small"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                        }}
                      />
                    )}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              name="lifeCycleStage"
              label="Life Cycle Stage"
              select
              value={values.lifeCycleStage}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lifeCycleStage && Boolean(errors.lifeCycleStage)}
              helperText={touched.lifeCycleStage && errors.lifeCycleStage}>
              {Object.values(LifeCycleStage)
                .filter((value) => typeof value === "string")
                .map((value, index) => (
                  // @check change value={key} to value={displayName} if we want to send the name in the future
                  <MenuItem key={value} value={index}>
                    {value}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              name="riskLevel"
              label="Risk Level"
              select
              SelectProps={{
                renderValue: (selected) => {
                  return Risk[selected as keyof typeof Risk];
                },
              }}
              value={values.riskLevel}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.riskLevel && Boolean(errors.riskLevel)}
              helperText={touched.riskLevel && errors.riskLevel}>
              {Object.values(Risk)
                .filter((value) => typeof value === "string")
                .map((value, index) => (
                  <MenuItem
                    key={value}
                    value={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    {value}
                    <Chip
                      size="small"
                      sx={{
                        height: "10px",
                        width: "25px",
                        background: getRiskColorFromIndex(index),
                        color: getTextColorTemp(getRiskColorFromIndex(index)),
                      }}
                    />
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              required
              type="number"
              label="Project Code"
              name="projectCode"
              value={values.projectCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.projectCode)}
              helperText={touched.name && errors.projectCode}
              fullWidth
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
