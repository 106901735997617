import { avatarStyles } from "@/features/tenantManagement/styles/avatarStyles";

export const userFormStyles = {
  box: {
    "& .MuiTextField-root": { marginBottom: "2rem", width: "100%" },
    height: "43rem",
    paddingTop: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  avatarStack: {
    marginBottom: "2rem",
    marginLeft: "3rem",
  },
  avatar: {
    ...avatarStyles.avatar,
    width: "9.5rem",
    height: "9.5rem",
  },
  avatarTypography: {
    fontSize: "4rem",
  },
  stack: {
    width: "100%",
    paddingLeft: "3rem",
    paddingTop: "1rem",
  },
  textfield: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#EEF1F4",
    },
    boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.1)",
    "& .MuiFilledInput-underline::before": {
      borderBottom: "none",
    },
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: "1rem",
  },
  cancelButton: {
    width: "10rem",
  },
  saveButton: {
    marginLeft: "1rem",
    width: "10rem",
  },
};
