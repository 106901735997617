// @todo you dont need to save this into session storage, Instead save the selected tab in the URL to persist across refresh.
/*eslint-disable @typescript-eslint/no-empty-function -- allowing us to pass empty functions in createContext is the least verbose solution */
import { createContext, useState, useMemo, useEffect, useCallback } from "react";

import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

export type BaselineTab = "parametric" | "cost" | "risk";
export type ConceptContextType = {
  selectedWbsEntryId: string | null;
  baselineTab: BaselineTab;
  updateSelectedWbsEntryId: (wbsEntryId: string) => void;
  updateBaselineTab: (tab: BaselineTab) => void;
};

export const ConceptContext = createContext<ConceptContextType>({
  selectedWbsEntryId: null,
  baselineTab: "parametric",
  updateSelectedWbsEntryId: () => {},
  updateBaselineTab: () => {},
});

export default function ConceptContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { projectWBS } = useProjectWBSContext();
  const [selectedWbsEntryId, setSelectedWbsEntryId] = useState(
    sessionStorage.getItem("selectedWbsEntryId") ?? null
  );

  const [baselineTab, setBaselineTab] = useState<BaselineTab>(
    (sessionStorage.getItem("baselineTab") as BaselineTab) || "parametric"
  );
  // Initialize selectedWbsEntryId with the top level WBS entry when StrategyDefinitionLayout is first rendered
  useEffect(() => {
    if (projectWBS && !selectedWbsEntryId) {
      setSelectedWbsEntryId(projectWBS.conceptWBS.id);
    }
  }, [projectWBS, selectedWbsEntryId]);

  const updateSelectedWbsEntryId = useCallback((wbsEntryId: string) => {
    sessionStorage.setItem("selectedWbsEntryId", wbsEntryId); // @test
    setSelectedWbsEntryId(wbsEntryId);
  }, []);

  const updateBaselineTab = useCallback((tab: BaselineTab) => {
    sessionStorage.setItem("baselineTab", tab);
    setBaselineTab(tab);
  }, []);

  const value = useMemo(
    () => ({
      selectedWbsEntryId,
      baselineTab,
      updateSelectedWbsEntryId,
      updateBaselineTab,
    }),
    [baselineTab, selectedWbsEntryId, updateBaselineTab, updateSelectedWbsEntryId]
  );

  return <ConceptContext.Provider value={value}>{children}</ConceptContext.Provider>;
}
