import CloseIcon from "@mui/icons-material/Close";
import { Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from "react";

import ResourceCatalogueTable from "@/features/cost/resourceCatalogue/components/ResourceCatalogueTable";
import ReusableModal from "@/shared/components/ReusableModal";

type CostToolbarProps = {
  key: string;
  wbsEntryId: string;
  handleAddResource: () => void;
};
export default function CostToolbar({ wbsEntryId, handleAddResource }: CostToolbarProps) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ paddingBottom: "24px" }}>
      <Button
        onClick={handleOpen}
        variant="outlined"
        size="large"
        sx={{ marginTop: "4px", marginLeft: "4px", fontWeight: "bold" }}>
        Add Resource
      </Button>
      <ReusableModal
        isOpen={isModalOpen}
        title=""
        content={
          <>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography variant="h6" sx={{ marginTop: "14px" }}>
                  Resource Catalogue
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="text"
                    size="large"
                    onClick={handleClose}
                    sx={{
                      marginTop: "14px",
                      marginRight: "14px",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}>
                    <CloseIcon fontSize="large" />
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <ResourceCatalogueTable
              handleAddResource={handleAddResource}
              outcome={wbsEntryId}
            />
          </>
        }
        onClose={handleClose}
        shouldCloseOnConfirm
        confirmButtonText="Add"
        maxWidth="xl"
        fullScreen
      />
    </Box>
  );
}
