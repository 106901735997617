import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { User } from "@/shared/types/userInfoModels";

const userSlice = createSlice({
  name: "user",
  initialState: null as User | null,
  reducers: {
    setUser: (_state, action: PayloadAction<User | null>) => {
      return action.payload;
    },
    clearUser: () => {
      return null;
    },
  },
});

export default userSlice;
