import MenuList from "@mui/material/MenuList";

type RMenuProps = {
  children: React.ReactNode;
};

export default function RMenu({ children }: RMenuProps) {
  return (
    <MenuList
      sx={{
        maxWidth: "20rem",
        maxHeight: "20rem",
        margin: "1rem",
        padding: "0rem",
      }}>
      {children}
    </MenuList>
  );
}
