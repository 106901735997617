// @refactor find a better way to deal with empty values in textfield of type number. Alternatively avoid the transform in the yup schema and instead pass a null in the onChange event of the text field
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as Yup from "yup";

import { ResourcesModel } from "@/features/cost/resourceCatalogue/models/resourcesModel";
import CustomTextField from "@/shared/components/CustomTextField";
import { OutcomeUnitsNames, DurationUnitsNames } from "@/shared/enums/units";

import editResource from "../services/editResource";

const validationSchema = Yup.object({
  category: Yup.string().required("is required").max(50, "must be 50 characters or less"),
  subCategory: Yup.string()
    .required("is required")
    .max(50, "must be 50 characters or less"),
  supplier: Yup.string().required("is required").max(50, "must be 50 characters or less"),
  name: Yup.string().required("is required").max(50, "must be 50 characters or less"),
  description: Yup.string().max(150, "must be 150 characters or less"),
  fixedCost: Yup.number()
    .nullable()
    .max(9999999999999, "exceeds the maximum value allowed")
    .transform((value: number, originalValue: string | number | null): number | null => {
      return originalValue === "" ? null : value;
    }),
});

export type EditResourceCatalogueFormProps = {
  row: ResourcesModel;
  onClose: () => void;
  onCatalogueAndCostRefetch: () => void;
  innerRef: RefObject<FormikProps<ResourcesModel>>;
};

export default function EditResourceCatalogueForm({
  row,
  onClose,
  onCatalogueAndCostRefetch,
  innerRef,
}: EditResourceCatalogueFormProps) {
  const initialValues: ResourcesModel = {
    id: row.id,
    category: row.category,
    subCategory: row.subCategory,
    name: row.name,
    supplier: row.supplier,
    description: row.description,
    fixedCost: row.fixedCost,
    dynamicCost: row.dynamicCost,
    unit: row.unit,
    unitEnum: row.unitEnum,
    section: row.section,
    unitCost: row.unitCost,
  };

  const units = new Map<number, string>([...DurationUnitsNames, ...OutcomeUnitsNames]);

  const onUnitChanged = (value: number) => {
    initialValues.unitEnum = value;
    initialValues.unit = units.get(value)!;
  };

  const handleMenuItemClick = (value: number) => {
    onUnitChanged(value);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={innerRef}
      onSubmit={async (values) => {
        await editResource(values).then(() => {
          onClose();
          onCatalogueAndCostRefetch();
        });
        // @todo handle error
        // .catch((err) => {});
      }}>
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        setFieldTouched,
        validateField,
      }) => (
        <Form>
          <Box sx={{ "& .MuiTextField-root": { mb: 1 }, paddingTop: "1rem" }}>
            <CustomTextField
              name="category"
              label="Category"
              type="string"
              value={values.category}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={50}
            />
            <CustomTextField
              name="subCategory"
              label="SubCategory"
              type="string"
              value={values.subCategory}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={50}
            />
            <CustomTextField
              name="supplier"
              label="Supplier"
              type="string"
              value={values.supplier}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={50}
            />
            <CustomTextField
              name="name"
              label="Resource Name"
              type="string"
              value={values.name}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={50}
            />
            <CustomTextField
              name="description"
              label="Description"
              type="string"
              value={values.description}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={150}
            />
            <Box mb={3.4}>
              <TextField
                select
                label="Unit"
                name="unit"
                value={values.unitEnum}
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.unit && Boolean(errors.unit)}
                helperText={touched.unit && errors.unit}
                fullWidth>
                {Array.from(units).map(([value, label]) => (
                  <MenuItem
                    key={value}
                    value={value}
                    onClick={() => handleMenuItemClick(value)}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <CustomTextField
              name="fixedCost"
              label="Fixed Price"
              type="number"
              value={values.fixedCost}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={13}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
