import { FormControl, FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Formik, Form } from "formik";
import * as yup from "yup";

import ProjectRegion from "@/shared/enums/projectRegion";

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  region: yup.string().required("Please select a region"),
  company: yup.string().required("Please enter a company"),
  domain: yup.string().required("Please select a domain"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
  password: yup
    .string()
    .required("please enter a password")
    .min(12, "Password is too short, must be at least 12 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Required"),
  acceptTerms: yup
    .bool()
    .oneOf([true], "You must Accept Terms & Conditions to create an account"),
});

export default function SignUpForm() {
  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  // };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        region: "",
        company: "",
        domain: "",
        email: "",
        password: "",
        confirmPassword: "",
        acceptTerms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(_values, { setSubmitting, resetForm }) => {
        resetForm();
        setSubmitting(false);
      }}>
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
        <Box sx={{ mt: 1 }}>
          <Form>
            <Divider variant="middle" color="#c1c2c3" />
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  required
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ minWidth: 120 }}>
                  <TextField
                    fullWidth
                    id="region-select"
                    name="region"
                    label="Region"
                    value={values.region}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    select
                    required
                    error={touched.region && Boolean(errors.region)}>
                    {Object.values(ProjectRegion)
                      .filter((value) => typeof value === "string")
                      .map((value, index) => {
                        return (
                          // @check change value={key} to value={displayName} if we want to send the name in the future
                          <MenuItem key={value} value={index}>
                            {value}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="company"
                  label="Company/Organisation"
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={touched.company && Boolean(errors.company)}
                  helperText={touched.company && errors.company}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ minWidth: 120 }}>
                  <TextField
                    fullWidth
                    select
                    id="domain-select"
                    label="Domain"
                    name="domain"
                    value={values.domain}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    error={touched.domain && Boolean(errors.domain)}
                    helperText={touched.domain && errors.domain}>
                    <MenuItem value={1}>Engineering</MenuItem>
                    <MenuItem value={2}>Computer Science</MenuItem>
                    <MenuItem value={3}>Software Engineering</MenuItem>
                  </TextField>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="new-password"
                  autoComplete="new-password"
                  value={values.password}
                  onChange={handleChange}
                  required
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="confirm-password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>
            </Grid>
            <FormControl
              required
              sx={{ mt: 2, mb: 3 }}
              error={touched.acceptTerms && Boolean(errors.acceptTerms)}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="acceptTerms"
                    id="acceptTerms"
                    value={values.acceptTerms}
                    color="primary"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label={
                  <span>
                    I have read and agree to{" "}
                    <a
                      href="link_to_user_agreement"
                      style={{
                        color: "#003057",
                        textDecoration: "underline",
                      }}>
                      User Agreement
                    </a>{" "}
                    and{" "}
                    <a
                      href="link_to_privacy_policy"
                      style={{ color: "#003057", textDecoration: "underline" }}>
                      Privacy Policy
                    </a>
                    .
                  </span>
                }
              />

              <FormHelperText>{touched.acceptTerms && errors.acceptTerms}</FormHelperText>
            </FormControl>
            <Divider variant="middle" color="#c1c2c3" />
            <Box
              sx={{
                display: "flex",
                justifyContent: { sm: "flex-start", md: "flex-end" },
                marginTop: 2,
              }}>
              <Button
                size="large"
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                sx={{
                  mt: 3,
                  mb: 3,
                  width: { xs: "100%", sm: "100%", md: "auto" },
                }}>
                Sign Up
              </Button>
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  );
}
