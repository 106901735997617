import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import OutcomeOverview from "@/features/overviewTable";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

export default function ScenarioOverview() {
  const { outcome: wbsEntryId } = useParams();
  const { getWBSEntryById } = useProjectWBSContext();
  const WBSEntry = getWBSEntryById(wbsEntryId);

  if (!WBSEntry) {
    // In your component
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="25vh">
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }

  return (
    <Box sx={{ marginTop: "20px" }}>
      <OutcomeOverview wbsEntry={WBSEntry} />
    </Box>
  );
}
