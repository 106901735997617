import { AxiosError, AxiosResponse } from "axios";

import { CloneModel } from "@/features/portfolio/models/cloneModel";
import { Concept } from "@/features/portfolio/models/portfolioModel";
import { PROJECTS_API_ENDPOINTS } from "@/shared/api/api";
import apiRequests from "@/shared/config/axiosConfig";

export default async function cloneConcept(conceptToClone: CloneModel): Promise<Concept> {
  try {
    const url = PROJECTS_API_ENDPOINTS.POST_CLONE_CONCEPT;

    const response: AxiosResponse<Concept> = await apiRequests.post(url, conceptToClone);
    const { data, status } = response;

    if (status >= 200 && status < 300) {
      return data;
    }

    // Handle specific error cases here
    switch (status) {
      case 400:
        throw new Error("Bad Request");
      case 401:
        throw new Error("Unauthorized");
      case 404:
        throw new Error("Not Found");
      default:
        throw new Error(`Unknown error occurred with status code ${status}`);
    }
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}
