import { Box, InputAdornment, TextField } from "@mui/material";
import { useRef } from "react";

import {
  formatDateToAvoidTimezoneIssues,
  commaSeparatedInputToNumber,
  updateWBSEntry,
} from "@/features/projectSchedule/helper";
import {
  InputAdornmentStyle,
  TextFieldStyle,
} from "@/features/projectSchedule/styles/styles";
import { DurationCellTypes } from "@/features/projectSchedule/types";
import { formatNumberWithCommas } from "@/shared/utils";

const BoxStyle = () => ({
  marginRight: "20px",
});

export default function DurationCell({
  scheduleEntry,
  onDurationChange,
}: DurationCellTypes) {
  const MAX_CHARACTERS = 4;
  const initialDuration = formatNumberWithCommas(scheduleEntry.duration ?? 0);
  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Limit input to 4 characters
    if (event.target.value.length > MAX_CHARACTERS) {
      // If the input is more than 4 characters, set the value to the first 4 characters
      textFieldRef.current!.value = event.target.value.slice(0, 4);
    }
  };

  const handleBlur = () => {
    const newDuration = commaSeparatedInputToNumber(textFieldRef.current?.value);
    // If the new duration is the same as the current duration, do nothing
    if (newDuration === scheduleEntry.duration) return;
    // Must transform start date to a string to avoid timezone issues e.g.(start date goes back 1 day when duration changes)
    const startDate = formatDateToAvoidTimezoneIssues(scheduleEntry.start);

    const newWBSEntry = updateWBSEntry(scheduleEntry, startDate, newDuration);

    void onDurationChange(newWBSEntry, "FromTable");
  };

  const handleFocus = () => {
    if (textFieldRef.current) {
      textFieldRef.current.type = "number";
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <Box sx={BoxStyle}>
      <TextField
        aria-label="Duration"
        type="text"
        variant="standard"
        size="small"
        disabled={scheduleEntry.isDisabled}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={InputAdornmentStyle} position="end">
              days
            </InputAdornment>
          ),
        }}
        sx={TextFieldStyle}
        defaultValue={initialDuration}
        onChange={handleInputChange}
        inputRef={textFieldRef}
        onFocus={handleFocus}
        key={scheduleEntry.duration}
        onBlur={handleBlur}
        onKeyDown={handleKeyPress}
      />
    </Box>
  );
}
