import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import { useNavigate, useLocation } from "react-router-dom";

import useConceptContext from "@/shared/context/conceptContext/useProjectContext";

const steps = [
  { label: "Scope", path: "project-scope" },
  { label: "Schedule", path: "project-schedule" },
  { label: "Baseline", path: "project-baseline" },
  { label: "Scenarios", path: "project-scenarios" },
];

export default function StrategyDefinitionStepper() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedWbsEntryId, baselineTab } = useConceptContext();

  // Extract the project ID and outcome ID from the current pathname
  const pathParts = location.pathname.split("/");
  const projectId = pathParts[2]; // Project ID is the 2nd segment in the path

  // Construct the base path with the project ID
  const basePath = `/strategy-definition/${projectId}`;

  // Determine the active step based on the current path
  let activeStep = steps.findIndex((step) => location.pathname.includes(step.path));

  if (activeStep <= 0) activeStep = 0;

  const handleStep = (path: string) => {
    // Define the paths where the outcome ID should be appended
    const pathsWithOutcomeId = ["project-baseline", "project-scenarios"];

    // Check if the current path is one of the specified paths
    const shouldAppendOutcomeId = pathsWithOutcomeId.includes(path);

    // Append outcome ID to the path if it exists and the current path requires it
    let newPath =
      shouldAppendOutcomeId && selectedWbsEntryId
        ? `${path}/${selectedWbsEntryId}`
        : path;

    // If the path is "project-baseline", append "baselineTabs" to the end of the URL
    if (path === "project-baseline") {
      newPath = `${newPath}/${baselineTab}`;
    }

    navigate(`${basePath.endsWith("/") ? basePath.slice(0, -1) : basePath}/${newPath}`);
  };

  return (
    <Box py={10} sx={{ width: "100%", alignContent: "center", justifyContent: "center" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        alternativeLabel
        sx={{
          "& .MuiStep-root": {
            flex: "none",
            maxWidth: "25%",
            width: "25%",
            paddingBottom: "0 !important",
            "& .MuiStepLabel-label.Mui-active": {
              color: "#003366",
            },
            "& .Mui-active": {
              color: "#003366",
            },
          },
        }}>
        {steps.map(({ label, path }, index) => (
          <Step key={label} active={index === activeStep}>
            <StepButton onClick={() => handleStep(path)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
