import { AxiosError, AxiosResponse } from "axios";

import { COST_API_ENDPOINTS } from "@/shared/api/api";
import apiRequests from "@/shared/config/axiosConfig";

import { CostModelForProjectBaseline } from "../models/costModelForProjectBaseline";

export default async function getCostDataService(
  projectId: string,
  deliverableId: string
): Promise<CostModelForProjectBaseline[]> {
  try {
    const url = COST_API_ENDPOINTS.GET_COST.replace("{projectId}", projectId).replace(
      "{deliverableId}",
      deliverableId
    );

    const response: AxiosResponse<CostModelForProjectBaseline[]> =
      await apiRequests.get(url);

    const { data, status } = response;

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}
