import { useContext } from "react";

import { TenantManagementCtx } from "@/shared/context/tenantManagementCtx/TenantManagementCtx";

export const useTenantManagement = () => {
  const context = useContext(TenantManagementCtx);
  if (!context) {
    throw new Error(
      "useTenantManagement must be used within an TenantManagementProvider"
    );
  }
  return context;
};
