import { ViewMode } from "@ecerto/gantt-chart";
import "@ecerto/gantt-chart/dist/index.css";
import { ChangeDateSource } from "@ecerto/gantt-chart/dist/types/public-types";
import { Alert, Box, CircularProgress } from "@mui/material";
import { useCallback, useState } from "react";

import DisplayOptions from "@/features/projectSchedule/components/displayOptions/DisplayOptions";
import GanttBoard from "@/features/projectSchedule/components/GanttBoard";
import {
  applyDragDropUpdate,
  createPayload,
  initializeDisplayOptions,
} from "@/features/projectSchedule/helper";
import { ScheduleCircularProgress } from "@/features/projectSchedule/styles/styles";
import { DisplayOptionsType } from "@/features/projectSchedule/types";
import editDeliverable from "@/shared/api/editDeliverable";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { IScheduleItems } from "@/shared/types/scheduleItems";

export default function Schedule() {
  // Contains the tools to GET, PUT Gantt chart data.
  const { refetchProjectWBS, isLoading, getWBSEntryById, getScheduleEntries } =
    useProjectWBSContext();
  // ScheduleEntries contains the data used to populate the Gantt chart
  const ScheduleEntries = getScheduleEntries();
  // State for the display options of the project schedule
  const [displayOptions, setDisplayOptions] = useState<DisplayOptionsType>(
    initializeDisplayOptions
  );

  /**
   * It updates the state of the display options by overriding the value of the specified key with the newOptionValue.
   * @param {keyof DisplayOptionsType} key - The key of the display option to update.
   * @param {number | boolean | ViewMode} newOptionValue - The new value for the display option.
   */
  const handleDisplayOptionsChange = useCallback(
    (key: keyof DisplayOptionsType, newOptionValue: number | boolean | ViewMode) => {
      const newOptions = {
        ...displayOptions,
        [key]: newOptionValue,
      };
      try {
        localStorage.setItem("displayOptions", JSON.stringify(newOptions));
        setDisplayOptions(newOptions);
      } catch (error) {
        // handle error
      }
    },
    [displayOptions]
  );

  /**
   * @param {IScheduleItems} wbsEntry - The entry the user edited.
   * @param {ChangeDateSource} changeDateSource - Source of the change (table or diagram).
   * @returns {Promise<boolean>} - True if the server update was successful, false otherwise.
   * Note: Due to library's requirements, this function must return a boolean, even though is not necessary.
   */
  const handleGanttBoardChange = useCallback(
    async (
      wbsEntry: IScheduleItems,
      changeDateSource?: ChangeDateSource
    ): Promise<boolean> => {
      try {
        let updatedWBSEntry = wbsEntry;
        if (changeDateSource !== "FromTable") {
          updatedWBSEntry = applyDragDropUpdate(wbsEntry);
        }
        const currentWBSEntry = getWBSEntryById(updatedWBSEntry.id);
        if (!currentWBSEntry) return false;

        const modelPayload = createPayload(currentWBSEntry, updatedWBSEntry);
        await editDeliverable(modelPayload);
        refetchProjectWBS();
        return true;
      } catch (error) {
        return false;
      }
    },
    [getWBSEntryById, refetchProjectWBS]
  );

  return (() => {
    if (isLoading) {
      return (
        <Box sx={{ ScheduleCircularProgress }}>
          <CircularProgress />
        </Box>
      );
    }
    if (ScheduleEntries.length === 0 && !ScheduleEntries) {
      return (
        <Alert severity="warning">
          There are no deliverables defined, please go to step 1.
        </Alert>
      );
    }
    return (
      <div className="Container">
        {!isLoading && ScheduleEntries.length > 0 && (
          <div className="DisplayOptionsWrapper">
            <DisplayOptions
              displayOptions={displayOptions}
              onDisplayOptionsChange={handleDisplayOptionsChange}
            />
          </div>
        )}
        <div className="Wrapper">
          {!isLoading && ScheduleEntries.length > 0 && (
            <div className="GanttBoardWrapper">
              <GanttBoard
                displayOptions={displayOptions}
                onGanttBoardChange={handleGanttBoardChange}
                ScheduleTasks={ScheduleEntries}
              />
            </div>
          )}
        </div>
      </div>
    );
  })();
}
