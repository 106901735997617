// @refactor make add and edit one  file and create a function or hook to handle on change
// @refactor the helperText should be a custom component
// @refactor find a better way to deal with empty values in textfield of type number. Alternatively avoid the transform in the yup schema and instead pass a null in the onChange event of the text field
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as Yup from "yup";

import { ResourcesModel } from "@/features/cost/resourceCatalogue/models/resourcesModel";
import CustomTextField from "@/shared/components/CustomTextField";
import { OutcomeUnitsNames, DurationUnitsNames } from "@/shared/enums/units";

import addResource from "../services/addResource";

const validationSchema = Yup.object({
  category: Yup.string().required("is required").max(50, "must be 50 characters or less"),
  subCategory: Yup.string()
    .required("is required")
    .max(50, "must be 50 characters or less"),
  supplier: Yup.string().required("is required").max(50, "must be 50 characters or less"),
  name: Yup.string().required("is required").max(50, "must be 50 characters or less"),
  description: Yup.string().max(150, "must be 150 characters or less"),
  fixedCost: Yup.number()
    .nullable()
    .max(9999999999999, "exceeds the maximum value allowed")
    .transform((value: number, originalValue: string | number | null): number | null => {
      return originalValue === "" ? null : value;
    }),
});

export type CreateNewResourceFormProps = {
  onSubmit: () => void;
  innerRef: RefObject<FormikProps<ResourcesModel>>;
};

export default function CreateNewResourceForm({
  onSubmit,
  innerRef,
}: CreateNewResourceFormProps) {
  const initialValues: ResourcesModel = {
    id: "",
    category: "",
    subCategory: "",
    name: "",
    supplier: "",
    description: "",
    fixedCost: null,
    dynamicCost: 0,
    unit: "",
    unitEnum: 0,
    section: "",
    unitCost: 0,
  };
  const units = new Map<number, string>([...DurationUnitsNames, ...OutcomeUnitsNames]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={innerRef}
      onSubmit={async (values, { setStatus }) => {
        try {
          await addResource(values);
          onSubmit();
        } catch (err) {
          if (err instanceof Error) {
            setStatus({ errorMessage: err.message });

            // Set a timeout to clear the error message after 5 seconds
            setTimeout(() => setStatus(null), 5000);
          }
        }
      }}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldTouched,
        validateField,
      }) => (
        <Form>
          <Box sx={{ "& .MuiTextField-root": { mb: 1 }, paddingTop: "1rem" }}>
            <CustomTextField
              name="category"
              label="Category"
              type="string"
              value={values.category}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={50}
              required
            />
            <CustomTextField
              name="subCategory"
              label="Subcategory"
              type="string"
              value={values.subCategory}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={50}
              required
            />
            <CustomTextField
              name="supplier"
              label="Supplier"
              type="string"
              value={values.supplier}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={50}
              required
            />
            <CustomTextField
              name="name"
              label="Resource Name"
              type="string"
              value={values.name}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={50}
              required
            />
            <CustomTextField
              name="description"
              label="Description"
              type="string"
              value={values.description}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={150}
            />
            <Box mb={3.4}>
              <TextField
                select
                label="Unit"
                name="unit"
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.unit && Boolean(errors.unit)}
                helperText={touched.unit && errors.unit}
                fullWidth>
                {Array.from(units).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <CustomTextField
              name="fixedCost"
              label="Fixed Price"
              type="number"
              value={values.fixedCost}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              validateField={validateField}
              maxLength={13}
            />
          </Box>
          {/* <Box display="flex" justifyContent="flex-end" mt={1} gap={1}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!dirty || isSubmitting || !isValid}>
              Create
            </Button>
          </Box> */}
        </Form>
      )}
    </Formik>
  );
}
