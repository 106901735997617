import Box from "@mui/material/Box";
import { MRT_RowSelectionState, MRT_TableInstance } from "material-react-table";

import { PortfolioRowModel } from "../models/portfolioModel";

import AddProjectButton from "./AddProjectButton";
import DeleteProjectButton from "./DeleteProjectButton";

export default function PortfolioToolbar({
  refetchProjects,
  tableInstanceRef,
  rowSelection,
}: {
  refetchProjects: () => Promise<void>;
  tableInstanceRef: MRT_TableInstance<PortfolioRowModel>;
  rowSelection: MRT_RowSelectionState;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 2,
      }}>
      <DeleteProjectButton
        tableInstanceRef={tableInstanceRef}
        rowSelection={rowSelection}
        refetchProjects={refetchProjects}
      />
      <AddProjectButton refetchProjects={refetchProjects} />
    </Box>
  );
}
