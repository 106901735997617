import { Home, NavigateNext } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";

import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

import BreadcrumbSkeleton from "./BreadcrumbSkeleton";

type BreadcrumbProps = {
  isLoading: boolean;
};

export default function Breadcrumb({ isLoading }: BreadcrumbProps) {
  const { outcome } = useParams();
  const { getWBSEntryById, projectWBS } = useProjectWBSContext();

  if (isLoading) {
    return <BreadcrumbSkeleton />;
  }

  const entry = getWBSEntryById(outcome);

  if (!projectWBS || !entry) {
    return null;
  }
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext />}
      style={{ display: "flex", alignItems: "center" }}>
      <Link
        to="/portfolio"
        style={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
          color: "inherit",
        }}>
        <Home sx={{ mr: 0.5 }} fontSize="inherit" />
        Portfolio
      </Link>

      <Link to="/portfolio">{projectWBS.projectName}</Link>
      <Typography>{projectWBS.conceptName}</Typography>
      <Typography>{entry.name}</Typography>
    </Breadcrumbs>
  );
}
