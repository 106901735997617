enum ProjectScenarios {
  "Transactional",
  "Integrated",
  "Bundled",
  "Cost",
  "Duration",
  "Emissions",
}

export default ProjectScenarios;
