import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// @check to be handled differently

export default function Settings() {
  return (
    <>
      <Drawer
        sx={{
          flexShrink: 0,

          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            backgroundColor: "#f7f7f7",
            color: "var(--ecerto-secondary-lightblue)",
            top: "64px",
            width: { xs: "0%", md: "25%" },
          },
        }}
        variant="permanent"
        anchor="left">
        <Typography
          variant="h3"
          component="h2"
          color="text.primary"
          sx={{
            marginLeft: "20px",
            marginTop: "20px",
          }}>
          Profile
        </Typography>
        <List sx={{ paddingLeft: "35px", marginTop: "25px" }}>
          <ListItem>
            <ListItemButton>
              <ListItemText
                primary="Settings"
                primaryTypographyProps={{ fontSize: "28px" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton component="a" href="#simple-list">
              <ListItemText
                primary="Change Password"
                primaryTypographyProps={{ fontSize: "28px" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      <Grid
        sx={{
          marginLeft: { xs: "5%", md: "30%" },
          textAlign: { xs: "center", md: "left" },
        }}>
        <Typography
          component="h1"
          sx={{ fontSize: { xs: "55px", md: "50px" } }}
          mb={1}
          mt={3}>
          Change Password
        </Typography>
        <Divider
          color="#ebe6e0"
          variant="middle"
          flexItem
          sx={{
            marginLeft: "0px",
            marginBottom: "20px",
            borderBottomWidth: "20px",
          }}
        />
        <Typography component="h2" sx={{ fontSize: { xs: "40px", md: "30px" } }} mt={5}>
          New Password
        </Typography>
        <TextField
          sx={{ marginTop: "15px" }}
          id="new-Password"
          variant="standard"
          color="info"
          focused
        />
        <Typography component="h2" sx={{ fontSize: { xs: "40px", md: "30px" } }} mt={3}>
          Confirm New Password
        </Typography>
        <TextField
          sx={{ marginTop: "15px" }}
          id="confirm-new-Password"
          variant="standard"
          color="info"
          focused
          required
        />
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Button
            type="submit"
            variant="contained"
            color="info"
            sx={{
              mt: 3,
              mb: 2,
              fontSize: { xs: "24px", md: "16px" },
              width: { xs: "100%", md: "180px" },
              height: { xs: "60px", md: "50px" },
            }}>
            Change
          </Button>
        </Box>
      </Grid>
    </>
  );
}
