import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

import { calculatePaddingLeft } from "@/features/projectSchedule/helper";
import {
  NameCellBoxStyle,
  NameCellTypographyStyle,
  NameCellButtonStyle,
} from "@/features/projectSchedule/styles/styles";
import { ScheduleBodyNameCellTypes } from "@/features/projectSchedule/types";

export default function NameCell({
  scheduleEntry,
  onExpanderClick,
}: ScheduleBodyNameCellTypes) {
  const paddingLeft = calculatePaddingLeft(scheduleEntry?.level);

  return (
    <Box sx={NameCellBoxStyle(paddingLeft)} title={scheduleEntry.name}>
      {scheduleEntry.level !== 3 && scheduleEntry.level !== 0 && (
        <IconButton
          sx={NameCellButtonStyle}
          onClick={() => onExpanderClick(scheduleEntry)}>
          {scheduleEntry.hideChildren ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </IconButton>
      )}
      <Typography sx={NameCellTypographyStyle}>{scheduleEntry.name}</Typography>
    </Box>
  );
}
