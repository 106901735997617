/* eslint-disable  @typescript-eslint/no-explicit-any -- Allow type any */
import { NumericFormat } from "react-number-format";

import { getDecorationSymbol, isWithinRange } from "@/shared/components/numberFormat";
import { DigitRange } from "@/shared/constants/digitLimit";

type RNumberFormatProps = {
  value: string | number;
  type: "text" | "input";
  category: "price" | "cost" | "emission" | "duration" | "percentage" | "quantity";
  customUnit?: string;
  allowNegative?: false;
  decimalScale?: number;
  color?: React.CSSProperties;
  range: DigitRange;
  customInput?: React.ComponentType<any>;
};

export default function RNumberFormat({
  value,
  type,
  category,
  customUnit,
  allowNegative,
  decimalScale = 2,
  color,
  range,
  customInput,
}: RNumberFormatProps) {
  const decorator = getDecorationSymbol(category, customUnit);
  const isAllowed = isWithinRange(value, range);

  return (
    <NumericFormat
      value={value}
      displayType={type}
      thousandSeparator
      fixedDecimalScale
      allowNegative={allowNegative}
      decimalScale={decimalScale}
      customInput={customInput}
      style={color}
      prefix={decorator.prefix}
      suffix={decorator.suffix}
      isAllowed={isAllowed}
    />
  );
}
