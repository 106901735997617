import { MRT_RowSelectionState } from "material-react-table";

import AddResourceToCostButton from "./AddResourceToCostButton";
import CreateNewResource from "./CreateNewResource";
import DeleteResourceButton from "./DeleteResourceButton";

export default function ResourceCatalogueRowActions({
  onCatalogueAndCostRefetch,
  rowSelection,
  outcome,
}: {
  onCatalogueAndCostRefetch: () => void;
  rowSelection: MRT_RowSelectionState;
  outcome: string;
}) {
  const selectedRowIdsSet = Object.keys(rowSelection).filter(
    (row_id) => rowSelection[row_id]
  );

  return (
    <>
      {selectedRowIdsSet.length > 0 && (
        <>
          <DeleteResourceButton
            rowSelection={selectedRowIdsSet}
            onCatalogueAndCostRefetch={onCatalogueAndCostRefetch}
          />
          <AddResourceToCostButton
            onCatalogueAndCostRefetch={onCatalogueAndCostRefetch}
            rowSelection={selectedRowIdsSet}
            outcome={outcome}
          />
        </>
      )}
      {selectedRowIdsSet.length === 0 && (
        <CreateNewResource onCatalogueAndCostRefetch={onCatalogueAndCostRefetch} />
      )}
    </>
  );
}
