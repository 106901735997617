import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState, MouseEvent } from "react";
import { Link, useLocation } from "react-router-dom";

import WhiteLogo from "@/shared/assets/logos/eCERTO_flat_white.png";
import RListButton from "@/shared/components/RListButton";
import RMenu from "@/shared/components/RMenu";
import { useTenantManagement } from "@/shared/context/tenantManagementCtx/useManagementTenant";
import TenantManagementView from "@/shared/enums/tenantManagement";
import { getUserInfo } from "@/shared/store/userInfo";
import { useAuth } from "@/shared/utils/auth";
import { stringAvatar } from "@/shared/utils/formattingFunctions";

import LicenseChip from "./LicenseChip";

export default function Navbar() {
  const location = useLocation();
  const userInfo = getUserInfo();
  const { logIn, logOut } = useAuth();
  const { openModalWithContent } = useTenantManagement();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const logOutHandler = () => {
    void logOut();
  };

  const logInHandler = () => {
    void logIn();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            {/* Logo and Title */}
            <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
              <img src={WhiteLogo} width={35} alt="Logo" />
              <Typography
                variant="h6"
                noWrap
                component={Link}
                to="/"
                ml={1}
                sx={{
                  letterSpacing: "1.5px",
                  fontWeight: "400",
                  color: "inherit",
                  textDecoration: "none",
                }}>
                INTEGRATI
              </Typography>
              <LicenseChip />
            </Box>

            {/* Authenticated/Unauthenticated Templates */}
            <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
              <AuthenticatedTemplate>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 1 }}>
                      <Avatar
                        alt="eCERTO"
                        sx={{
                          backgroundColor: "var(--ecerto-breakdown-brown)",
                        }}>
                        {userInfo.fullName !== ""
                          ? stringAvatar(userInfo.fullName)
                          : null}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}>
                    <RMenu>
                      <RListButton
                        icon={<SettingsIcon />}
                        onClick={() => {
                          openModalWithContent(TenantManagementView.Profile);
                          handleCloseUserMenu();
                        }}
                        isMenuItem>
                        Manage Your Account
                      </RListButton>

                      <RListButton
                        icon={<ManageAccountsIcon />}
                        onClick={() => {
                          openModalWithContent(TenantManagementView.ManageUsers);
                          handleCloseUserMenu();
                        }}
                        isMenuItem>
                        Manage Users
                      </RListButton>

                      <Divider />
                      <RListButton
                        icon={<LogoutIcon />}
                        onClick={logOutHandler}
                        type="error"
                        isMenuItem>
                        Log out
                      </RListButton>
                    </RMenu>
                  </Menu>
                </Box>
              </AuthenticatedTemplate>

              <UnauthenticatedTemplate>
                <Button
                  onClick={logInHandler}
                  variant="contained"
                  sx={{
                    my: 2,
                    border: "1px solid white",
                    color: "white",
                    ml: 2,
                  }}>
                  {location.pathname === "/login" ? "Sign Up" : "Sign in"}
                </Button>
              </UnauthenticatedTemplate>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
