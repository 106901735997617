import {
  CostApiEndpoints,
  DeliverablesApiEndpoints,
  ParametricApiEndpoints,
  ProjectApiEndpoints,
  ResourceCatalogueEndpoints,
  RiskApiEndpoints,
  ScenariosApiEndpoints,
  AccountApiEndpoints,
} from "@/shared/api/apiModels";

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL as string;

export const PROJECTS_API_ENDPOINTS: ProjectApiEndpoints = {
  GET_PORTFOLIO: "/api/projects/getPortfolio",
  GET_PROJECTS: "/api/projects", // used for 4 calls adding and editing projects and concepts. I would prefer to have separate endpoints for each.
  GET_PROJECT: "/api/projects/{uuid}",
  GET_PROJECTS_FOR_CLONE: "/api/projects/getProjectsForClone",
  BATCH_DELETE_PROJECTS: "/api/projects/batchDelete",
  GET_CONCEPT_WBS: "/api/projects/getProjectWBS/{projectId}",
  PATCH_PROJECT_ORDER: "/api/projects",
  POST_CLONE_PROJECT: "/api/projects/cloneProject",
  POST_CLONE_CONCEPT: "/api/projects/cloneConcept",
};

export const RESOURCE_CATALOGUE_ENDPOINTS: ResourceCatalogueEndpoints = {
  GET_RESOURCE_CATALOGUE_FOR_DELIVERABLE: "/api/resources/deliverable/{deliverableId}",
  EDIT_RESOURCE: "/api/resources",
  ADD_RESOURCE: "/api/resources",
  DELETE_RESOURCE: "/api/resources/{resourceId}",
};

export const COST_API_ENDPOINTS: CostApiEndpoints = {
  GET_COST: "api/scenarios/getLevel3DeliverableResources/{deliverableId}/{projectId}",
  EDIT_COST: "/api/deliverableResources",
  DELETE_COST: "/api/deliverableResources/{deliverableId}/{resourceId}",
  ADD_RESOURCE: "/api/deliverableresources/{deliverableId}/{resourceId}",
  GET_COST_BASELINE_TOTALS:
    "/api/deliverableResources/getCostEstimateSummary/{wbsEntryId}",
};

export const PARAMETRIC_API_ENDPOINTS: ParametricApiEndpoints = {
  GET_PARAMETERS: "/api/parametricCost/getAllParametricCosts/{outcomeId}",
  UPDATE_PARAMETER: "/api/parametricCost/{parameterId}",
  CREATE_PARAMETER: "/api/parametricCost",
};

export const RISK_API_ENDPOINTS: RiskApiEndpoints = {
  GET_BASELINE_RISKS: "/api/deliverableRisk/getAllDeliverableRisks/{wbsEntryId}",
  // GET_BASELINE_RISKS_TOTALS:
  //   "/api/deliverableResources/getCostEstimateSummary/{wbsEntryId}",
  UPDATE_RISKS: "/api/deliverableRisk/{riskId}",
  ADD_RISKS: "/api/deliverableRisk",
  DELETE_RISK: "/api/deliverableRisk/{riskId}",
};

export const DELIVERABLES_API_ENDPOINTS: DeliverablesApiEndpoints = {
  GET_DELIVERABLES_FOR_CLONE: "/api/deliverables/projectForCloning/{programid}",
  GET_DELIVERABLE_CHILDREN: "/api/deliverables/childrenDeliverables/{deliverableid}",
  GET_DELIVERABLE_BY_ID: "/api/deliverables/{id}",
  ADD_PROJECT_DELIVERABLES: "/api/deliverables",
  GET_PROJECT_DELIVERABLES: "/api/deliverables/project/{projectid}",
  ADD_CLONED_DELIVERABLE: "/api/deliverables/clone",
  // GET_SCHEDULE: "/api/deliverables/projectSchedule/{projectid}",
  UPDATE_DELIVERABLE_ORDER: "/api/deliverables/order/{projectid}",
};

export const SCENARIOS_API_ENDPOINTS: ScenariosApiEndpoints = {
  PATCH_SCENARIO_ALLOWANCES_CONTINGENCY:
    "/api/deliverables/UpdateDeliverableCostSummary/{wbsEntryId}",
  POST_SCENARIO_RESERVES: "/api/projects/{wbsEntryId}/costs",
};

export const ACCOUNT_API_ENDPOINTS: AccountApiEndpoints = {
  GET_USER_INFO_FOR_EDIT: "",
};
