import { AxiosError, AxiosResponse } from "axios";

import { PARAMETRIC_API_ENDPOINTS } from "@/shared/api/api";
import apiRequests from "@/shared/config/axiosConfig";
import { ParameterModels } from "@/shared/types/parameterModels";

import {
  LegacyAPIDataModel,
  PostLegacyAPIDataModel,
} from "../model/legacyParameterModel";
import formatLegacyParametricModel, {
  formatSingleLegacyParametricModel,
  formatToLegacyParameter,
} from "../util/formatLegacyParamModel";

/**
 * Retrieves all parameters for the parametric model component for a given outcome ID.
 * @param deliverableId The ID of the deliverable to retrieve parametric costs for.
 * @returns A Promise that resolves to an array of Parametric model objects, or null if an error occurs.
 * @throws An error if the request fails or returns an error status code.
 */
export default async function getProjectBaselineParameters(
  deliverableId: string
): Promise<ParameterModels[]> {
  try {
    // API call to get parametric costs
    const url = PARAMETRIC_API_ENDPOINTS.GET_PARAMETERS.replace(
      "{outcomeId}",
      deliverableId
    );
    const response: AxiosResponse<LegacyAPIDataModel[]> = await apiRequests.get(url);

    if (response.status === 200) {
      const parameterLegacyModels = response.data;
      // @todo Remove function below when the API is updated to return the correct format
      const parameters: ParameterModels[] =
        formatLegacyParametricModel(parameterLegacyModels);
      return parameters;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}

export const updateParameter = async (parameterToUpdate: ParameterModels) => {
  const legacyParameterToUpdate: PostLegacyAPIDataModel =
    formatToLegacyParameter(parameterToUpdate);
  try {
    if (!legacyParameterToUpdate.id) {
      throw new Error("Parameter could not be updated because ID is undefined");
    }
    const url = PARAMETRIC_API_ENDPOINTS.UPDATE_PARAMETER.replace(
      "{parameterId}",
      legacyParameterToUpdate.id
    );
    const response: AxiosResponse<LegacyAPIDataModel> = await apiRequests.put(
      url,
      legacyParameterToUpdate
    );

    if (response.status >= 200 && response.status < 300) {
      const updatedParameterLegacyModel: LegacyAPIDataModel = response.data;
      const updatedParameter: ParameterModels = formatSingleLegacyParametricModel(
        updatedParameterLegacyModel
      );
      return updatedParameter;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
};

export const createParameter = async (parameter: ParameterModels) => {
  const legacyParameter = formatToLegacyParameter(parameter);

  try {
    const url = PARAMETRIC_API_ENDPOINTS.CREATE_PARAMETER;
    const response: AxiosResponse<LegacyAPIDataModel> = await apiRequests.post(
      url,
      legacyParameter
    );

    if (response.status >= 200 && response.status < 300) {
      const newParameter: ParameterModels = formatSingleLegacyParametricModel(
        response.data
      );
      return newParameter;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
};
