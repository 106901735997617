import { AxiosError, AxiosResponse } from "axios";

import { PROJECTS_API_ENDPOINTS } from "@/shared/api/api";
import apiRequests from "@/shared/config/axiosConfig";
import { ProjectWBS } from "@/shared/context/projectWBS/projectWBSModel";

export default async function getProjectWBS(projectId: string): Promise<ProjectWBS> {
  try {
    // API call to get parametric costs
    const url = PROJECTS_API_ENDPOINTS.GET_CONCEPT_WBS.replace("{projectId}", projectId);
    const response: AxiosResponse<ProjectWBS> = await apiRequests.get(url);

    if (response.status === 200) {
      const projectWBS = response.data;
      //   const parameterLegacyModels = response.data.parametricCosts;
      //   // @todo Remove function below when the API is updated to return the correct format
      //   const parameters: ParameterModels[] = formatLegacyParametricModel(
      //     parameterLegacyModels
      //   );
      return projectWBS;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}
// /api/scenarios/getTransactional/096b3a1b-bb07-4073-9284-007d7456f6e3/4c08f649-acf8-4843-9bf0-948bc88f8e66
// /api/scenarios/gettransactional/096b3a1b-bb07-4073-9284-007d7456f6e3/79966841-93b5-43fe-b2cc-9bf2e1afca92
