import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigation } from "react-router-dom";

import { PortfolioTable } from "@/features/portfolio";

export default function Portfolio() {
  const navigation = useNavigation();

  if (navigation.state === "loading")
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  return (
    <Box
      sx={{
        maxWidth: "95%",
        margin: "auto",
      }}>
      <Typography
        variant="h4"
        component="h1"
        align="left"
        m={2}
        style={{ fontWeight: "bold" }}>
        Portfolio
      </Typography>

      <PortfolioTable />
    </Box>
  );
}
