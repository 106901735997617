import { Typography } from "@mui/material";
import {
  TreeItem,
  TreeItemContentProps,
  TreeItemProps,
  useTreeItem,
} from "@mui/x-tree-view";
import clsx from "clsx";
import { forwardRef } from "react";
import { Link, useLocation } from "react-router-dom";

import useProjectContext from "@/shared/context/conceptContext/useProjectContext";

type StyledTreeItemProps = TreeItemProps & {
  bg?: string;
  border?: string;
};

const CustomContent = forwardRef<HTMLDivElement, TreeItemContentProps>((props, ref) => {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const { updateSelectedWbsEntryId, baselineTab } = useProjectContext();

  const { disabled, expanded, selected, focused, handleExpansion, preventSelection } =
    useTreeItem(nodeId);

  const icon = iconProp ?? expansionIcon ?? displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const location = useLocation();

  const basePath = location.pathname.split("/");
  while (basePath.length > 4) {
    basePath.pop();
  }

  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions -- the team member that added this comment did not add why */
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
      style={{ minHeight: "2rem" }}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions -- the team member that added this comment did not add why */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      {basePath.includes("project-scope") || basePath.includes("project-schedule") ? (
        <Typography
          className={classes.label}
          onClick={() => updateSelectedWbsEntryId(nodeId)}>
          {label}
        </Typography>
      ) : (
        <Link
          to={`${basePath.reduce(
            (prev, curr) => `${prev}/${curr}`
          )}/${nodeId}/${baselineTab}`}
          className={classes.label}
          onClick={() => updateSelectedWbsEntryId(nodeId)}>
          {label}
        </Link>
      )}
    </div>
  );
});
CustomContent.displayName = "CustomContent";

const CustomTreeItem = forwardRef<HTMLLIElement, StyledTreeItemProps>((props, ref) => {
  return (
    <TreeItem
      ContentComponent={CustomContent}
      {...props}
      style={{
        backgroundColor: props.bg,
        borderLeft: `4px solid ${props.border}`,
      }}
      ref={ref}
    />
  );
});
CustomTreeItem.displayName = "CustomTreeItem";

export default CustomTreeItem;
