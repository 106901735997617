import { Add } from "@mui/icons-material";
import { FormikProps } from "formik";
import { useRef, useState } from "react";

import PrimaryButton from "@/shared/components/PrimaryButton";
import ReusableDialog from "@/shared/components/ReusableDialog";

import CreateNewResourceForm from "../forms/CreateNewResourceForm";
import { ResourcesModel } from "../models/resourcesModel";

export type CreateNewResourceProps = {
  onCatalogueAndCostRefetch: () => void;
};

export default function CreateNewResource({
  onCatalogueAndCostRefetch,
}: CreateNewResourceProps) {
  const [isModalOpen, setModalOpen] = useState(false);
  const newFormikRef = useRef<FormikProps<ResourcesModel>>(null);

  const handleSubmitForm = () => {
    if (isModalOpen && newFormikRef.current) {
      void newFormikRef.current.submitForm();
      setModalOpen(false);
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    onCatalogueAndCostRefetch(); // Trigger refetching of projects
    handleClose(); // Close the modal
  };

  return (
    <>
      <PrimaryButton onClick={handleOpen} icon={<Add fontSize="small" />}>
        New Resource
      </PrimaryButton>
      <ReusableDialog
        isOpen={isModalOpen}
        title="Create New Resource"
        confirmButtonCallback={handleSubmitForm}
        cancelButtonCallback={handleClose}
        confirmButtonText="Create"
        confirmButtonType="primary"
        size="medium">
        <CreateNewResourceForm onSubmit={handleConfirm} innerRef={newFormikRef} />
      </ReusableDialog>
    </>
  );
}
