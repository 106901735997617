export const addUserFormStyles = {
  textfield: {
    "& .MuiFilledInput-root": {
      backgroundColor: "#EEF1F4",
    },
    boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.1)",
    "& .MuiFilledInput-underline::before": {
      borderBottom: "none",
    },
  },
};
