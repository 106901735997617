import Chip from "@mui/material/Chip";

// import { useAppSelector } from "@/shared/store/hooks";

export default function LicenseChip() {
  // const role = useAppSelector((state) => state.user?.role ?? "");

  return (
    <Chip
      label="LITE" // label={role} (user role should either be Lite or Pro, currently it's ADM or USR)
      sx={{
        backgroundColor: "var(--ecerto-pantone-663)",
        borderRadius: "3px",
        color: "var(--primary-color)",
        height: "20px",
        padding: "0 2px",
        mx: 1,
      }}
    />
  );
}
