import { CssBaseline } from "@mui/material";

import "../index.css";
import AppProvider from "@/app/AppProvider";
import Routing from "@/app/routes/Routing";

export default function App() {
  return (
    <AppProvider>
      <CssBaseline />
      <Routing />
    </AppProvider>
  );
}
