import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useRef, useState } from "react";

import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

import { IData } from "../models/orgChartModels";

import OrgChartComponent from "./OrgChart";
import OrgChartButtons from "./OrgChartButtons";
import OrgSearch from "./OrgChartSearch";

// Adjust this function to correctly type the parameters and return typee
const flattenData = (originalData: WBSEntryViewModel[], level = 0): IData[] => {
  return originalData.flatMap((item: WBSEntryViewModel): IData[] => {
    const { children, ...rest } = item;
    const flattenedItem: IData = {
      ...rest,
      children: children ? flattenData(children, level + 1) : [],
      level,
      _expanded: false,
      _highlighted: false,
      _upToTheRootHighlighted: false,
    };

    return [flattenedItem, ...(children ? flattenData(children, level + 1) : [])];
  });
};

export default function BreakdownStructures() {
  const OrgChartRef = useRef(null);
  const { projectWBS } = useProjectWBSContext();
  const rootData: WBSEntryViewModel[] = projectWBS
    ? ([projectWBS.conceptWBS] as WBSEntryViewModel[])
    : [];
  const flattenedData: IData[] = flattenData(rootData);
  const [display, setDisplay] = useState(() => {
    const savedFilter = localStorage.getItem("display") ?? "WBS";
    return savedFilter;
  });
  const chart = OrgChartComponent(flattenedData, OrgChartRef, display);

  const handleDisplayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDisplay = event.target.value;
    localStorage.setItem("display", newDisplay);
    setDisplay(newDisplay);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: "1rem 2.5rem" }}>
        <Grid item xs={8}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={handleDisplayChange}
              value={display}>
              <FormControlLabel control={<Radio />} label="Overview" value="overview" />
              <FormControlLabel control={<Radio />} label="WBS" value="WBS" />
              <FormControlLabel control={<Radio />} label="TBS" value="TBS" />
              <FormControlLabel control={<Radio />} label="CBS" value="CBS" />
              <FormControlLabel control={<Radio />} value="EBS" label="EBS" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={6} lg={9} justifyContent="end">
              <Box
                sx={{ display: "flex", justifyContent: "end", paddingRight: "0.5rem" }}>
                <OrgSearch chart={chart} />
              </Box>
            </Grid>
            <Grid item xs={6} lg={3} alignItems="end">
              <Box sx={{ paddingTop: "1rem" }}>
                <OrgChartButtons chart={chart} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box ref={OrgChartRef} style={{ width: "100%", height: "100%" }} />
    </>
  );
}
