import { FormikProps } from "formik";
import { useRef, useState } from "react";

import { AddConceptModel } from "@/features/portfolio/models/addConceptModel";
import addConcept from "@/features/portfolio/services/addConceptService";
import addProject from "@/features/portfolio/services/addProjectService";
import { updateSelectedConcept } from "@/features/portfolio/utils/portfolioHelpers";
import PrimaryButton from "@/shared/components/PrimaryButton";
import ReusableDialog from "@/shared/components/ReusableDialog";

import { AddProjectModel } from "../models/addProjectModel";

import AddConceptForm from "./forms/AddConceptForm";
import AddProjectForm from "./forms/AddProjectForm";

export default function AddProjectButton({
  refetchProjects,
}: {
  refetchProjects: () => Promise<void>;
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFollowUpModalOpen, setFollowUpModalOpen] = useState(false);
  const [tempNewProject, setTempNewProject] = useState<AddProjectModel | null>(null);
  const projectFormikRef = useRef<FormikProps<AddProjectModel>>(null);
  const conceptFormikRef = useRef<FormikProps<AddConceptModel>>(null);

  const handleDialogConfirm = () => {
    if (isModalOpen && projectFormikRef.current) {
      void projectFormikRef.current.submitForm();
      setModalOpen(false);
    } else if (isFollowUpModalOpen && conceptFormikRef.current) {
      void conceptFormikRef.current.submitForm();
      setFollowUpModalOpen(false);
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setFollowUpModalOpen(false);
  };

  const handleAddProject = (newProject: AddProjectModel) => {
    setTempNewProject(newProject);
    setFollowUpModalOpen(true);
  };
  const addConceptAsync = async (newConceptData: AddConceptModel) => {
    try {
      if (tempNewProject) {
        const savedProject = await addProject(tempNewProject);

        const newConcept = { ...newConceptData, parentId: savedProject.id };

        const savedConcept = await addConcept(newConcept);

        await updateSelectedConcept(savedProject, savedConcept);
        void refetchProjects();
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        throw new Error(err.message);
      } else {
        throw new Error(String(err));
      }
    }
  };

  const handleAddConcept = (newConceptData: AddConceptModel): void => {
    void addConceptAsync(newConceptData);
    handleClose();
  };
  return (
    <>
      <PrimaryButton onClick={handleOpen}>New Project</PrimaryButton>
      <ReusableDialog
        isOpen={isModalOpen}
        title="New Project"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleDialogConfirm}
        confirmButtonText="Create"
        confirmButtonType="primary"
        size="medium">
        <AddProjectForm onConfirm={handleAddProject} innerRef={projectFormikRef} />
      </ReusableDialog>

      <ReusableDialog
        isOpen={isFollowUpModalOpen}
        title="Create Concept"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleDialogConfirm}
        confirmButtonText="Create"
        confirmButtonType="primary"
        size="medium">
        <AddConceptForm onConfirm={handleAddConcept} innerRef={conceptFormikRef} />
      </ReusableDialog>
    </>
  );
}
