import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { Provider } from "react-redux";

import msalInstance from "@/shared/config/authConfig";
import { TenantManagementProvider } from "@/shared/context/tenantManagementCtx/TenantManagementCtx";
import { LightTheme } from "@/shared/lib/themes";
import store from "@/shared/store";

const theme = LightTheme();
type AppProviderProps = {
  children: React.ReactNode;
};
export default function AppProvider({ children }: AppProviderProps) {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TenantManagementProvider>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
          </TenantManagementProvider>
        </LocalizationProvider>
      </Provider>
    </MsalProvider>
  );
}
