// Format a number as a currency string with the locale
// @todo add locales to store

import { Units, UnitsAbbreviations } from "@/shared/enums/units";

/**
 * Retrieves the selected currency and returns its symbol.
 * If no currency is selected, it defaults to USD.
 * @returns The symbol of the selected currency. e.g. $ or €
 */
export const getSelectedCurrency = (): string => {
  const selectedCurrency = (import.meta.env.VITE_CURRENCY as string) ?? "USD";
  const currencySymbol = (0)
    .toLocaleString("en", { style: "currency", currency: selectedCurrency })
    // eslint-disable-next-line no-useless-escape -- needed for implementing regex
    .replace(/[\d\.]/g, "")
    .trim();
  return currencySymbol;
};

/**
 * Formats a number with commas.
 * @param num - The number to format.
 * @returns The formatted number with commas. e.g. 1,000
 */
// @todo replace this in schedule for format for days and remove days decorator
const formatWithCommas = (num: number, decimals: boolean): string => {
  if (!num && num !== 0) return "";

  const formattedNumber = num.toLocaleString(undefined, {
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
  });

  return formattedNumber;
};

export const formatNumberLabel = (
  type: string,
  number: number,
  hasDecimals: boolean,
  unit?: Units
) => {
  let formattedNumber = "";
  let subScript = "";

  switch (type) {
    case "currency":
      formattedNumber = `${getSelectedCurrency()}${formatWithCommas(number, hasDecimals)}`;
      break;
    case "duration":
      formattedNumber = formatWithCommas(number, hasDecimals);
      subScript = "days";
      break;
    case "emission":
      formattedNumber = formatWithCommas(number, hasDecimals);
      subScript = "tCO₂e";
      break;
    case "quantity":
      formattedNumber = formatWithCommas(number, hasDecimals);
      subScript = unit ? (UnitsAbbreviations.get(unit) ?? "") : "";
      break;
    case "percentage":
      formattedNumber = formatWithCommas(number, hasDecimals);
      subScript = "%";
      break;
    default:
      break;
  }

  return { formattedNumber, subScript };
};

export const stringAvatar = (name: string) => {
  const initials = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  return initials;
};
