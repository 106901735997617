import { AxiosResponse, AxiosError } from "axios";

import {
  PostRiskLegacyBaseModel,
  GetRiskLegacyBaseModel,
} from "@/features/risk/models/riskLegacyModel";
import { RiskBaseModel } from "@/features/risk/models/riskModels";
import newRiskModelToLegacyModel, {
  legacyRiskItemToNewItem,
} from "@/features/risk/utils/formatLegacyRiskModels";
import { RISK_API_ENDPOINTS } from "@/shared/api/api";
import apiRequests from "@/shared/config/axiosConfig";

export default async function addRisk(risk: RiskBaseModel): Promise<RiskBaseModel> {
  try {
    const url = RISK_API_ENDPOINTS.ADD_RISKS;
    const legacyRiskModel: PostRiskLegacyBaseModel = newRiskModelToLegacyModel(risk);
    const response: AxiosResponse<GetRiskLegacyBaseModel> = await apiRequests.post(
      url,
      legacyRiskModel
    );
    const { data, status } = response;

    if (status >= 200 && status < 300) {
      // @refactor:  instead of having to call legacyRiskItemToNewItem we should get that data from the response of the api call
      const newRisk: RiskBaseModel = legacyRiskItemToNewItem(data);
      return newRisk;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}
