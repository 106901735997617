import { Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

type RListButtonProps = {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: () => void;
  isMenuItem?: boolean;
  type?: "primary" | "error";
  textSize?: string;
  active?: boolean;
  value?: string | number;
};

export default function RListButton({
  icon,
  children,
  onClick,
  isMenuItem = false,
  type = "primary",
  textSize = "1.25rem",
  active = false,
  value,
}: RListButtonProps) {
  const typeColor = type === "error" ? "#c66e4e" : "#003057";
  const hoverColor =
    type === "error" ? "rgba(198, 110, 78, 0.1)" : "rgba(0, 65, 111, 0.1)";
  const activeColor =
    type === "error" ? "rgba(198, 110, 78, 0.05)" : "rgba(0, 65, 111, 0.05)";
  const commonStyles = {
    borderRadius: 1,
    backgroundColor: active ? activeColor : "transparent",
    "&:hover": {
      backgroundColor: !active ? hoverColor : activeColor,
    },
  };

  const content = (
    <>
      {icon && <ListItemIcon sx={{ color: typeColor }}>{icon}</ListItemIcon>}
      <ListItemText sx={{ color: typeColor }}>
        <Typography sx={{ fontSize: textSize }}>{children}</Typography>
      </ListItemText>
    </>
  );

  return isMenuItem ? (
    <MenuItem onClick={onClick} value={value} sx={commonStyles}>
      {content}
    </MenuItem>
  ) : (
    <ListItemButton onClick={onClick} sx={commonStyles}>
      {content}
    </ListItemButton>
  );
}
