import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";
type ListItemWithIconProps = {
  icon: React.ReactNode;
  text: string | undefined;
};

export default function ListItemWithIcon({ icon, text = "" }: ListItemWithIconProps) {
  return (
    <ListItem sx={{ mb: 1.5 }}>
      <ListItemIcon sx={{ color: "var(--primary-color)" }}>{icon}</ListItemIcon>
      <Tooltip title={text} placement="bottom">
        <ListItemText
          primary={<Typography sx={{ overflow: "hidden" }}>{text}</Typography>}
        />
      </Tooltip>
    </ListItem>
  );
}
