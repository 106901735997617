import { Dispatch } from "@reduxjs/toolkit";

import { setUser } from "@/shared/store";
import { User } from "@/shared/types/userInfoModels";

import msalInstance, { graphConfig } from "../config/authConfig";

// Define the structure of the expected JSON response
type GraphResponse = {
  id?: string;
  mail?: string;
  userPrincipalName?: string;
  givenName?: string;
  surname?: string;
  fullName?: string;
  role?: string;
  officeLocation?: string;
  jobTitle?: string;
};

type IdTokenClaimsType = {
  roles: string[];
};

// Thunk
export default function getUserFromGraph() {
  return async (dispatch: Dispatch) => {
    try {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        throw Error(
          "No active account! Verify a user has been signed in and setActiveAccount has been called."
        );
      }

      const response = await msalInstance.acquireTokenSilent({
        scopes: ["User.Read"],
        account,
      });

      const headers = new Headers();
      const bearer = `Bearer ${response.accessToken}`;

      headers.append("Authorization", bearer);

      const options = {
        method: "GET",
        headers,
      };

      fetch(graphConfig.graphMeEndpoint, options)
        .then(async (res) => {
          const json = (await res.json()) as GraphResponse;
          const idTokenClaims = response.idTokenClaims as IdTokenClaimsType;
          const userInfoObj: User = {
            userId: json.id ?? "",
            emailAddress: json.mail ?? json.userPrincipalName ?? "",
            firstName: json.givenName ?? "",
            lastName: json.surname ?? "",
            fullName: `${json.givenName} ${json.surname}` ?? "",
            role: idTokenClaims.roles[0] ?? "",
            officeLocation: json.officeLocation ?? "",
            jobTitle: json.jobTitle ?? "",
          };
          localStorage.setItem("userInfo", JSON.stringify(userInfoObj));

          if (!userInfoObj.userId || !userInfoObj.emailAddress) {
            throw new Error("User ID and email are required fields.");
          }

          dispatch(setUser(userInfoObj));
        })
        .catch((error) => {
          if (error instanceof Error) {
            throw new Error(error.message);
          } else {
            throw new Error("An unknown error occurred");
          }
        });
    } catch (error: unknown) {
      // @todo Handle Error Below
      if (error instanceof Error) {
        throw new Error(error.message);
      }
    }
  };
}
