import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import {
  WBSEntryModel,
  WBSEntryViewModel,
} from "@/shared/context/projectWBS/projectWBSModel";

import { overviewDetailCardStyles } from "../styles/styles";

export type OverviewDetailCardTypes = {
  wbsEntry: WBSEntryModel | WBSEntryViewModel;
};

export default function OverviewDetailCard({ wbsEntry }: OverviewDetailCardTypes) {
  const styles = overviewDetailCardStyles;

  return (
    <Box sx={styles.root}>
      <Typography variant="h1">
        {wbsEntry.wbsCode} {wbsEntry.name.toUpperCase()}
      </Typography>
      <Typography variant="h3">Level {wbsEntry.level}</Typography>
      <Box>
        <Typography variant="subtitle1">Scenario</Typography>
        <Typography variant="h3">Transactional</Typography>
      </Box>
      <Box sx={styles.dateBox}>
        <Box>
          <Typography variant="subtitle1">From</Typography>
          <Typography variant="h3">
            {new Date(wbsEntry.startDate).toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">To</Typography>
          <Typography variant="h3">
            {wbsEntry.level === 0 &&
            "projectEndDate" in wbsEntry &&
            wbsEntry.projectEndDate
              ? new Date(wbsEntry.projectEndDate).toLocaleDateString()
              : new Date(wbsEntry.budgetEndDate).toLocaleDateString()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
