/*eslint-disable @typescript-eslint/no-empty-function -- allowing us to pass empty functions in createContext is the least verbose solution */
import { useState, createContext, useMemo, useCallback } from "react";

type WBSLevelsContextType = {
  expandedWBSEntries: string[];
  setExpandedWBSEntries: (openEntries: string[]) => void;
  updateExpandedWBSEntries: (entryId: string, wasOpened: boolean) => void; // Updated this line
  highlightedWBSEntries: string[];
  setHighlightedWBSEntries: (highlightedEntry: string[]) => void;
};

export const WBSLevelsContext = createContext<WBSLevelsContextType>({
  expandedWBSEntries: [],
  setExpandedWBSEntries: () => {},
  updateExpandedWBSEntries: () => {}, // Updated this line
  highlightedWBSEntries: [],
  setHighlightedWBSEntries: () => {},
});

export default function WBSLevelsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [expandedWBSEntries, setExpandedWBSEntries] = useState<string[]>([]);
  const [highlightedWBSEntries, setHighlightedWBSEntries] = useState<string[]>([]);

  const updateExpandedWBSEntries = useCallback(
    (entryId: string, wasOpened: boolean) => {
      let updatedNodes;

      if (wasOpened) {
        updatedNodes = [...expandedWBSEntries, entryId];
      } else {
        updatedNodes = expandedWBSEntries.filter((nodeId) => nodeId !== entryId);
      }

      setExpandedWBSEntries(updatedNodes);
    },
    [expandedWBSEntries]
  );

  const value: WBSLevelsContextType = useMemo(
    () => ({
      expandedWBSEntries,
      setExpandedWBSEntries,
      updateExpandedWBSEntries, // Updated this line
      highlightedWBSEntries,
      setHighlightedWBSEntries,
    }),
    [
      expandedWBSEntries,
      updateExpandedWBSEntries,
      highlightedWBSEntries,
      setHighlightedWBSEntries,
    ]
  );

  return <WBSLevelsContext.Provider value={value}>{children}</WBSLevelsContext.Provider>;
}
