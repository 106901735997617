import { Gantt } from "@ecerto/gantt-chart";
import { useMediaQuery } from "@mui/material";
import { useContext } from "react";

import ScheduleTable from "@/features/projectSchedule/components/ganttTable/GanttTable";
import {
  updateExpandedState,
  getColumnWidth,
  getScheduleHeight,
} from "@/features/projectSchedule/helper";
import { GanttBoardTypes } from "@/features/projectSchedule/types";
import { WBSLevelsContext } from "@/shared/context/WBSLevelsContext";
import { IScheduleItems } from "@/shared/types/scheduleItems";

export default function GanttBoard({
  displayOptions,
  onGanttBoardChange,
  ScheduleTasks,
}: GanttBoardTypes) {
  // Use the WBSLevelsContext to "get" and "update" the global expanded WBSEntries state.
  const { expandedWBSEntries, updateExpandedWBSEntries } = useContext(WBSLevelsContext);
  // Update the expanded global state in the schedule entries before populating Gantt
  const scheduleEntries = updateExpandedState(ScheduleTasks, expandedWBSEntries);

  // The Gantt library's SVG rowHeight does not accept rem, so we need to convert it.
  // The useMediaQuery hook triggers a re-render whenever the screen size changes,
  // allowing us to recalculate the rootFontSize and rowHeight.
  useMediaQuery("(min-width:2048px)");
  useMediaQuery("(min-width:1920px)");
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const rowHeight = rootFontSize * 3.2;

  const handleExpandEntry = (wbsEntry: IScheduleItems) => {
    const wasExpanded = !wbsEntry.hideChildren;
    updateExpandedWBSEntries(wbsEntry.id, wasExpanded);
  };

  const { ScheduleTableHeader, ScheduleTableBody } = ScheduleTable({
    onGanttBoardChange,
  });
  const ScheduleTableBodyConst = ScheduleTableBody; // needed for onGanttBoardChange to work
  return (
    <Gantt
      tasks={scheduleEntries}
      viewMode={displayOptions.view}
      // @ts-expect-error - The library requires children array for dependencies but we are not using that yet.
      onDateChange={onGanttBoardChange}
      onExpanderClick={handleExpandEntry}
      onDoubleClick={handleExpandEntry}
      listCellWidth={displayOptions.isTimeLineView ? "" : "9.6875rem"}
      columnWidth={getColumnWidth(
        Number(displayOptions.sliderWidth),
        displayOptions.view
      )}
      ganttHeight={getScheduleHeight(displayOptions.sliderHeight)}
      TaskListHeader={ScheduleTableHeader}
      TaskListTable={ScheduleTableBodyConst}
      rowHeight={rowHeight}
      arrowIndent={5}
      preStepsCount={4}
      projectBackgroundColor="white"
      projectProgressColor="white"
      projectProgressSelectedColor="white"
      projectBackgroundSelectedColor="white"
      barBackgroundColor="white"
      barBackgroundSelectedColor="#D3DBE8"
      barProgressColor="white"
      barProgressSelectedColor="white"
      ganttColor="white"
      fontSize="1rem"
      fontFamily="Roboto"
      timeStep={86400000}
      todayColor="#f1f8ff"
      headerHeight={70}
    />
  );
}
