import { Theme } from "@mui/material/styles";

import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";

/**
 * Updates the order of WBS entries when reordering items.
 *
 * @param {Array} siblings - The list of WBS entries.
 * @param {Object} sourceItem - The WBS entry being moved.
 * @param {Object} targetItem - The WBS entry being moved to.
 * @returns {Array} - The updated list of WBS entries.
 */
export const reorderByDragDrop = (
  siblings: WBSEntryViewModel[],
  sourceItem: WBSEntryViewModel,
  targetItem: WBSEntryViewModel
): WBSEntryViewModel[] => {
  return siblings.map((sibling) => {
    // Update the order of the source item
    if (sibling.id === sourceItem.id) {
      return { ...sibling, order: targetItem.order };
    }

    // Adjust the order for items between the source and target orders when moving up
    if (
      sourceItem.order > targetItem.order &&
      sibling.order >= targetItem.order &&
      sibling.order < sourceItem.order
    ) {
      return { ...sibling, order: sibling.order + 1 };
    }

    // Adjust the order for items between the source and target orders when moving down
    if (
      sourceItem.order < targetItem.order &&
      sibling.order > sourceItem.order &&
      sibling.order <= targetItem.order
    ) {
      return { ...sibling, order: sibling.order - 1 };
    }

    // Return the item unchanged if it's not affected by the reordering
    return sibling;
  });
};

/**
 * Flattens a tree structure of WBS entries into a list.
 *
 * @param {Array} nodes - The tree structure of WBS entries.
 * @param {number} level - The depth of the current node in the tree.
 * @param {string} parentId - The ID of the parent node.
 * @returns {Array} - The list of WBS entries, flattened from the tree structure.
 */
export const transformWBSTreeToList = (
  nodes: WBSEntryViewModel[],
  level = 0,
  parentId = ""
): WBSEntryViewModel[] => {
  return nodes.reduce<WBSEntryViewModel[]>((acc, node) => {
    const flattenedNode = { ...node, level, parentId }; // Spread the node to include all properties
    acc.push(flattenedNode); // Add the current node to the accumulator

    if (node.children && node.children.length > 0) {
      // Recursively flatten children
      const children = transformWBSTreeToList(node.children, level + 1, node.id);
      acc.push(...children); // Add the flattened children to the accumulator
    }

    return acc;
  }, []);
};

export const getBackgroundColor = (level: number, theme: Theme) => {
  switch (level) {
    case 0:
      return theme.palette.levelColors.contrastText;
    case 1:
      return theme.palette.primary.light;
    case 2:
      return theme.palette.secondary.light;
    case 3:
      return theme.palette.levelColors.light;
    // case 4:
    //   return theme.palette.levelColors.dark;
    default:
      return theme.palette.levelColors.contrastText;
  }
};
