import { Box, Snackbar, SnackbarContent, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import isEqual from "lodash.isequal";
import {
  MRT_ColumnDef,
  useMaterialReactTable,
  MaterialReactTable,
  MRT_ExpandedState,
  MRT_RowSelectionState,
} from "material-react-table";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import editOrderByDragDrop from "@/features/projectScope/services/editOrderByDragDrop";
import {
  reorderByDragDrop,
  getBackgroundColor,
  transformWBSTreeToList,
} from "@/features/projectScope/utils/cleanCodeHelpers";
import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { WBSLevelsContext } from "@/shared/context/WBSLevelsContext";

import ProjectScopeToolbar from "./ProjectScopeToolbar";

// Assuming the WBSEntryViewModel and column definitions are correctly defined in your context and model files
export default function ProjectScopeTable({
  columns,
}: {
  columns: MRT_ColumnDef<WBSEntryViewModel>[];
}) {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [snackbar, setSnackbar] = useState(false);
  const { expandedWBSEntries: expandedNodes, setExpandedWBSEntries: setExpandedNodes } =
    useContext(WBSLevelsContext);
  const [expanded, setExpanded] = useState<MRT_ExpandedState>(
    expandedNodes.length !== 0
      ? expandedNodes.reduce((acc, node) => {
          (acc as Record<string, boolean>)[node] = true;
          return acc;
        }, {} as MRT_ExpandedState)
      : {}
  );
  const { projectWBS, refetchProjectWBS } = useProjectWBSContext();
  const theme = useTheme();
  const { id } = useParams();

  useEffect(() => {
    const vals = Object.keys(expanded).map((key: string) => key);
    setExpandedNodes(vals);
  }, [expanded, setExpandedNodes]);

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };

  const performDragAndDrop = async (
    siblings: WBSEntryViewModel[],
    sourceRow: WBSEntryViewModel,
    targetRow: WBSEntryViewModel
  ) => {
    try {
      // Sort siblings based on the new order and update their `order` property
      const updatedSiblings: WBSEntryViewModel[] = reorderByDragDrop(
        siblings,
        sourceRow,
        targetRow
      );
      // Pass updated siblings to the update service and database
      if (id) {
        await editOrderByDragDrop(updatedSiblings, id);
      }
      // Trigger a refresh of the project WBS
      refetchProjectWBS();
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Error during drag and drop: ${error.message}`);
      } else {
        throw new Error(`Error during drag and drop: ${String(error)}`);
      }
    }
  };

  const handleDragAndDrop = (
    siblings: WBSEntryViewModel[],
    sourceRow: WBSEntryViewModel,
    targetRow: WBSEntryViewModel
  ) => {
    void performDragAndDrop(siblings, sourceRow, targetRow);
  };

  const projectScopeTable = useMaterialReactTable({
    data: projectWBS?.conceptWBS.children ?? [],
    columns,
    initialState: {
      density: "compact", // Setting the initial density of rows
      sorting: [
        {
          id: "wbsCode",
          desc: false,
        },
      ],
      columnPinning: {
        left: ["mrt-row-drag", "mrt-row-select", "mrt-row-expand"],
      },
    },
    positionToolbarAlertBanner: "none", // Position of the toolbar alert banner
    layoutMode: "grid", // Layout mode of the table
    enableStickyHeader: true,
    enableColumnResizing: true,
    enableColumnPinning: true,
    enableRowSelection: true,
    enableExpanding: true,
    enableSorting: true,
    enableRowOrdering: true,
    enableExpandAll: true,
    onExpandedChange: setExpanded,
    enableGlobalFilterModes: true,
    enableSubRowSelection: false,
    autoResetPageIndex: false,
    filterFromLeafRows: true,
    muiTableHeadCellProps: {
      sx: {
        background: "var(--primary-color)", // Assuming this CSS variable is defined in your project
        color: "#fff",
        fontSize: "12px",
        overflowX: "hidden",
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "700px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      },
    },
    displayColumnDefOptions: {
      "mrt-row-drag": { size: 10, enablePinning: true },
      "mrt-row-select": { size: 10, enablePinning: true },
      "mrt-row-expand": { size: 10, enablePinning: true },
    },
    // displayColumnDefOptions: {
    //   "mrt-row-select": {
    //     size: 10,
    //     muiTableHeadCellProps: {
    //       align: "center",
    //       sx: {
    //         background: "var(--primary-color)", // Replace with your theme's primary color variable
    //         color: "#fff",
    //         fontSize: "12px",
    //         overflowX: "hidden",
    //         // lineHeight: "1.5", // Uncomment if needed for text wrapping
    //       },
    //     },
    //   },
    //   "mrt-row-expand": {
    //     size: 10,
    //   },
    // },
    muiTableHeadRowProps: {
      className: "scoped-table",
    },

    muiTableBodyRowProps: ({ row: currRow }) => ({
      className: `scoped-table type-${currRow.original.level}`,
      sx: {
        backgroundColor: getBackgroundColor(currRow.original.level, theme),
        // "& > *": {
        //   // align: "center", // Ensure all cells in the row align to center
        // },
        // // paddingLeft: "3px", // Add padding to the left of the row
      },
    }),
    onRowSelectionChange: (rowUpdater) => {
      // Check if the updater function returns a selection equal to the current selection
      if (typeof rowUpdater === "function") {
        const newSelection = rowUpdater(rowSelection);
        if (isEqual(newSelection, rowSelection)) {
          setRowSelection({}); // Clear selection if the new selection is the same as the current one
        } else {
          setRowSelection(newSelection); // Update the selection otherwise
        }
      } else {
        setRowSelection(rowUpdater); // Directly update with the provided selection if it's not a function
      }
    },
    getRowId: (originalRow) => originalRow.id,
    getSubRows: (originalRow) => originalRow.children || [],
    state: {
      rowSelection, // Preserving the row selection state
      // Dynamically setting the expanded rows based on the expandedNodes
      expanded:
        expandedNodes.length !== 0
          ? expandedNodes.reduce((acc, node) => {
              (acc as Record<string, boolean>)[node] = true;
              return acc;
            }, {} as MRT_ExpandedState)
          : {},
    },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (!hoveredRow?.original?.id || !draggingRow?.original?.id) return;

        const flattenedData = transformWBSTreeToList(
          projectWBS?.conceptWBS.children ?? []
        );
        const sourceRow = flattenedData.find((d) => d.id === draggingRow.original.id);
        const targetRow = flattenedData.find((d) => d.id === hoveredRow.original!.id);
        // restricts dragging within the same parent
        if (sourceRow && targetRow && sourceRow.parentId === targetRow.parentId) {
          const siblings = flattenedData.filter((d) => d.parentId === sourceRow.parentId);
          handleDragAndDrop(siblings, sourceRow, targetRow);
        } else {
          setSnackbar(true);
        }
      },
    }),
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Work Breakdown Structure</Typography>
        <ProjectScopeToolbar
          rowSelection={rowSelection}
          tableInstanceRef={projectScopeTable}
          data={projectWBS?.conceptWBS}
        />
      </Box>
      <MaterialReactTable
        table={projectScopeTable} // Pass the table instance directly to MaterialReactTable component
        // No need to spread getTableProps
      />
      <Snackbar
        open={snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={handleClose}>
        <SnackbarContent
          style={{ backgroundColor: theme.palette.primary.dark, color: "white" }}
          message="Please note, items can only be rearranged within their own group."
        />
      </Snackbar>
    </>
  );
}
