import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import { ToggleButton, Tooltip } from "@mui/material";

import { ViewTimeLineButtonTypes } from "@/features/projectSchedule/types";

export default function ViewTimeLineButton({
  isTimeLineView,
  onViewTimeLineChange,
}: ViewTimeLineButtonTypes) {
  return (
    <Tooltip title="Toggle Time Line View">
      <ToggleButton
        sx={{ transform: "scaleX(-1)" }}
        value={isTimeLineView ? "timeline" : "With-Table-View"}
        selected={isTimeLineView}
        onChange={() => onViewTimeLineChange("isTimeLineView", !isTimeLineView)}>
        <ViewTimelineIcon fontSize="medium" />
      </ToggleButton>
    </Tooltip>
  );
}
