import Box from "@mui/material/Box";

import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";

import DeleteCostButton from "../DeleteCostButton";

type CostActionsProps = {
  WBSEntry: WBSEntryModel | null;
  handleRemoveResource: (resourceId: string) => void;
  resourceToDelete: string;
};

export default function CostActions({
  resourceToDelete,
  WBSEntry,
  handleRemoveResource,
}: CostActionsProps) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <DeleteCostButton
        resourceToDelete={[resourceToDelete]}
        WBSEntry={WBSEntry}
        handleRemoveResource={handleRemoveResource}
      />
    </Box>
  );
}
