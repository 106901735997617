import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { LinearProgress } from "@mui/material";
import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import PrivateRoutes from "./Private";
import PublicRoutes from "./Public";

export default function Routing() {
  return (
    <Suspense fallback={<LinearProgress />}>
      <AuthenticatedTemplate>
        <RouterProvider router={createBrowserRouter(PrivateRoutes())} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <RouterProvider router={createBrowserRouter(PublicRoutes())} />
      </UnauthenticatedTemplate>
    </Suspense>
  );
}
