import { ParameterModels } from "../../../shared/types/parameterModels";

import { createParameter, updateParameter } from "./parametricService";

export default async function updateOrCreateParameter(parameter: ParameterModels) {
  let updatedParameter: ParameterModels;
  if (parameter?.id) {
    // If the parameter has an id, update it
    updatedParameter = await updateParameter(parameter);
  } else {
    // If the parameter doesn't have an id, it means it's a new parameter, so create it
    updatedParameter = await createParameter(parameter);
  }
  return updatedParameter;
}
