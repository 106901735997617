import { TableCell, TableRow } from "@mui/material";
import { useCallback } from "react";

import NumberField from "@/shared/components/NumberField";
import {
  WBSEntryModel,
  WBSEntryViewModel,
} from "@/shared/context/projectWBS/projectWBSModel";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { Units } from "@/shared/enums/units";
import { getTextColor } from "@/shared/utils";

import { OverviewTableModel } from "../models/overviewTableModel";
import editOverviewTableService from "../service/editOverviewTableService";
import { tableRowStyles } from "../styles/styles";

type TableRowProps = {
  row: OverviewTableModel;
  wbsEntry: WBSEntryModel | WBSEntryViewModel;
};

export default function OverviewTableRow({ row, wbsEntry }: TableRowProps) {
  const { refetchProjectWBS } = useProjectWBSContext();
  const { id, backgroundColor, name, cost, isEditable, duration, emissions } = row;
  const textColor = getTextColor(backgroundColor);
  const OnEditTable = useCallback(
    async (column: "cost" | "duration" | "emissions", value?: number): Promise<void> => {
      const updatedRow = {
        ...row,
        [column]: value,
      };
      if (wbsEntry) {
        await editOverviewTableService(updatedRow, wbsEntry as WBSEntryModel);
        refetchProjectWBS();
      } else {
        throw new Error("WBS Entry is null");
      }
    },
    [wbsEntry, refetchProjectWBS, row]
  );
  return (
    <TableRow
      key={id}
      sx={{
        ...tableRowStyles.row,
        background: backgroundColor,
      }}>
      <TableCell
        sx={{
          ...tableRowStyles.nameCell,
          color: textColor,
        }}>
        {name}
      </TableCell>
      <TableCell
        sx={{
          ...tableRowStyles.cellInCommon,
          width: "12rem",
        }}>
        <NumberField
          type="currency"
          handleBlur={(value) => OnEditTable("cost", Number(value))}
          hasDecimals={false}
          number={cost}
          isEditable={isEditable && name !== "Contingency"}
          color={textColor}
        />
      </TableCell>
      <TableCell
        sx={{
          ...tableRowStyles.cellInCommon,
          width: "9rem",
        }}>
        <NumberField
          type="duration"
          hasDecimals={false}
          number={duration}
          handleBlur={(value) => OnEditTable("duration", Number(value))}
          color={textColor}
          unit={Units.Day}
          isEditable={isEditable && name !== "Contingency"}
        />
      </TableCell>
      <TableCell
        sx={{
          ...tableRowStyles.cellInCommon,
          width: "11rem",
        }}>
        <NumberField
          type="emission"
          hasDecimals={false}
          number={emissions}
          handleBlur={(value) => OnEditTable("emissions", Number(value))}
          unit={Units.tCO2e}
          color={textColor}
          isEditable={isEditable}
        />
      </TableCell>
    </TableRow>
  );
}
