type TabPanelProps = {
  children?: React.ReactNode;
  name: string;
  value: string;
};

export default function CustomTabPanel(props: TabPanelProps) {
  const { children, value, name } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      id={`tabpanel-${name}`}
      aria-labelledby={`tab-${name}`}>
      {value === name && <div>{children}</div>}
    </div>
  );
}
