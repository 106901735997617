import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

import { UserManagementModel } from "@/features/tenantManagement/models/userManagementModel";
import { columnStyles } from "@/features/tenantManagement/styles/columnStyles";
import { stringAvatar } from "@/shared/utils/formattingFunctions";

const UserManagementColumns = () =>
  useMemo<MRT_ColumnDef<UserManagementModel>[]>(() => {
    return [
      {
        accessorKey: "image",
        header: "",
        size: 30,
        enableSorting: false,
        enableEditing: false,
        enableColumnActions: false,
        enableGlobalFilter: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <Box
            sx={{
              paddingLeft: "0.5rem",
            }}>
            <Avatar sx={columnStyles.avatar}>
              {row.original.name !== "" ? stringAvatar(row.original.name) : null}
            </Avatar>
          </Box>
        ),
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 100,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
              }}>
              {row.original.name}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: "var(--ecerto-cool-grey-dark)",
              }}>
              {row.original.contact}
            </Typography>
          </Box>
        ),
      },
      // {
      //   accessorKey: "group",
      //   header: "Group",
      //   size: 100,
      //   enableEditing: false,
      //   muiTableBodyCellProps: {
      //     align: "left",
      //   },
      //   Cell: ({ row }) => (
      //     <Box>
      //       <Typography>{row.original.group}</Typography>
      //     </Box>
      //   ),
      // },
      {
        accessorKey: "role",
        header: "Role",
        size: 100,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => (
          <Box>
            <Typography>{row.original.role}</Typography>
          </Box>
        ),
      },
    ];
  }, []);

export default UserManagementColumns;
