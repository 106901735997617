import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

import { SliderStyle } from "@/features/projectSchedule/styles/styles";
import { SliderTypes } from "@/features/projectSchedule/types";

export default function ScheduleSlider({
  label,
  value,
  onChange,
}: SliderTypes): React.ReactElement {
  return (
    <MenuItem>
      <Box sx={SliderStyle}>
        <Typography>{label}</Typography>
        <Slider
          aria-label={label}
          value={value}
          onChange={(_, newValue) => onChange(newValue as number)}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={1}
          max={10}
        />
      </Box>
    </MenuItem>
  );
}
