import Box from "@mui/material/Box";

import InviteUsersButton from "@/features/tenantManagement/components/userManagement/InviteUsersButton";

export default function UserManagementToolbar() {
  return (
    <Box
      sx={{
        marginLeft: "auto",
        display: "flex",
        marginBottom: "1rem",
      }}>
      <InviteUsersButton />
    </Box>
  );
}
