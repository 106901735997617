import { OverviewItemsModel } from "../model/baselineOverviewModels";

const BaselineOverviewSchema: OverviewItemsModel[] = [
  {
    id: "cost/unit",
    title: "Cost / Unit",
    amount: "0",
    color: "var(--ecerto-green)",
  },
  {
    id: "deterministicFixedCost",
    title: "Cost",
    amount: "0",
    color: "var(--ecerto-green)",
  },
  {
    id: "modeDuration",
    title: "Duration",
    amount: "0",
    color: "var(--ecerto-green)",
  },
  {
    id: "emissions",
    title: "Emissions",
    amount: "0",
    color: "var(--ecerto-green)",
  },
  {
    id: "standbyCostDay",
    title: "Standby Cost / Day",
    amount: "0",
    color: "var(--ecerto-green)",
  },
  {
    id: "impactCost",
    title: "Impact: Cost",
    amount: "0",
    color: "var(--ecerto-red)",
  },
  {
    id: "impactDuration",
    title: "Impact: Duration",
    amount: "0",
    color: "var(--ecerto-red)",
  },
  {
    id: "impactEmissions",
    title: "Impact: Emissions",
    amount: "0",
    color: "var(--ecerto-red)",
  },
];

export default BaselineOverviewSchema;
