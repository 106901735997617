import { Add } from "@mui/icons-material";

import addResourceToCost from "@/features/cost/services/addResourceToCost";
import PrimaryButton from "@/shared/components/PrimaryButton";

export default function AddResourceToCostButton({
  rowSelection,
  outcome,
  onCatalogueAndCostRefetch,
}: {
  rowSelection: string[];
  outcome: string;
  onCatalogueAndCostRefetch: () => void;
}) {
  const addResourceToCostAsync = async () => {
    try {
      await Promise.all(rowSelection.map((id) => addResourceToCost(outcome, id)));
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Error adding resource to cost: ${error.message}`);
      } else {
        throw new Error("An unknown error occurred while adding resource to cost.");
      }
    }
  };

  const handleAddResourceToCost = () => {
    void addResourceToCostAsync();
    onCatalogueAndCostRefetch();
  };

  return (
    <PrimaryButton
      sx={{
        marginRight: "5px",
      }}
      icon={<Add fontSize="small" />}
      onClick={handleAddResourceToCost}>
      Add to Cost Table
    </PrimaryButton>
  );
}
