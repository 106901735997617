import { User } from "@/shared/types/userInfoModels";

const defaultUser: User = {
  userId: "",
  emailAddress: "",
  firstName: "",
  lastName: "",
  fullName: "",
  role: "",
  officeLocation: "",
  jobTitle: "",
};

export const getUserInfo = (): User => {
  const storedUserInfo = localStorage.getItem("userInfo");

  if (storedUserInfo) {
    const userInfoObj = JSON.parse(storedUserInfo) as User;
    return userInfoObj;
  }

  return defaultUser;
};
