enum Risk {
  "Very Low",
  "Low",
  "Medium",
  "High",
  "Very High",
}

// This object is a map between the Risk enum keys and their corresponding display colours
const riskDisplayColors: Record<string, string> = {
  "Very Low": "#6c9781",
  Low: "#85b09a",
  Medium: "#fbd872",
  High: "#e59e6d",
  "Very High": "#ad5535",
};
export const getRiskColorFromIndex = (index: number): string => {
  const enumName = Risk[index];
  // as a fallback return the color for VeryLow
  return riskDisplayColors[enumName] || riskDisplayColors.VeryLow;
};
export default Risk;
