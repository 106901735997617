import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import LifeCycleStage from "@/shared/enums/lifeCycleStage";
import ProjectRegion from "@/shared/enums/projectRegion";
import Risk from "@/shared/enums/risk";

import { AddProjectModel } from "../../models/addProjectModel";

export type AddProjectFormProps = {
  onConfirm: (newProject: AddProjectModel) => void;
  innerRef: RefObject<FormikProps<AddProjectModel>>;
};

// Note that name and lifeCycleStage are the same as the name of the TextField.
const validationSchema = yup.object({
  name: yup
    .string()
    .max(100, "Name should not be longer than 75 characters")
    .required("Name is required"),
  programmeName: yup
    .string()
    .max(100, "Programme name should not be longer than 75 characters")
    .required("Programme name is required"),
});

export default function AddProjectForm({ onConfirm, innerRef }: AddProjectFormProps) {
  return (
    <Formik
      initialValues={{
        name: "",
        programmeName: "",
        region: ProjectRegion["Asia Pacific"],
        lifeCycleStage: LifeCycleStage.Execute + 1,
        riskLevel: Risk["Very High"] + 1,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        try {
          onConfirm(values);
        } catch (err) {
          if (err instanceof Error) {
            throw new Error(`Failed to confirm project: ${err.message}`);
          } else {
            throw new Error("Failed to confirm project: An unknown error occurred");
          }
        }
      }}
      innerRef={innerRef}>
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
          <Form>
            <TextField
              required
              label="Project Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
            />
            <TextField
              required
              label="Programme Name"
              id="programmeName"
              name="programmeName"
              value={values.programmeName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.programmeName && Boolean(errors.programmeName)}
              helperText={touched.programmeName && errors.programmeName}
              fullWidth
            />
            <TextField
              required
              label="Region"
              select
              id="region"
              name="region"
              value={values.region}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.region && Boolean(errors.region)}
              helperText={touched.region && errors.region}
              fullWidth>
              {Object.values(ProjectRegion)
                .filter((value) => typeof value === "string")
                .map((value, index) => {
                  return (
                    // @check change value={key} to value={displayName} if we want to send the name in the future
                    <MenuItem key={value} value={index}>
                      {value}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Form>
        </Box>
      )}
    </Formik>
  );
}
