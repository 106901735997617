// import { Box, Chip, Tabs, useTheme } from "@mui/material";
// import { useState } from "react";

import ScenarioOverview from "./ScenarioOverview";

// import Simulation from "@/features/simulation/Simulation";
// import CustomTab from "@/shared/components/CustomTab";
// import CustomTabPanel from "@/shared/components/CustomTabPanel";

export default function Transactional() {
  // const theme = useTheme();
  // const [isActiveTab, setActiveTab] = useState("overview");

  // const columnsParametric = ColumnsParametricForProcurementScenarios();

  return (
    <ScenarioOverview />
    // <>
    //   <Tabs
    //     value={isActiveTab}
    //     onChange={(_e, index) => setActiveTab(index)}
    //     aria-label="tabs"
    //     TabIndicatorProps={{
    //       style: {
    //         background: "red",
    //       },
    //     }}>
    //     <CustomTab
    //       label="Overview"
    //       value="overview"
    //       setActiveTab={setActiveTab}
    //       disabled
    //     />
    //     <CustomTab
    //       label={
    //         <Box display="flex" alignItems="center">
    //           Parametric Baseline
    //           <Chip
    //             label="Pro"
    //             size="small"
    //             sx={{
    //               backgroundColor: theme.palette.primary.main,
    //               color: theme.palette.primary.contrastText,
    //               marginLeft: "0.2rem",
    //               marginBottom: "1rem",
    //               fontSize: "0.6rem", // Adjust font size as needed
    //               height: "20px", // Adjust height as needed
    //               width: "47px", // Adjust width as needed
    //             }}
    //           />
    //         </Box>
    //       }
    //       value="parametric"
    //       setActiveTab={setActiveTab}
    //       disabled
    //     />
    //     <CustomTab
    //       label={
    //         <Box display="flex" alignItems="center">
    //           Cost Baseline
    //           <Chip
    //             label="Pro"
    //             size="small"
    //             sx={{
    //               backgroundColor: theme.palette.primary.main,
    //               color: theme.palette.primary.contrastText,
    //               marginLeft: "0.2rem",
    //               marginBottom: "1rem",
    //               fontSize: "0.6rem", // Adjust font size as needed
    //               height: "20px", // Adjust height as needed
    //               width: "47px", // Adjust width as needed
    //             }}
    //           />
    //         </Box>
    //       }
    //       value="cost"
    //       setActiveTab={setActiveTab}
    //       disabled
    //     />
    //     <CustomTab
    //       label={
    //         <Box display="flex" alignItems="center">
    //           Risk Baseline
    //           <Chip
    //             label="Pro"
    //             size="small"
    //             sx={{
    //               backgroundColor: theme.palette.primary.main,
    //               color: theme.palette.primary.contrastText,
    //               marginLeft: "0.2rem",
    //               marginBottom: "1rem",
    //               fontSize: "0.6rem",
    //               height: "20px",
    //               width: "47px",
    //             }}
    //           />
    //         </Box>
    //       }
    //       value="risk"
    //       setActiveTab={setActiveTab}
    //       disabled
    //     />
    //   </Tabs>
    //   <CustomTabPanel name="overview" value={isActiveTab}>
    //     <ScenarioOverview />
    //   </CustomTabPanel>
    //   <CustomTabPanel name="parametric" value={isActiveTab} />
    //   <CustomTabPanel name="cost" value={isActiveTab} />
    //   <CustomTabPanel name="risk" value={isActiveTab} />
    // </>
  );
}
