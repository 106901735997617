export { formatNumberLabel } from "./formattingFunctions";
export { getSelectedCurrency } from "./formattingFunctions";

/*
Ensuring proper contrast between the text colour and the background colour
is an important part of meeting WCAG 2.1 AA guidelines.
One strategy is to check the background colour and choose a light or dark text colour based on its brightness.
*/

// @check I am just experimenting. This is supposed ...
// @check ...to get the text colour based on the background colour
// Function to calculate brightness of a colour
const getBrightness = (colour: string): number => {
  const rgb = parseInt(colour.slice(1), 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  // Perceived brightness formula: https://www.w3.org/TR/AERT/#color-contrast
  return (r * 299 + g * 587 + b * 114) / 1000;
};

export const getTextColor = (backgroundColor: string): string => {
  if (backgroundColor === "#85b09a" || backgroundColor === "#003057") {
    return "#fff";
  }
  return "#003057";
};

// Function to get text colour based on background colour
// We pass a string in HEX format, e.g. "#FFFFFF"
export const getTextColorTemp = (bgColour: string): string => {
  // Convert the colour to brightness
  const brightness = getBrightness(bgColour);

  // Use a threshold (128) to determine if the colour is dark or light
  // If it's dark, return white. If it's light, return black
  // @todo define light and dark text colours in :root variables
  return brightness < 128 ? "#FFFFFF" : "#000000";
};

// Format a number as a currency string with the locale
// @todo add locales to store
export const formatCurrency = (amount = 0): string => {
  // https://www.w3schools.com/jsref/jsref_tolocalestring_number.asp
  const selectedCurrency = (import.meta.env.VITE_CURRENCY as string) || "USD";
  const selectedLocale = (import.meta.env.VITE_LOCALE as string) || "en-GB";

  return amount.toLocaleString(selectedLocale, {
    style: "currency",
    currency: selectedCurrency,
  });
};

export const getSelectedCurrencyTemp = (): string => {
  const selectedCurrency = (import.meta.env.VITE_CURRENCY as string) || "USD";
  const currencySymbol = (0)
    .toLocaleString("en", { style: "currency", currency: selectedCurrency })
    // eslint-disable-next-line no-useless-escape -- needed to implement regex
    .replace(/[\d\.]/g, "")
    .trim();
  return currencySymbol;
};
export const formatCurrencyNoDecimals = (amount = 0): string => {
  const selectedCurrency = (import.meta.env.VITE_CURRENCY as string) || "USD";
  const selectedLocale = (import.meta.env.VITE_LOCALE as string) || "en-GB";

  return amount.toLocaleString(selectedLocale, {
    style: "currency",
    currency: selectedCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const getCurrencySymbol = (): string => {
  const selectedCurrency = (import.meta.env.VITE_CURRENCY as string) || "USD";
  const selectedLocale = (import.meta.env.VITE_LOCALE as string) || "en-GB";

  return (0)
    .toLocaleString(selectedLocale, {
      style: "currency",
      currency: selectedCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "");
};

export const formatEmissions = (num: number): string => {
  if (!num && num !== 0) return "";

  const formattedNumber = Math.floor(num).toLocaleString();

  return `${formattedNumber} tCO₂e`;
};

export const formatPerDays = (num: number): string => {
  if (!num && num !== 0) return "";

  const formattedNumber = Math.floor(num).toLocaleString();

  return `${formattedNumber} / day`;
};

export const formatDays = (num: number): string => {
  if (!num && num !== 0) return "";

  const formattedNumber = Math.floor(num).toLocaleString();

  return `${formattedNumber} days`;
};

export const formatNumberWithCommas = (num: number): string => {
  if (!num && num !== 0) return "";

  const formattedNumber = num.toLocaleString(undefined, { maximumFractionDigits: 0 });

  return formattedNumber;
};
export const formatDate = (date: string | Date): string => {
  if (!date) return "";

  const selectedLocale = (import.meta.env.VITE_LOCALE as string) || "en-GB";
  const dateObject = typeof date === "string" ? new Date(date) : date;
  return dateObject.toLocaleDateString(selectedLocale, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

// Function to validate UUID
export const isValidUUID = (uuid: string): boolean => {
  const regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return regex.test(uuid);
};
