import { useMsal } from "@azure/msal-react";

import { loginRequest } from "@/shared/config/authConfig";

export const useAuth = () => {
  const { instance } = useMsal();

  const logIn = async () => {
    try {
      await instance.loginPopup(loginRequest);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error("Login failed: " + error.message);
      } else {
        throw new Error("Login failed: An unknown error occurred");
      }
    }
  };

  const logOut = async () => {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/login",
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error("Logout failed: " + error.message);
      } else {
        throw new Error("Logout failed: An unknown error occurred");
      }
    }
  };

  return { logIn, logOut };
};
