import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { formatCurrencyNoDecimals } from "@/shared/utils";

export default function SidebarSummaryTable() {
  const { getWBSEntryById } = useProjectWBSContext();

  const WBSEntry = getWBSEntryById();
  // @todo if WBSEntry is null return custom message.
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody
          style={{
            backgroundColor: "var(--primary-color)",
          }}>
          <TableRow
            key="estimatedCost"
            style={{
              backgroundColor: "var(--ecerto-green)",
            }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              Project
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              {formatCurrencyNoDecimals(Number(WBSEntry?.costForProject))}
            </TableCell>
          </TableRow>
          <TableRow
            key="managementReserve"
            style={{
              backgroundColor: "var(--primary-color)",
              borderColor: "var(--primary-color)",
            }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              Reserve
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              {formatCurrencyNoDecimals(Number(WBSEntry?.reserveForCost))}
            </TableCell>
          </TableRow>
          <TableRow
            key="projectBudget"
            style={{ backgroundColor: "var(--ecerto-green)" }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              Budget
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              {formatCurrencyNoDecimals(Number(WBSEntry?.budgetForCost))}
            </TableCell>
          </TableRow>
          <TableRow key="executionContingency">
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              Contingency
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              {formatCurrencyNoDecimals(Number(WBSEntry?.contingencyForCost))}
            </TableCell>
          </TableRow>
          <TableRow
            key="costBaselineFromSimulation"
            style={{ backgroundColor: "var(--ecerto-green)" }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              Baseline
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              {formatCurrencyNoDecimals(Number(WBSEntry?.baselineForCost))}
            </TableCell>
          </TableRow>
          <TableRow key="scopeAllowance">
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              Allowance
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              {formatCurrencyNoDecimals(Number(WBSEntry?.allowanceForCost))}
            </TableCell>
          </TableRow>
          <TableRow
            key="controlEstimate"
            style={{ backgroundColor: "var(--ecerto-green)" }}>
            <TableCell
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              Estimate
            </TableCell>
            <TableCell
              align="right"
              style={{
                color: "white",
                borderColor: "var(--primary-color)",
              }}>
              {formatCurrencyNoDecimals(Number(WBSEntry?.estimateForCost ?? "0"))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
