import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import SignUpForm from "@/app/pages/auth/signup/SignUpForm";
import Logo from "@/shared/assets/logos/eCERTO_flat_white.png";

export default function SignUp() {
  return (
    <Grid container component="main">
      <Grid
        item
        xs={false}
        sm={false}
        md={6}
        lg={7}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
          },
          backgroundRepeat: "no-repeat",
          backgroundColor: "primary.main",
          backgroundSize: "cover",
          backgroundPosition: "center",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <img src={Logo} alt="Logo" width={150} />
        <Typography
          component="h1"
          color="white"
          sx={{
            fontWeight: "light",
            fontSize: {
              // xs: 70,
              // sm: 70,
              md: 80,
              lg: 90,
              xl: 100,
            },
          }}>
          eCERTO
        </Typography>
        <Typography
          component="h2"
          color="white"
          letterSpacing={8}
          sx={{
            fontWeight: "light",
            marginBottom: "10px",
            fontSize: {
              // xs: 10,
              // sm: 10,
              md: 14,
              lg: 17,
              xl: 20,
            },
          }}>
          BEYOND eCOMMERCE
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={5}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Typography
            component="h1"
            sx={{
              fontWeight: "light",
              fontSize: {
                xs: 70,
                sm: 80,
                md: 85,
                lg: 90,
                xl: 100,
              },
            }}
            color="text.secondary">
            INTEGRATI
          </Typography>
          <Typography
            component="h2"
            color="text.secondary"
            letterSpacing={3}
            sx={{
              mb: 3,
              textAlign: "center",
              fontWeight: {
                xs: "bold",
                sm: "normal",
              },
              fontSize: {
                xs: 10,
                sm: 12,
                md: 13,
                lg: 14,
                xl: 16,
              },
            }}>
            TRANSFORMING YOUR COMMERCIAL DNA
            <span
              style={{
                verticalAlign: "super",
                fontSize: "0.6em",
                marginLeft: "0.1em",
              }}>
              TM
            </span>
          </Typography>

          <SignUpForm />
        </Box>
      </Grid>
    </Grid>
  );
}
