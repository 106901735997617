import { AxiosError, AxiosResponse } from "axios";

import { PROJECTS_API_ENDPOINTS } from "@/shared/api/api";
import apiRequests from "@/shared/config/axiosConfig";

type DeleteProjectResponse = {
  message: string;
  statusCode: number;
};

// Batch delete projects by passing a string of project IDs
export default async function deleteProjects(
  projectIDs: string[]
): Promise<DeleteProjectResponse> {
  try {
    const url = PROJECTS_API_ENDPOINTS.BATCH_DELETE_PROJECTS;

    const response: AxiosResponse<DeleteProjectResponse> = await apiRequests.post(
      url,
      projectIDs
    );

    const { data, status } = response;

    if (status >= 200 && status < 300) {
      return data;
    }

    throw new Error(`Unknown error occurred with status code ${status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}
