enum ProjectRegion {
  "Asia Pacific",
  "Russia & CIS",
  "MENAT",
  "Sub-Saharan Africa",
  "Europe",
  "North America",
  "Latin America",
}

export default ProjectRegion;
