import { Edit } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useRef, useState } from "react";
import * as yup from "yup";

import editDeliverable from "@/shared/api/editDeliverable";
import ReusableDialog from "@/shared/components/ReusableDialog";
import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string().max(999, "Max length is 1000 characters").notRequired(),
});

export default function EditProjectScope({
  row,
  clearRowSelection,
}: {
  row: WBSEntryViewModel;
  clearRowSelection: () => void;
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const { refetchProjectWBS } = useProjectWBSContext();
  const editFormikRef = useRef<FormikProps<WBSEntryViewModel>>(null);

  const handleSubmitForm = () => {
    if (isModalOpen && editFormikRef.current) {
      void editFormikRef.current.submitForm(); // Explicitly ignore the returned promise
      setModalOpen(false);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          width: "fit-content",
          maxWidth: "fit-content",
          marginTop: "24px",
          transition: "transform 0.2s",
          "&:focus": {
            outline: "none",
          },
          marginLeft: "0.5rem",
        }}
        startIcon={<Edit fontSize="small" />}
        onClick={() => setModalOpen(true)}>
        Edit Outcome
      </Button>

      <ReusableDialog
        isOpen={isModalOpen}
        title="Edit Deliverable"
        cancelButtonCallback={handleClose}
        confirmButtonCallback={handleSubmitForm}
        confirmButtonType="primary"
        confirmButtonText="Confirm"
        size="medium">
        <Formik
          onSubmit={async (values) => {
            const modelPayload = {
              id: row.id,
              projectId: row.projectId,
              parentId: row.parentId,
              level: Number(row.level) + 1,
              name: values.name,
              start: row.startDate,
              wbsCode: row.wbsCode || "",
              description: values.description,
            } as unknown as WBSEntryViewModel;
            try {
              await editDeliverable(modelPayload);
            } catch (error) {
              // @todo handle error
            } finally {
              refetchProjectWBS();
              clearRowSelection();
              handleClose();
            }
          }}
          initialValues={{
            projectId: "",
            parentId: "",
            id: "",
            wbsCode: "",
            order: 0,
            name: row.name,
            description: row.description,
            level: 0,
            categoryType: "",
            deliverableType: "",
            startDate: new Date(),
            estimateEndDate: new Date(),
            budgetEndDate: new Date(),
            children: [],
            standbyCost: 0,
            height: 0,
            estimateForCost: 0,
            allowanceForCost: 0,
            baselineForCost: 0,
            contingencyForCost: 0,
            budgetForCost: 0,

            estimateForDuration: 0,
            allowanceForDuration: 0,
            baselineForDuration: 0,
            contingencyForDuration: 0,
            budgetForDuration: 0,

            estimateForEmissions: 0,
            allowanceForEmission: 0,
            baselineForEmission: 0,
            contingencyForEmission: 0,
            budgetForEmission: 0,
          }}
          validationSchema={validationSchema}
          innerRef={editFormikRef}>
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
              <Form>
                <TextField
                  required
                  label="Deliverable Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  fullWidth
                />
                <TextField
                  label="Scope Statement"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                  fullWidth
                  multiline
                  rows={5}
                  inputProps={{ maxLength: 1000 }}
                />
              </Form>
            </Box>
          )}
        </Formik>
      </ReusableDialog>
    </>
  );
}
