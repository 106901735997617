// import { NumberFormatValues } from "react-number-format";

import { NumberFormatValues } from "react-number-format";

import {
  // DURATION_RANGE,
  // DURATION_RISK_RANGE,
  // EMISSION_RANGE,
  // LARGE_AMOUNT_RANGE,
  // MID_AMOUNT_NEGATIVE_RANGE,
  // PERCENTAGE_RANGE,
  DigitRange,
} from "@/shared/constants";
import { getCurrencySymbol } from "@/shared/utils";

// Determine decimal scale based on category
// export const getDecimalScale = (category: string): number | undefined => {
//   switch (category) {
//     case "price":
//       return 2;
//     case "cost":
//     case "emission":
//     case "duration":
//     case "percentage":
//       return 0;
//     default:
//       return undefined;
//   }
// };

// Determine suffix based on category
const getUnit = (category: string, customUnit?: string): string | undefined => {
  switch (category) {
    case "emission":
      return " tCO₂e ";
    case "duration":
      return " days ";
    case "percentage":
      return "%";
    default:
      return customUnit ?? undefined;
  }
};
type DecoratorSymbol = {
  prefix: string | undefined;
  suffix: string | undefined;
};

export const getDecorationSymbol = (
  category: string,
  customUnit?: string
): DecoratorSymbol => {
  const decoratorSymbol: DecoratorSymbol = { prefix: undefined, suffix: undefined };

  if (category === "price" || category === "cost") {
    decoratorSymbol.prefix = getCurrencySymbol();
  } else {
    decoratorSymbol.suffix = getUnit(category, customUnit);
  }

  return decoratorSymbol;
};

export const isWithinRange = (value: number | string, range: DigitRange) => {
  return (values: NumberFormatValues): boolean => {
    const numericValue = Number(value);
    const floatValue = values.floatValue;

    // Check if conversion to number fails (NaN check)
    if (isNaN(numericValue) || floatValue === undefined) {
      return false;
    }

    // Check if the value is within the range
    return numericValue >= range.min && numericValue <= range.max;
  };
};

// export const getAllowed = (values: NumberFormatValues, category: string): boolean => {
//   const { floatValue } = values;

//   // If floatValue is undefined, return false (or true depending on your logic)
//   if (floatValue === undefined) {
//     return false;
//   }

//   switch (category) {
//     case "price":
//     case "cost":
//       return floatValue >= LARGE_AMOUNT_RANGE.min && floatValue <= LARGE_AMOUNT_RANGE.max;
//     case "costInput":
//       return (
//         floatValue >= MID_AMOUNT_NEGATIVE_RANGE.min &&
//         floatValue <= MID_AMOUNT_NEGATIVE_RANGE.max
//       );
//     case "emission":
//     case "emissionInput":
//       return floatValue >= EMISSION_RANGE.min && floatValue <= EMISSION_RANGE.max;
//     case "duration":
//       return floatValue >= DURATION_RANGE.min && floatValue <= DURATION_RANGE.max;
//     case "durationInput":
//       return (
//         floatValue >= DURATION_RISK_RANGE.min && floatValue <= DURATION_RISK_RANGE.max
//       );
//     case "percentage":
//       return floatValue >= PERCENTAGE_RANGE.min && floatValue <= PERCENTAGE_RANGE.max;
//     case "quantity":
//       return floatValue >= LARGE_AMOUNT_RANGE.min && floatValue <= LARGE_AMOUNT_RANGE.max;
//     default:
//       // Default case if category doesn't match any case above
//       return true;
//   }
// };
