import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";

/**
 * Returns the ProjectScopeModel object with the given id, or null if not found.
 * If the object is not found in the top level array, it will recursively search
 * through the children arrays of each object until it is found.
 * @param id - The id of the ProjectScopeModel object to find.
 * @param arr - The array of ProjectScopeModel objects to search through.
 * @returns The ProjectScopeModel object with the given id, or null if not found.
 */
export default function findId(
  id: string,
  arr: WBSEntryViewModel[]
): WBSEntryViewModel | null {
  return arr.reduce<WBSEntryViewModel | null>((a, item) => {
    if (a) return a;
    if (item.id === id) return item;
    if (item.children) return findId(id, item.children);
    return null;
  }, null);
}
