export const tableRowStyles = {
  row: {
    "& .MuiTableCell-root": {
      paddingRight: "2.5rem",
      border: "none",
      fontSize: "1.30rem",
      fontWeight: "500 !important",
    },
    "& .MuiTypography-root": {
      fontWeight: "500 !important",
    },
    "& .numberLabel": {
      fontSize: "1.30rem",
    },
  },
  nameCell: {
    paddingLeft: "2rem",
    width: "9rem",
    paddingRight: "5rem",
  },
  cellInCommon: {
    paddingRight: "2.5rem",
    paddingLeft: "2.5rem",
    border: "none",
    textAlign: "right",
  },
  durationCell: {
    width: "9rem",
  },
  emissionCell: {
    width: "11rem",
  },
};

export const overviewDetailCardStyles = {
  root: {
    width: "40%",
    borderRight: "0.35rem solid var(--ecerto-breakdown-brown)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "4rem 1.875rem",
    textAlign: "center",
    color: "white",
    backgroundColor: "var(--primary-color)",
    "& .MuiTypography-subtitle1": {
      color: "#a7b4c2",
    },
  },
  dateBox: {
    display: "flex",
    justifyContent: "space-around",
  },
};
