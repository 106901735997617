import { useParams } from "react-router-dom";

import InvalidConceptID from "@/app/pages/InvalidConcept";
import ConceptContextProvider from "@/shared/context/conceptContext/ConceptContext";
import ProjectWBSProvider from "@/shared/context/projectWBS/ProjectWBSContext";
import WBSLevelsContextProvider from "@/shared/context/WBSLevelsContext";
import { isValidUUID } from "@/shared/utils";

type StrategyDefinitionProviderProps = {
  children: React.ReactNode;
};
export default function StrategyDefinitionProvider({
  children,
}: StrategyDefinitionProviderProps) {
  const { id: conceptId } = useParams();
  if (!conceptId || !isValidUUID(conceptId)) {
    return <InvalidConceptID />;
  }
  return (
    <ProjectWBSProvider conceptId={conceptId}>
      <ConceptContextProvider>
        <WBSLevelsContextProvider>{children}</WBSLevelsContextProvider>
      </ConceptContextProvider>
    </ProjectWBSProvider>
  );
}
