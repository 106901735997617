import { useTheme, Grid, Typography } from "@mui/material";

import { OverviewItemsModel } from "../model/baselineOverviewModels";

import { getOverviewItemStyles } from "./baselineOverviewStyles";

type OverviewItemProps = {
  item: OverviewItemsModel;
  type?: string;
};
const getWidth = (title: string): string => {
  if (title.includes("Cost / Unit") || title.includes("Standby Cost / Day")) {
    return "17rem";
  }
  if (title.includes("Cost")) {
    return "11rem";
  }
  if (title.includes("Duration")) {
    return "8rem";
  }
  if (title.includes("Emissions")) {
    return "9rem";
  }
  return "15rem";
};

export default function OverviewItem({ item, type }: OverviewItemProps) {
  const theme = useTheme();
  const { typographyAmountStyle } = getOverviewItemStyles(theme);

  let amountDisplay = item.amount;
  let colorDisplay = item.color;
  const itemWidth = getWidth(item.title);

  if (type === "estimate") {
    colorDisplay = "var(--ecerto-green)";
  } else if (type === "risk") {
    amountDisplay = `${item.amount}`;
    colorDisplay = "var(--ecerto-red)";
  }

  return (
    <Grid
      item
      sx={{
        textAlign: "right",
        width: itemWidth,
        // border: "1px solid red"
      }}>
      {item && (
        <Typography key={item.id} color={colorDisplay} style={typographyAmountStyle}>
          {amountDisplay}
        </Typography>
      )}
    </Grid>
  );
}
