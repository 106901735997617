export type DigitRange = {
  min: number;
  max: number;
};
export const BIG_OUTPUT_CURRENCY_WIDTH = "15rem"; // Width for big currency output fields
export const OUTPUT_CURRENCY_WIDTH = "11rem"; // Standard width for currency output fields
export const INPUT_QUANTITY_WIDTH = "9rem"; // Width for quantity input fields
export const INPUT_CURRENCY_WIDTH = "9rem"; // Width for currency input fields
export const INPUT_EMISSIONS_WIDTH = "7.5rem"; // Width for emissions input fields
export const INPUT_DURATION_WIDTH = "6rem"; // Width for duration input fields
export const INPUT_PERCENTAGE_WIDTH = "5rem"; // Width for percentage input fields

// For Portfolio-NPV
export const PERCENTAGE_RANGE = {
  min: 0,
  max: 100,
};
// For Risk-probability
export const PERCENTAGE_NEGATIVE_RANGE = {
  min: -100,
  max: 100,
};

// 99 Billion For step 3 Cost Table (Totals)
export const X_LARGE_AMOUNT_RANGE = {
  min: 0,
  max: 99999999999,
};

// 999million For step 3 Cost Table (Quantity)
export const LARGE_AMOUNT_RANGE = {
  min: 0,
  max: 999999999,
};

// 99 million. For step 4 (Allowance Reserve), step 3 parametric (Outcome)
export const MID_AMOUNT_RANGE = {
  min: 0,
  max: 99999999,
};

// 99 million For step 3 resource catalogue(Fixed Price)
export const MID_AMOUNT_NEGATIVE_RANGE = {
  min: -99999999,
  max: 99999999,
};

// 9 million For step 3 Risk table (remedial cost)
export const SMALL_AMOUNT_NEGATIVE_RANGE = {
  min: -9999999,
  max: 9999999,
};

// 9 million For Emissions
export const EMISSION_RANGE = {
  min: 0,
  max: 9999999,
};

export const DURATION_RANGE = {
  min: 0,
  max: 9999,
};

export const DURATION_RISK_RANGE = {
  min: 0,
  max: 999,
};
