import {
  AuthenticationResult,
  EventMessage,
  EventType,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const redirectUri = import.meta.env.VITE_ENTRA_REDIRECT_URI as string;
const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_AZURE_CLIENT_ID as string,
    authority: `https://login.microsoftonline.com/${
      import.meta.env.VITE_AZURE_TENANT_ID as string
    }`,
    redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored.
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        // message: string,
        containsPii: string
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
          case LogLevel.Verbose:
          case LogLevel.Warning:
            break;
          default:
            break;
        }
      },
    },
  },
};
// Retrieve the environment variable as a string.
let scopesString = import.meta.env.VITE_ENTRA_SCOPES as string;

// Clean up the string: remove surrounding quotes and any newline characters
scopesString = scopesString
  .replace(/\\n/g, "") // Remove newline characters
  .replace(/\\"/g, '"') // Replace escaped quotes with normal quotes
  .replace(/^"(.*)"$/, "$1"); // Remove surrounding quotes

// Parse the cleaned string into an array
const scopes = JSON.parse(scopesString) as string[];
export const loginRequest = {
  scopes,
};

// MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance
  .initialize()
  .then(() => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const { account } = payload;
        msalInstance.setActiveAccount(account);
      }
    });
  })
  .catch((error) => {
    throw new Error("Error during MSAL initialization: " + error);
  });

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export default msalInstance;
