// ReusableModal.tsx
import { DialogActions, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

type ModalProps = {
  title: string;
  children: React.ReactNode;
  confirmButtonText?: string;
  confirmButtonType?: "primary" | "error" | "secondary";
  size?: "small" | "medium";
  confirmButtonCallback?: (...args: unknown[]) => Promise<void> | void;
  cancelButtonCallback: () => void;
  isOpen: boolean;
};

export default function ReusableDialog({
  title,
  children,
  confirmButtonText = "Confirm",
  confirmButtonType = "primary",
  size = "medium",
  confirmButtonCallback,
  cancelButtonCallback,
  isOpen,
}: ModalProps) {
  const confirmButtonVariant =
    confirmButtonType === "secondary" ? "outlined" : "contained";
  const bottomWidthSize = size === "medium" ? "19rem" : "12rem";
  const modalWidth = size === "medium" ? "46.5rem" : "30rem";

  const handleConfirmButtonClick = () => {
    if (confirmButtonCallback) {
      Promise.resolve(confirmButtonCallback()).catch((error) => {
        if (error instanceof Error) {
          throw new Error(`Error during confirm button click: ${error.message}`);
        } else {
          throw new Error("Error during confirm button click");
        }
      });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={cancelButtonCallback}
      PaperProps={{
        sx: {
          borderRadius: "0.5rem",
          padding: "1rem",
          width: modalWidth,
        },
      }}>
      {title && (
        <DialogTitle
          sx={{
            fontSize: "1.8rem",
            letterSpacing: "0.05rem",
            fontWeight: "800",
            textAlign: "center",
            paddingBottom: "1.5rem",
          }}>
          {title.toUpperCase()}
        </DialogTitle>
      )}

      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          padding: "1.5rem 1.5rem 1rem",
        }}>
        <Button
          onClick={cancelButtonCallback}
          color="primary"
          variant="outlined"
          size="large"
          sx={{ width: bottomWidthSize }}>
          <Typography variant="button">Cancel</Typography>
        </Button>
        <Button
          onClick={handleConfirmButtonClick}
          size="large"
          color={confirmButtonType}
          variant={confirmButtonVariant}
          sx={{ width: bottomWidthSize }}>
          <Typography variant="button">{confirmButtonText}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
