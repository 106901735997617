import { TaskType as WBSEntryType } from "@ecerto/gantt-chart/dist/types/public-types";

import {
  ProjectWBS,
  WBSEntryModel,
  WBSEntryViewModel,
} from "@/shared/context/projectWBS/projectWBSModel";
import { IScheduleItems } from "@/shared/types/scheduleItems";

export const getTopLevelWBSEntry = (projectWBS: ProjectWBS): WBSEntryModel => {
  const { ...rest } = projectWBS.conceptWBS;
  const WBSEntry: WBSEntryModel = {
    ...rest,
    selectedScenario: projectWBS.selectedScenario,
    emissionForProject: projectWBS.emissionForProject,
    reserveForEmission: projectWBS.reserveForEmission,
    durationForProject: projectWBS.durationForProject,
    reserveForDuration: projectWBS.reserveForDuration,
    costForProject: projectWBS.costForProject,
    reserveForCost: projectWBS.reserveForCost,
    projectEndDate: projectWBS.projectEndDate,
  };
  return WBSEntry;
};

export const findEntryInProjectWBS = (
  projectWBS: ProjectWBS,
  entryID: string
): WBSEntryModel | null => {
  const { selectedScenario, conceptWBS } = projectWBS;
  if (conceptWBS.id === entryID) {
    const topLevelEntry = getTopLevelWBSEntry(projectWBS);
    return topLevelEntry;
  }

  const recursiveSearch = (
    WBSEntry: WBSEntryViewModel,
    id: string
  ): WBSEntryModel | null => {
    if (WBSEntry.id === id) {
      const { ...rest } = WBSEntry;
      const Entry: WBSEntryModel = {
        ...rest,
        selectedScenario,
      };
      return Entry;
    }

    for (const child of WBSEntry.children) {
      const found = recursiveSearch(child, id);
      if (found) {
        return found;
      }
    }

    return null;
  };

  return recursiveSearch(conceptWBS, entryID);
};

/**
 * Checks if a WBSEntry has children.
 */
const hasChildren = (WBSEntry: WBSEntryViewModel): boolean => {
  return WBSEntry.children && WBSEntry.children.length > 0;
};

/**
 * Returns the type of the WBS entry based on the number of children it has and whether it has an outcome duration.
 */
const getWBSEntryType = (WBSEntry: WBSEntryViewModel) => {
  if (hasChildren(WBSEntry) || WBSEntry.level <= 2) {
    return "project"; // project in INTEGRATI is a WBSComponent
  }
  if (WBSEntry.estimateForDuration === 0) {
    return "milestone";
  }
  // If the WBS entry has no children, it is a work package
  return "task"; // task in INTEGRATI is a work package
};

/**
 * Checks if a WBSEntry is not a work package, meaning it is a WBSComponent
 */
const isWBSComponent = (type: WBSEntryType) => {
  return type !== "task" && type !== "milestone"; // task in INTEGRATI is a work package
};

/**
 * This function sets the background color based on the WBS entry level, creates a new schedule item, and adds it to the scheduleItems array. It processes any children recursively and returns the updated array.
 *
 * @param {WBSEntryViewModel} WBSEntry - The WBS entry to convert.
 * @param {IScheduleItems[]} scheduleItems - An optional array of schedule items. If provided, the function will add the converted WBS entries to this array.
 *
 * @returns {IScheduleItems[]} An array of schedule items. Each schedule item represents a WBS entry and its children.
 */
export const convertToScheduleItems = (
  WBSEntry: WBSEntryViewModel,
  scheduleItems: IScheduleItems[] = []
): IScheduleItems[] => {
  let backgroundColor = "defaultColor";

  switch (WBSEntry.level) {
    case 0:
      backgroundColor = "rgb(255, 255, 255)";
      break;
    case 1:
      backgroundColor = "rgb(255, 255, 255)";
      break;
    case 2:
      backgroundColor = "rgb(245, 245, 245)";
      break;
    case 3:
      // Level 3 tasks only have barBackgroundColor
      backgroundColor = "rgb(239, 239, 239)";
      break;
    default:
      backgroundColor = "white";
  }

  const scheduleItem: IScheduleItems = {
    id: WBSEntry.id,
    name: WBSEntry.name,
    // start: startDate,
    start: new Date(WBSEntry.startDate),
    end: new Date(WBSEntry.estimateEndDate || WBSEntry.startDate),
    type: getWBSEntryType(WBSEntry),
    duration: WBSEntry.estimateForDuration,
    progress: 100, // You might want to calculate this value based on the WBSEntry data
    level: WBSEntry.level,
    project: WBSEntry.parentId,
    hideChildren: WBSEntry.children.length > 0 ? true : undefined,
    isDisabled: isWBSComponent(getWBSEntryType(WBSEntry)),
    styles: {
      backgroundColor,
    },
    // dependencies: [], // You might want to fill this array based on the WBSEntry data
  };

  // Push the current scheduleItem to the list
  scheduleItems.push(scheduleItem);

  // If the current WBSEntry has children, map them to IScheduleItems objects and add them to the list
  if (WBSEntry.children.length > 0) {
    WBSEntry.children.forEach((child) => {
      convertToScheduleItems(child, scheduleItems);
    });
  }

  return scheduleItems;
};
