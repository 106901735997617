import { AxiosResponse, AxiosError } from "axios";

import { GetRiskLegacyBaseModel } from "@/features/risk/models/riskLegacyModel";
import { RiskBaseModel } from "@/features/risk/models/riskModels";
import { legacyRiskModelToNewModel } from "@/features/risk/utils/formatLegacyRiskModels";
import { RISK_API_ENDPOINTS } from "@/shared/api/api";
import apiRequests from "@/shared/config/axiosConfig";

/**
 * Retrieves a risk by its ID.
 * @param id The ID of the risk to retrieve.
 * @returns A Promise that resolves to a RiskModel object, or null if an error occurs.
 * @throws An error if the request fails or returns an error status code.
 */
export default async function getProjectBaseRisks(
  wbsEntryId: string
): Promise<RiskBaseModel[]> {
  try {
    const url = RISK_API_ENDPOINTS.GET_BASELINE_RISKS.replace("{wbsEntryId}", wbsEntryId);
    const response: AxiosResponse<{ deliverableRisks: GetRiskLegacyBaseModel[] }> =
      await apiRequests.get(url);

    if (response.status === 200) {
      // Use type assertion to specify the type of data
      const data = response.data;
      const riskLegacyModel: GetRiskLegacyBaseModel[] = data.deliverableRisks;

      // @todo: This is a temporary fix to convert the legacy model to the new model. This should be removed once the API is updated.
      const risks: RiskBaseModel[] = legacyRiskModelToNewModel(riskLegacyModel);

      return risks;
    }

    throw new Error(`Unknown error occurred with status code ${response.status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}
