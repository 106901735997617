/*eslint-disable @typescript-eslint/no-empty-function -- allowing us to pass empty functions in createContext is the least verbose solution */

import { createContext, useState, useCallback, useMemo, ReactNode } from "react";

import TenantManagement from "@/shared/enums/tenantManagement";

type TenantManagementCtxType = {
  isModalOpen: boolean;
  activeContent: TenantManagement | null;
  closeModal: () => void;
  updateActiveContent: (content: TenantManagement) => void;
  openModalWithContent: (content: TenantManagement) => void;
};

export const TenantManagementCtx = createContext<TenantManagementCtxType>({
  isModalOpen: false,
  activeContent: null,
  closeModal: () => {},
  updateActiveContent: () => {},
  openModalWithContent: () => {},
});

export const TenantManagementProvider = ({ children }: { children: ReactNode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeContent, setActiveContent] = useState<TenantManagement | null>(null);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const updateActiveContent = useCallback((content: TenantManagement) => {
    setActiveContent(content);
  }, []);

  const openModalWithContent = useCallback((content: TenantManagement) => {
    setActiveContent(content);
    setIsModalOpen(true);
  }, []);

  const value = useMemo(
    () => ({
      isModalOpen,
      activeContent,
      closeModal,
      updateActiveContent,
      openModalWithContent,
    }),
    [isModalOpen, activeContent, closeModal, updateActiveContent, openModalWithContent]
  );

  return (
    <TenantManagementCtx.Provider value={value}>{children}</TenantManagementCtx.Provider>
  );
};
