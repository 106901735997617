import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import populateSchema from "@/features/overviewTable/util/cleanCodeHelper";
import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";

import OverviewTableSchema from "../models/overviewTableSchema.json";

import OverviewTableRow from "./OverviewTableRow";

const tableStyles = {
  container: {
    width: "60%",
    th: {
      color: "#fff",
      background: "#003057",
      padding: "15px !important",
      textAlign: "center",
      fontSize: "1.10rem",
      border: "none",
    },
  },
};

export default function OverviewTable({ wbsEntry }: { wbsEntry: WBSEntryModel }) {
  const wbsEntries = populateSchema(OverviewTableSchema, wbsEntry);

  return (
    <TableContainer component={Box} sx={tableStyles.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Cost</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Emissions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {wbsEntries.map((row) => (
            <OverviewTableRow key={row.id} wbsEntry={wbsEntry} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
