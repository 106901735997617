import { Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";

import { TenantManagement } from "@/features/tenantManagement";
import { Navbar } from "@/layouts/navigation";
// import AccountManagement from "@/features/accountManagement/components/AccountManagement";

// @cmp This layout wraps many things, I am creating it because we want
// @cmp to follow DRY (Don't Repeat Yourself) principle, so we don't have to
// @cmp include the navbar in every page, we just wrap all pages that need with this
export default function MainNavigationLayout() {
  return (
    <>
      <Navbar />
      <TenantManagement />
      <Toolbar />
      {/* <AccountManagement /> */}
      <Box
        sx={{
          px: 3,
        }}>
        <Outlet />
      </Box>
    </>
  );
}
