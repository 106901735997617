import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function StrategyDefinitionLoading() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        p: 5,
        pb: 25,
        pr: 10,
      }}>
      <PropagateLoader
        color="#003057"
        size={15}
        speedMultiplier={0.55}
        cssOverride={{ marginBottom: "30px" }}
      />
      <Typography
        variant="h3"
        sx={{ mt: 3, fontWeight: "bold", color: "var(--primary-color)" }}>
        We are retrieving your project data
      </Typography>
      <Typography variant="h5" sx={{ mt: 3, color: "var(--ecerto-cool-grey)" }}>
        Thank you for your patience
      </Typography>
    </Box>
  );
}
