import { Box } from "@mui/material";
import { MRT_RowSelectionState, MRT_TableInstance } from "material-react-table";
import { useState, useEffect } from "react";

import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";
import findId from "@/shared/utils/getId";

import AddProjectScope from "./AddProjectScope";
import DeleteProjectScope from "./DeleteProjectScope";
import EditProjectScope from "./EditProjectScope";

export default function ProjectScopeToolbar({
  rowSelection: initialRowSelection,
  tableInstanceRef,
  data,
}: {
  rowSelection: MRT_RowSelectionState;
  tableInstanceRef: MRT_TableInstance<WBSEntryViewModel>;
  data: WBSEntryViewModel | undefined;
}) {
  const [selectedRowIdsSet, setSelectedRowIdsSet] = useState<string[]>([]);

  useEffect(() => {
    const newSelectedIds = Object.keys(initialRowSelection).filter(
      (row_id) => initialRowSelection[row_id]
    );
    setSelectedRowIdsSet(newSelectedIds);
  }, [initialRowSelection]);

  const clearRowSelection = () => {
    setSelectedRowIdsSet([]);
    tableInstanceRef.resetRowSelection();
  };
  const row =
    !data || selectedRowIdsSet.length > 1
      ? null
      : findId(selectedRowIdsSet[0], data.children);

  if (selectedRowIdsSet.length > 0)
    return (
      <Box sx={{ marginLeft: "auto", display: "flex", marginBottom: "1rem" }}>
        {row && (
          <>
            {row.level < 3 && (
              <AddProjectScope clearRowSelection={clearRowSelection} row={row} />
            )}
            <EditProjectScope clearRowSelection={clearRowSelection} row={row} />
          </>
        )}
        <DeleteProjectScope
          clearRowSelection={clearRowSelection}
          rowSelection={selectedRowIdsSet}
        />
      </Box>
    );

  return (
    <Box sx={{ marginLeft: "auto", display: "flex", marginBottom: "1rem" }}>
      <AddProjectScope clearRowSelection={clearRowSelection} row={data} />
    </Box>
  );
}
