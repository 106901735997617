// @todo Remove this function when the API is updated to return the correct model
// The function below is used to format the legacy API model to the new model
// Is also divided between det, prob, scenario version which needs to be handled in the api layer.
import {
  PostLegacyAPIDataModel,
  LegacyAPIDataModel,
} from "@/features/parametric/model/legacyParameterModel";
import { ParameterModels } from "@/shared/types/parameterModels";

export default function formatLegacyParametricModel(items: LegacyAPIDataModel[]) {
  return items.map((item) => {
    // Because we are changing performance for duration but the name performance is coming from the back-end
    // We Check if item.parameter is "performance" and change it to "duration"
    const parameterDeterModel = {
      wbsEntryId: item.deliverableId,
      id: item.id,
      name: item.parameter,
      unit: item.unit,
      quantity: item.quantity,
      order: item.order,
    };
    return parameterDeterModel;

    // const ParameterProbabModel = {
    //   ...parameterDeterModel,
    //   min: item.min,
    //   max: item.max,
    //   distributionType: item.distributionType,
    //   visualizedPercentile: item.SelectedPercentile,
    //   probabGenericBaselineDistrib: item.p50Quantity,
    // };

    // if (modelVersion === "deterministic") {
    //   return parameterDeterModel;
    // }
    // if (modelVersion === "probabilisticModel") {
    //   return ParameterProbabModel;
    // }
    // if (modelVersion === "scenariosModel") {
    //   return {
    //     ...ParameterProbabModel,
    //     selectedBaselinePercentile: item.DeterministicBaselinePercentile,
    //     probabBaselineDistrib: item.P50QuantityStep5,
    //   };
    // }
    // throw new Error(`Invalid integratiVersion: ${modelVersion}`);
  });
}
export const formatSingleLegacyParametricModel = (item: LegacyAPIDataModel) => {
  // @todo we need to change performance to duration in the back-end through migrations.
  // Check if item.parameter is "performance" and change it to "duration"
  const parameterDeterModel = {
    wbsEntryId: item.deliverableId,
    id: item.id,
    name: item.parameter,
    unit: item.unit,
    quantity: item.quantity,
    order: item.order,
  };
  return parameterDeterModel;
};

// create a function that does the opposite of the one above
// this is used to format the model to the legacy model
export const formatToLegacyParameter = (
  item: ParameterModels
): PostLegacyAPIDataModel => {
  const parameterDeterModel = {
    deliverableId: item.wbsEntryId,
    id: item.id,
    parameter: item.name,
    unit: item.unit,
    quantity: item.quantity,
    min: 0,
    max: 0,
    distribution: 0,
    dynamicCost: 0,
    deterministicBaselinePercentile: "",
    fixedCost: 0,
    isSelected: false,
    selectedStep3DeterministicBaselineQuantity: 0,
    selectedStep5ProbabilisticBaselineQuantity: 0,
  };
  return parameterDeterModel;
};
