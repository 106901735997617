import Box from "@mui/material/Box";

import DeleteRiskButton from "./DeleteRiskButton";

type RiskActionsProps = {
  riskId: string;
  onRiskDeleted: () => void;
};

export default function RiskActions({ riskId, onRiskDeleted }: RiskActionsProps) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <DeleteRiskButton rowSelection={[riskId]} onRiskDeleted={onRiskDeleted} />
    </Box>
  );
}
