import {
  GetRiskLegacyBaseModel,
  PostRiskLegacyBaseModel,
} from "@/features/risk/models/riskLegacyModel";
import { RiskBaseModel } from "@/features/risk/models/riskModels";

export const legacyRiskModelToNewModel = (items: GetRiskLegacyBaseModel[]) => {
  return items.map((item) => {
    const riskBaseModel: RiskBaseModel = {
      wbsEntryId: item.deliverableId,
      id: item.id,
      name: item.name,
      probabOfOccurrence: item.residualProbability,
      modeDuration: item.mostLikelyPerformance,
      modeRemedialCost: item.mostLikelyRemedialCost,
      impactDuration: item.deterministicPerformance,
      // @todo: The calculation below should be done in the back-end
      impactCost: item.fixedDeterministicPerformanceCost + item.deterministicRemedialCost,
    };
    return riskBaseModel;
  });
};

export const legacyRiskItemToNewItem = (item: GetRiskLegacyBaseModel): RiskBaseModel => {
  const riskBaseModel: RiskBaseModel = {
    wbsEntryId: item.deliverableId,
    id: item.id,
    name: item.name,
    probabOfOccurrence: item.residualProbability,
    modeDuration: item.mostLikelyPerformance,
    modeRemedialCost: item.mostLikelyRemedialCost,
    impactDuration: item.deterministicPerformance,
    // @todo: The calculation below should be done in the back-end
    impactCost: item.fixedDeterministicPerformanceCost + item.deterministicRemedialCost,
  };
  return riskBaseModel;
};
export default function newRiskModelToLegacyModel(
  risk: RiskBaseModel
): PostRiskLegacyBaseModel {
  const riskLegacyBaseModel: PostRiskLegacyBaseModel = {
    deliverableId: risk.wbsEntryId,
    id: risk.id,
    name: risk.name,
    residualProbability: risk.probabOfOccurrence,
    mostLikelyPerformance: risk.modeDuration,
    mostLikelyRemedialCost: risk.modeRemedialCost,
  };
  return riskLegacyBaseModel;
}
