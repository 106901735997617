import { useContext } from "react";

import { ConceptContext, ConceptContextType } from "./ConceptContext";

export default function useConceptContext(): ConceptContextType {
  const context = useContext(ConceptContext);

  if (!context) {
    throw new Error("useConceptContext must be used within a ConceptContextProvider");
  }
  return context;
}
