import { Theme } from "@mui/material";
import { CSSProperties } from "react";

// Types for BaselineOverview
type BaselineOverviewStyles = {
  containerBaselineOverviewStyle: CSSProperties;
  divBaselineOverviewStyle: CSSProperties;
  titleBaselineOverviewStyle: CSSProperties;
  titleLineStyle: CSSProperties;
  bottomLineStyle: CSSProperties;
};

// Types for OverviewItem
type OverviewItemStyles = {
  gridOverviewItemStyle: CSSProperties;
  typographyTitleStyle: CSSProperties;
  typographyAmountStyle: CSSProperties;
};

const commonStyles = (theme: Theme) => ({
  lines: (margin: string) => ({
    content: "",
    height: theme.spacing(0.1),
    flex: 1,
    backgroundColor: "var(--ecerto-cool-grey-light)",
    margin,
  }),
});

// Styles for BaselineOverview
export const getBaselineOverviewStyles = (theme: Theme): BaselineOverviewStyles => {
  const { lines } = commonStyles(theme);

  return {
    containerBaselineOverviewStyle: {
      flexDirection: "column",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      marginBottom: ".5rem",
      marginTop: "1.5rem",
      display: "flex",
      maxWidth: "100%",
      padding: "0rem 3rem",
    },

    divBaselineOverviewStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      marginTop: "1rem",
      marginBottom: "1rem",
    },
    titleBaselineOverviewStyle: {
      padding: ".25rem",
      margin: "0.25rem",
      paddingLeft: "0.75rem",
      letterSpacing: "0.25rem",
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "var(--primary-color)",
    },
    titleLineStyle: lines(`${"0rem"} ${"0.5rem"}`),
    bottomLineStyle: lines(`${"2rem"} ${"0rem"} ${"2.5rem"} ${"0.25rem"}`),
  };
};

// Styles for OverviewItem
export const getOverviewItemStyles = (theme: Theme): OverviewItemStyles => {
  return {
    gridOverviewItemStyle: {
      padding: "1.5rem",
      flexDirection: "column",
    },
    typographyTitleStyle: {
      color: theme.palette.text.primary,
      textAlign: "right",
      fontSize: "1.5rem",
      whiteSpace: "nowrap",
      fontWeight: "bold",
    },
    typographyAmountStyle: {
      textAlign: "right",
      fontSize: "1.4rem",
      whiteSpace: "nowrap",
      fontWeight: "bold",
    },
  };
};
