import { Chip, FormControl, MenuItem, Select, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useProjectContext from "@/shared/context/conceptContext/useProjectContext";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import ProjectScenarios from "@/shared/enums/scenarios";

import BreakdownStructuresButton from "./BreakdownStructuresButton";
import DeliverableTree from "./DeliverableTree";
import SidebarSkeleton from "./SidebarSkeleton";
import SidebarSummaryTable from "./SidebarSummaryTable";

export default function Sidebar({
  drawerWidth,
  setDrawerWidth,
  isLoading,
  setIsHovering,
  setIsBreakdownStructuresButtonOpen,
  isBreakdownStructuresButtonOpen,
}: {
  drawerWidth: number;
  setDrawerWidth: (width: number) => void;
  isLoading: boolean;
  setIsHovering: (hovering: boolean) => void;
  setIsBreakdownStructuresButtonOpen: (isOpen: boolean) => void;
  isBreakdownStructuresButtonOpen: boolean;
}) {
  const [initialLoad, setInitialLoad] = useState(true);
  const MIN_SIDEBAR_WIDTH = 30;
  const { projectWBS, getWBSEntryById } = useProjectWBSContext();

  const WBSEntry = getWBSEntryById();
  const { updateSelectedWbsEntryId } = useProjectContext();

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  // substitute the entryId from the path params with the selectedWbsEntryId if it exists, otherwise, just update the context

  const [scenario, setScenario] = useState<string>(ProjectScenarios[0]);
  const [open] = useState(true);
  // const [isResizing, setIsResizing] = useState(false);

  // Resizing appropriated from: https://codesandbox.io/s/react-resizable-sidebar-kz9de?file=/src/App.js
  const sidebarRef = useRef<HTMLDivElement>(null);

  // const startResizing = useCallback(() => {
  //   setIsResizing(true);
  //   // Add a global class to disable user select
  //   document.body.classList.add("no-select");
  // }, []);

  // const stopResizing = useCallback(() => {
  //   setIsResizing(false);
  //   // Remove the global class to re-enable user select
  //   document.body.classList.remove("no-select");
  // }, []);

  // const resize = useCallback(
  //   (mouseMoveEvent: MouseEvent) => {
  //     if (!sidebarRef.current) return;
  //     if (sidebarRef.current.contains(mouseMoveEvent.target as Node)) {
  //       mouseMoveEvent.preventDefault();
  //     }
  //     if (isResizing) {
  //       const newWidth =
  //         mouseMoveEvent.clientX - sidebarRef.current.getBoundingClientRect().left;
  //       if (newWidth > 280 && newWidth < 700) setDrawerWidth(newWidth);
  //     }
  //   },
  //   [isResizing, setDrawerWidth]
  // );

  useEffect(() => {
    if (initialLoad) {
      const savedWidth = localStorage.getItem("sidebarWidth");
      if (savedWidth) {
        setDrawerWidth(Math.max(Number(savedWidth), MIN_SIDEBAR_WIDTH));
      } else {
        setDrawerWidth(MIN_SIDEBAR_WIDTH);
      }
      setInitialLoad(false);
    } else {
      localStorage.setItem(
        "sidebarWidth",
        String(Math.max(drawerWidth, MIN_SIDEBAR_WIDTH))
      );
    }
  }, [drawerWidth, initialLoad, setDrawerWidth]);

  // useEffect(() => {
  //   window.addEventListener("mousemove", resize);
  //   window.addEventListener("mouseup", stopResizing);
  //   return () => {
  //     window.removeEventListener("mousemove", resize);
  //     window.removeEventListener("mouseup", stopResizing);
  //   };
  // }, [resize, stopResizing]);

  const handleUpdateAndNavigate = () => {
    updateSelectedWbsEntryId(projectWBS?.conceptWBS.id ?? "");

    // Define the paths that require navigation
    // If the current path is one of these, we will navigate to a new URL
    const pathsRequiringNavigation = ["project-baseline", "project-scenarios"];

    // Determine if the current path is one of the paths requiring navigation
    const currentPathRequiringNavigation = pathsRequiringNavigation.find((path) =>
      location.pathname.includes(path)
    );

    // If the current path does not include "project-baseline" or "project-scenarios",
    // we only update the state and do not navigate.
    if (!currentPathRequiringNavigation) return;

    // Extract the base URL from the current pathname
    const basePath = location.pathname.split("/").slice(0, 3).join("/");

    // Construct the new path by appending the current path requiring navigation and the concept WBS ID to the base URL
    // This is the URL we will navigate to
    const newPath = `${basePath}/${currentPathRequiringNavigation}/${projectWBS?.conceptWBS.id}`;

    // Navigate to the new URL
    navigate(newPath);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        PaperProps={{
          style: {
            padding: "0 1rem",
            width: `${drawerWidth}rem`,
            backgroundColor: "var(--primary-color)",
            color: "white",
          },
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => {
          if (!isBreakdownStructuresButtonOpen) {
            setIsHovering(false);
          }
        }}
        variant="permanent"
        open={open}
        ref={sidebarRef}
        anchor="left">
        <Toolbar />

        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SidebarSkeleton />
          </Box>
        ) : (
          <Box marginTop="1rem" gap="1rem" display="flex" flexDirection="column">
            <BreakdownStructuresButton
              setIsBreakdownStructuresButtonOpen={setIsBreakdownStructuresButtonOpen}
              setIsHovering={setIsHovering}
            />

            {/* Removed Suspense and Await components for direct component rendering */}
            <Typography
              onClick={handleUpdateAndNavigate}
              className="custom-link"
              variant="h5">
              {WBSEntry?.name}
            </Typography>
            <FormControl fullWidth size="small">
              <Select
                labelId="selected-scenario-select"
                id="selected-scenario"
                value={scenario}
                style={{ backgroundColor: "white", color: "black" }}
                onChange={(e) => setScenario(e.target.value)}>
                {Object.values(ProjectScenarios)
                  .filter((value) => typeof value === "string")
                  .map((value, index) => {
                    let chipLabel = "";
                    if (index < 1) {
                      chipLabel = "LITE";
                    } else if (index >= 1 && index <= 2) {
                      chipLabel = "PRO";
                    } else if (index >= 3 && index <= 6) {
                      chipLabel = "ENTERPRISE";
                    }

                    return (
                      <MenuItem
                        key={value}
                        value={value}
                        disabled={value !== ProjectScenarios[0]}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        {value}
                        {chipLabel &&
                          (chipLabel === "LITE" ? (
                            <Chip
                              label="LITE"
                              size="small"
                              sx={{
                                backgroundColor: "var(--ecerto-pantone-663)",
                                borderRadius: "0.1875rem",
                                color: "var(--primary-color)",
                                height: "1.25rem",
                                fontSize: "0.6rem",
                                marginLeft: "0.2rem",
                                fontWeight: "bold",
                              }}
                            />
                          ) : (
                            <Chip
                              label={chipLabel}
                              size="small"
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                borderRadius: "0.1875rem",
                                height: "1.25rem",
                                fontSize: "0.6rem",
                                marginLeft: "0.2rem",
                                fontWeight: "bold",
                              }}
                            />
                          ))}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <SidebarSummaryTable />

            <DeliverableTree />
          </Box>
        )}
        <Box
          // onMouseDown={startResizing}
          style={{
            width: "0.5rem",
            cursor: "ew-resize",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            // zIndex: 2,
            backgroundColor: "var(--primary-color)",
          }}
        />
      </Drawer>
    </Box>
  );
}
