import Box from "@mui/material/Box";

import DimensionsMenu from "@/features/projectSchedule/components/displayOptions/DimensionsMenu";
import TimeFrame from "@/features/projectSchedule/components/displayOptions/TimeFrame";
import ViewTimeLineButton from "@/features/projectSchedule/components/displayOptions/ViewTimeLineButton";
import { DisplayOptionsStyle } from "@/features/projectSchedule/styles/styles";
import { DisplayOptionsTypes } from "@/features/projectSchedule/types";

export default function DisplayOptions({
  displayOptions,
  onDisplayOptionsChange,
}: DisplayOptionsTypes) {
  return (
    <Box sx={DisplayOptionsStyle}>
      <TimeFrame view={displayOptions.view} onTimeFrameChange={onDisplayOptionsChange} />
      <ViewTimeLineButton
        isTimeLineView={displayOptions.isTimeLineView}
        onViewTimeLineChange={onDisplayOptionsChange}
      />
      <DimensionsMenu
        GanttBoardHeight={displayOptions.sliderHeight}
        ganttColumnWidth={displayOptions.sliderWidth}
        onDisplayOptionsChange={onDisplayOptionsChange}
      />
    </Box>
  );
}
