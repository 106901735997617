export const avatarStyles = {
  avatar: {
    bgcolor: "var(--ecerto-breakdown-brown)",
    width: "4.5rem",
    height: "4.5rem",
    boxShadow: "0 0.2rem 0.4rem rgba(0, 0, 0, 0.15)",
  },
  avatarText: {
    fontSize: "2rem",
  },
};
