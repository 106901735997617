import Modal from "@mui/material/Modal";

/* eslint-disable import/no-restricted-paths -- Allow importing UserManagementLayout in this file */
import Profile from "@/features/tenantManagement/components/Profile";
import UserManagement from "@/features/tenantManagement/components/userManagement/UserManagement";
import TenantManagementLayout from "@/layouts/TenantManagementLayout";
import { useTenantManagement } from "@/shared/context/tenantManagementCtx/useManagementTenant";
import TenantManagementView from "@/shared/enums/tenantManagement";
import { getUserInfo } from "@/shared/store/userInfo";

export default function TenantManagement() {
  const userInfo = getUserInfo();
  const { activeContent, isModalOpen, closeModal } = useTenantManagement();

  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <TenantManagementLayout user={userInfo}>
        {activeContent === TenantManagementView.Profile && (
          <Profile userInfo={userInfo} />
        )}
        {activeContent === TenantManagementView.ManageUsers && (
          <UserManagement userInfo={userInfo} />
        )}
      </TenantManagementLayout>
    </Modal>
  );
}
