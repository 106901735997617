import { Box, Chip, Tabs, useTheme } from "@mui/material";
import { useState } from "react";

import Transactional from "@/features/transactional";
import CustomTab from "@/shared/components/CustomTab";
import CustomTabPanel from "@/shared/components/CustomTabPanel";

export default function ProcurementScenarios() {
  const theme = useTheme();

  const [isActiveTab, setActiveTab] = useState("transactional");

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: "90%", margin: "auto" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={isActiveTab} onChange={handleChange} aria-label="tabs">
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Transactional
                <Chip
                  label="LITE"
                  size="small"
                  sx={{
                    backgroundColor: "var(--ecerto-pantone-663)",
                    borderRadius: "3px",
                    color: "var(--primary-color)",
                    height: "20px",
                    fontSize: "0.6rem",
                    marginLeft: "0.2rem",
                    fontWeight: "bold",
                  }}
                />
              </Box>
            }
            value="transactional"
            setActiveTab={setActiveTab}
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Bundled
                <Chip
                  label="Pro"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    borderRadius: "3px",
                    marginLeft: "0.2rem",
                    fontSize: "0.6rem",
                    height: "20px",
                    width: "47px",
                  }}
                />
              </Box>
            }
            value="bundled"
            setActiveTab={setActiveTab}
            disabled
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Integrated
                <Chip
                  label="Pro"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    borderRadius: "3px",
                    marginLeft: "0.2rem",
                    fontSize: "0.6rem",
                    height: "20px",
                    width: "47px",
                  }}
                />
              </Box>
            }
            value="integrated"
            setActiveTab={setActiveTab}
            disabled
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Cost
                <Chip
                  label="Enterprise"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    borderRadius: "3px",
                    marginLeft: "0.2rem",
                    fontSize: "0.6rem",
                    height: "20px",
                    width: "80px",
                  }}
                />
              </Box>
            }
            value="cost"
            setActiveTab={setActiveTab}
            disabled
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Schedule
                <Chip
                  label="Enterprise"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    borderRadius: "3px",
                    marginLeft: "0.2rem",
                    fontSize: "0.6rem",
                    height: "20px",
                    width: "80px",
                  }}
                />
              </Box>
            }
            value="schedule"
            setActiveTab={setActiveTab}
            disabled
          />
          <CustomTab<string>
            label={
              <Box display="flex" alignItems="center">
                Emissions
                <Chip
                  label="Enterprise"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    borderRadius: "3px",
                    marginLeft: "0.2rem",
                    fontSize: "0.6rem",
                    height: "20px",
                    width: "80px",
                  }}
                />
              </Box>
            }
            value="emissions"
            setActiveTab={setActiveTab}
            disabled
          />
        </Tabs>
      </Box>

      <CustomTabPanel value={isActiveTab} name="transactional">
        <Transactional />
      </CustomTabPanel>
      <CustomTabPanel value={isActiveTab} name="bundled">
        Scenario B
      </CustomTabPanel>
      <CustomTabPanel value={isActiveTab} name="integrated">
        Scenario C
      </CustomTabPanel>
      <CustomTabPanel value={isActiveTab} name="cost">
        Scenario D
      </CustomTabPanel>
      <CustomTabPanel value={isActiveTab} name="schedule">
        Scenario E
      </CustomTabPanel>
      <CustomTabPanel value={isActiveTab} name="emissions">
        Scenario F
      </CustomTabPanel>
    </Box>
  );
}
