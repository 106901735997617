export enum Units {
  Unit = 0,
  Metre = 1,
  Centimetre = 2,
  Kilogram = 3,
  Gram = 4,
  Barrel = 5,
  CubicFeet = 6,
  CubicMetre = 7,
  Day = 8,
  Feet = 9,
  Gallon = 10,
  Hour = 11,
  Inch = 12,
  Item = 13,
  Joint = 14,
  Kilometre = 15,
  Litre = 16,
  Load = 17,
  Mile = 18,
  Minute = 19,
  Month = 20,
  Pound = 21,
  Run = 22,
  Sack = 23,
  Service = 24,
  Set = 25,
  SquareFeet = 26,
  SquareMetre = 27,
  SquareMile = 28,
  Tonne = 29,
  Trip = 30,
  Week = 31,
  Year = 32,
  CO2e = 33,
  tCO2e = 35,
  ktCO2e = 34,
  MtCO2e = 36,
  GtCO2e = 37,
  Anchor = 38,
  Cable = 39,
  Certificate = 40,
  SquareCentimeter = 41,
  Floater = 42,
  Foundation = 43,
  FullTimeEquivalent = 44,
  Generator = 45,
  Gearbox = 46,
  Gigawatt = 47,
  GigawattHour = 48,
  Good = 49,
  Installation = 50,
  SquareKilometer = 51,
  Kilowatt = 52,
  KilowattHour = 53,
  Megawatt = 54,
  MegawattHour = 55,
  Module = 56,
  Mooring = 57,
  Nacelle = 58,
  Office = 59,
  Panel = 60,
  Policy = 61,
  Product = 62,
  PVArea = 63,
  PVGroup = 64,
  PVModule = 65,
  Road = 66,
  Supply = 67,
  Tower = 68,
  Transformer = 69,
  Turbine = 70,
  Watt = 71,
  WattHour = 72,
  Well = 73,
}

export const DurationUnitsNames = new Map<number, string>([
  [Units.Day, "Day"],
  [Units.Hour, "Hour"],
  [Units.Minute, "Minute"],
  [Units.Month, "Month"],
  [Units.Week, "Week"],
  [Units.Year, "Year"],
]);

export const UnitsAbbreviations = new Map<number, string>([
  [Units.Unit, "unit"],
  [Units.Metre, "m"],
  [Units.Centimetre, "cm"],
  [Units.Kilogram, "kg"],
  [Units.Gram, "g"],
  [Units.Barrel, "bbl"],
  [Units.CubicFeet, "ft³"],
  [Units.CubicMetre, "cm³"],
  [Units.Day, "days"],
  [Units.Feet, "ft"],
  [Units.Gallon, "gal"],
  [Units.Hour, "h"],
  [Units.Inch, "in"],
  [Units.Item, "items"],
  [Units.Joint, "joints"],
  [Units.Kilometre, "km"],
  [Units.Litre, "L"],
  [Units.Load, "loads"],
  [Units.Mile, "mi"],
  [Units.Minute, "min"],
  [Units.Month, "month"],
  [Units.Pound, "lb"],
  [Units.Run, "runs"],
  [Units.Sack, "sk"],
  [Units.Service, "services"],
  [Units.Set, "sets"],
  [Units.SquareFeet, "ft²"],
  [Units.SquareMetre, "m²"],
  [Units.SquareMile, "mi²"],
  [Units.Tonne, "t"],
  [Units.Trip, "trips"],
  [Units.Week, "wk"],
  [Units.Year, "yr"],
  [Units.CO2e, "CO₂e"],
  [Units.tCO2e, "tCO₂e"],
  [Units.ktCO2e, "ktCO₂e"],
  [Units.MtCO2e, "MtCO₂e"],
  [Units.GtCO2e, "GtCO₂e"],
  [Units.Anchor, "anchors"],
  [Units.Cable, "cables"],
  [Units.Certificate, "certificates"],
  [Units.SquareCentimeter, "cm²"],
  [Units.Floater, "floaters"],
  [Units.Foundation, "foundations"],
  [Units.FullTimeEquivalent, "FTE"],
  [Units.Generator, "generators"],
  [Units.Gearbox, "gearboxes"],
  [Units.Gigawatt, "GW"],
  [Units.GigawattHour, "GWh"],
  [Units.Good, "goods"],
  [Units.Installation, "installations"],
  [Units.SquareKilometer, "km²"],
  [Units.Kilowatt, "kW"],
  [Units.KilowattHour, "kWh"],
  [Units.Megawatt, "MW"],
  [Units.MegawattHour, "MWh"],
  [Units.Module, "modules"],
  [Units.Mooring, "moorings"],
  [Units.Nacelle, "nacelles"],
  [Units.Office, "offices"],
  [Units.Panel, "panels"],
  [Units.Policy, "policies"],
  [Units.Product, "products"],
  [Units.PVArea, "PV areas"],
  [Units.PVGroup, "PV groups"],
  [Units.PVModule, "PV modules"],
  [Units.Road, "roads"],
  [Units.Supply, "supplies"],
  [Units.Tower, "towers"],
  [Units.Transformer, "transformers"],
  [Units.Turbine, "turbines"],
  [Units.Watt, "W"],
  [Units.WattHour, "Wh"],
  [Units.Well, "wells"],
]);

export const EmissionUnitsNames = new Map<number, string>([
  [Units.CO2e, "CO₂e"],
  [Units.tCO2e, "tCO₂e"],
  [Units.ktCO2e, "ktCO₂e"],
  [Units.MtCO2e, "MtCO₂e"],
  [Units.GtCO2e, "GtCO₂e"],
]);

export const OutcomeUnitsNames = new Map<number, string>([
  [Units.Anchor, "Anchor"],
  [Units.Barrel, "Barrel"],
  [Units.Cable, "Cable"],
  [Units.Certificate, "Certificate"],
  [Units.Centimetre, "Centimetre"],
  [Units.CubicFeet, "Cubic Feet"],
  [Units.CubicMetre, "Cubic Metre"],
  [Units.Feet, "Feet"],
  [Units.Floater, "Floater"],
  [Units.Foundation, "Foundation"],
  [Units.FullTimeEquivalent, "Full Time Equivalent"],
  [Units.Gallon, "Gallon (US)"],
  [Units.Gearbox, "Gearbox"],
  [Units.Generator, "Generator"],
  [Units.Gigawatt, "Gigawatt"],
  [Units.GigawattHour, "Gigawatt Hour"],
  [Units.Good, "Good"],
  [Units.Gram, "Gram"],
  [Units.Inch, "Inch"],
  [Units.Installation, "Installation"],
  [Units.Item, "Item"],
  [Units.Joint, "Joint"],
  [Units.Kilogram, "Kilogram"],
  [Units.Kilometre, "Kilometre"],
  [Units.Kilowatt, "Kilowatt"],
  [Units.KilowattHour, "Kilowatt Hour"],
  [Units.Litre, "Litre"],
  [Units.Load, "Load"],
  [Units.Megawatt, "Megawatt"],
  [Units.MegawattHour, "Megawatt Hour"],
  [Units.Metre, "Metre"],
  [Units.Mile, "Mile"],
  [Units.Module, "Module"],
  [Units.Mooring, "Mooring"],
  [Units.Nacelle, "Nacelle"],
  [Units.Office, "Office"],
  [Units.Panel, "Panel"],
  [Units.Policy, "Policy"],
  [Units.Pound, "Pound"],
  [Units.Product, "Product"],
  [Units.PVArea, "PV Area"],
  [Units.PVGroup, "PV Group"],
  [Units.PVModule, "PV Module"],
  [Units.Road, "Road"],
  [Units.Run, "Run"],
  [Units.Sack, "Sack"],
  [Units.Service, "Service"],
  [Units.Set, "Set"],
  [Units.SquareCentimeter, "Square Centimeter"],
  [Units.SquareFeet, "Square Feet"],
  [Units.SquareKilometer, "Square Kilometer"],
  [Units.SquareMetre, "Square Metre"],
  [Units.SquareMile, "Square Mile"],
  [Units.Supply, "Supply"],
  [Units.Tonne, "Tonne (Metric)"],
  [Units.Tower, "Tower"],
  [Units.Transformer, "Transformer"],
  [Units.Trip, "Trip"],
  [Units.Turbine, "Turbine"],
  [Units.Unit, "Unit"],
  [Units.Watt, "Watt"],
  [Units.WattHour, "Watt Hour"],
  [Units.Well, "Well"],
]);
