import pluralize from "pluralize";

import { WBSEntryModel } from "@/shared/context/projectWBS/projectWBSModel";
import { UnitsAbbreviations } from "@/shared/enums/units";
import { ParameterModels } from "@/shared/types/parameterModels";
import {
  formatCurrencyNoDecimals,
  formatDays,
  formatEmissions,
  formatPerDays,
} from "@/shared/utils";

import { OverviewItemsModel } from "../model/baselineOverviewModels";

const calculateAmount = (
  parametricOutcome: ParameterModels | undefined,
  entry: WBSEntryModel
): string => {
  if (parametricOutcome && entry && entry.estimateForCost !== 0) {
    const unitAbbreviation = UnitsAbbreviations.get(parametricOutcome.unit) ?? "";
    const costPerUnit = entry.estimateForCost / parametricOutcome.quantity;
    const formattedCost = formatCurrencyNoDecimals(costPerUnit);

    // Singularize the unit abbreviation if necessary
    const singularUnit = pluralize.singular(unitAbbreviation);

    return `${formattedCost} / ${singularUnit}`;
  }
  return ""; // return an empty string if the condition is not met
};

export default function updateBaselineOverviewSchema(
  overviewItems: OverviewItemsModel[],
  entry: WBSEntryModel | null,
  parametricOutcome: ParameterModels | undefined
): OverviewItemsModel[] {
  if (entry === null) {
    return overviewItems;
  }

  const filterOverviewItems = overviewItems;

  return filterOverviewItems.map((item) => {
    switch (item.title) {
      case "Cost / Unit":
        return {
          ...item,
          amount:
            parametricOutcome?.quantity === 0
              ? " "
              : calculateAmount(parametricOutcome, entry).replace(/s$/, ""), // s is removed as this is cost per 1 unit.
        };
      case "Standby Cost / Day":
        return {
          ...item,
          amount: entry.standbyCost === 0 ? " " : formatPerDays(entry.standbyCost),
        };
      case "Cost":
        return {
          ...item,
          amount:
            entry.estimateForCost === 0
              ? " "
              : formatCurrencyNoDecimals(entry.estimateForCost),
        };
      case "Duration":
        return {
          ...item,
          amount:
            entry.estimateForDuration === 0 ? " " : formatDays(entry.estimateForDuration),
        };
      case "Emissions":
        return {
          ...item,
          amount:
            entry.estimateForEmissions === 0
              ? " "
              : formatEmissions(entry.estimateForEmissions),
        };
      case "Impact: Cost":
        return {
          ...item,
          amount:
            entry.contingencyForCost === 0
              ? " "
              : formatCurrencyNoDecimals(entry.contingencyForCost),
        };
      case "Impact: Duration":
        return {
          ...item,
          amount:
            entry.contingencyForDuration === 0
              ? " "
              : formatDays(entry.contingencyForDuration),
        };
      case "Impact: Emissions":
        return {
          ...item,
          amount:
            entry.contingencyForEmission === 0
              ? " "
              : formatEmissions(entry.contingencyForEmission),
        };
      default:
        return item;
    }
  });
}
