import { createTheme } from "@mui/material";

const LightTheme = () =>
  createTheme({
    palette: {
      mode: "light",

      // @important You cannot use :root vars here
      // e.g. no var(--primary-color)

      // You use this by setting color="primary" in the component
      primary: {
        // when you select (check) a row the background color is set to this color
        main: "#003057",
        contrastText: "#fff",

        // Can add variants and access them like this: theme.palette.primary.light
        // or color="primary.light" in the component
        // dark: "#ff0000",
        light: "#fff",
        dark: "#85b09a",
      },

      // You use this by setting color="secondary" in the component
      secondary: {
        main: "#fff",
        contrastText: "#003057",
        light: "#f4f4f4",
      },

      error: {
        main: "#c66e4e",
        contrastText: "#fff",
      },

      levelColors: {
        main: "#e0e0e0",
        dark: "#d6d6d6",
        light: "#eaeaea",
        contrastText: "#fff",
      },

      tabsColors: {
        main: "#eaeaea",
      },

      // You use this by setting color="text.primary" in the component
      text: {
        primary: "#003057",
        secondary: "#3d3935",
      },

      background: {
        // if we change this here it will overwrite the default theme
        // better not to overwrite here
        default: "#f9f9f9",
      },
      forms: {
        // main: "#EEF1F4",
        main: "green",
        light: "#EEF1F4",
        dark: "#EEF1F4",
        contrastText: "#003057",
      },
    },

    breakpoints: {
      values: {
        xs: 1024, // Medium Tablets
        sm: 1366, // Big Tablets
        md: 1920, // HD screens
        lg: 2560, // 2K screens
        xl: 3840, // 4K screens
      },
    },

    components: {
      // MuiTableCell: {
      //   styleOverrides: {
      //     root: {
      //       "&:hover td": {
      //         color: "green",
      //       },
      //     },
      //   },
      // },

      // MuiTableRow: {
      //   styleOverrides: {
      //     root: {

      //     },
      //   },
      // },

      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&:focus, &:focus-visible": {
              outline: "0px",
            },
            "&[aria-label='Expand']": {
              marginLeft: "0", // set the margin left to 0
              padding: "0", // set the padding to 0
            },
            "&._35nLX": {
              transition: "width 0.5s ease-in-out",
            },
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              boxShadow: "0px 10px 30px -10px rgba(66, 68, 90, 1)",
              // color: "#003057",
              // backgroundColor: "#f4f4f4",
            },
          },
        },
      },

      // MuiTabs: {
      //   styleOverrides: {
      //     root: {
      //       "&.MuiTabs-indicator": {
      //         backgroundColor: "#f4f4f4",
      //       },
      //     },
      //   },
      // },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: ".8rem", // override to make tooltip font size larger
            backgroundColor: "#003057",
            maxWidth: "50rem",
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            // color: "grey",
            // "& .PrivateSwitchBase-input": {
            //   width: "auto",
            //   height: "auto",
            //   top: "auto",
            //   left: "auto",
            //   opacity: "1",
            //   visibility: "hidden", // optional
            //   "&::before": {
            //     content: '""',
            //     position: "absolute",
            //     background: "white",
            //     height: "100%",
            //     width: "100%",
            //     visibility: "visible", // optional
            //   },
            // },
          },
        },
      },

      // Causes checkbox not to be checked when clicked
      // because the z-index is set to 1 (higher than the checkbox)
      // MuiSvgIcon: {
      //   styleOverrides: {
      //     root: {
      //       zIndex: 1,
      //     },
      //   },
      // },

      MuiTableCell: {
        styleOverrides: {
          root: {
            "& [data-pinned='true']:: before": {
              backgroundColor: "transparent !important",
            },
          },
        },
      },

      // MuiSvgIcon: {
      //   styleOverrides: {
      //     root: {
      //       "&.MuiSelect-icon": {
      //         // Targeting the MuiSelect-icon class
      //         "& path": {
      //           // Targeting the path inside the SVG
      //           // color: "#fff", // Change the color of the path
      //         },
      //       },
      //     },
      //   },
      // },

      MuiTableRow: {
        styleOverrides: {
          root: {
            "& [data-pinned='true']:: before": {
              backgroundColor: "transparent !important",
            },

            "&.type-2.portfolio > .MuiTableCell-root[data-index='1']": {
              paddingLeft: "1.875rem", // 30px, adjust the padding as needed
            },
            "&.type-2.portfolio > .MuiTableCell-root[data-index='2']": {
              paddingLeft: "1.875rem", // 30px, adjust the padding as needed
            },
            "&.type-3.portfolio > .MuiTableCell-root[data-index='2']": {
              paddingLeft: "3.75rem", // 60px, adjust the padding as needed
            },
            "&.type-2.scoped-table > .MuiTableCell-root[data-index='2']": {
              paddingLeft: "0.9375rem", // 15px, adjust the padding as needed
            },
            // "&.type-3.scoped-table > .MuiTableCell-root[data-index='3']": {
            //   paddingLeft: "60px", // adjust the padding as needed
            // },
            "&.type-2.scoped-table > .MuiTableCell-root[data-index='3']": {
              paddingLeft: "0.9375rem", // 15px, adjust the padding as needed
            },
            "&.type-3.scoped-table > .MuiTableCell-root[data-index='3']": {
              paddingLeft: "1.5625rem", // 25px, adjust the padding as needed
            },
          },
        },
      },

      MuiTableHead: {
        styleOverrides: {
          root: {
            // The background color of the header below is to avoid pinned columns turning white.
            '[data-pinned="true"]:: before': {
              backgroundColor: "#003057 !important",
            },
            "& [data-index='0'] > .Mui-TableHeadCell-Content > .Mui-TableHeadCell-Content-Labels > .Mui-TableHeadCell-Content-Wrapper":
              {
                // display: "none",
                paddingTop: "0.125rem",
                // color: "green",
              },
            "& .MuiInputBase-input": {
              color: "white",
            },
            "& .Mui-TableHeadCell-Content-Wrapper": {
              fontSize: "0.875rem",
              color: "white",
              // @todo I want to apply a line height of 1.4 if the text wraps
              // @todo if we can do that we can remove the line below
              // @todo Kacper, can we detect the density state somehow?
              whiteSpace: "nowrap !important", // Break line in header
            },
            "& .css-929hxt-MuiInputBase-input-MuiInput-input": {
              color: "white",
            },
            "& .MuiFormHelperText-root": {
              color: "white",
            },
            "& .MuiCheckbox-root": {
              color: "white",
            },
            "& .MuiCheckbox-root.Mui-checked": {
              color: "white",
            },
            "& .MuiCheckbox-root.MuiCheckbox-indeterminate": {
              color: "white",
            },

            "& .MuiButtonBase-root": {
              color: "white",
            },
            "& .MuiTableCell-root.MuiTableCell-head": {
              paddingTop: "0.3125rem",
              paddingBottom: "0.3125rem",
              // @check this might be useful for Project Scope but it "breaks" Portfolio table's header checkbox
              // paddingLeft: "12px",
              // paddingRight: "8px",
              verticalAlign: "middle",
              lineHeight: "1.4rem",
            },
            // @important this modifies only the checkbox and expand arrows alignment
            "& .MuiTableCell-root": {
              // fontSize: "0.8rem",
              // justifyContent: "center",
              // textAlign: "center",
              // background:
              //   "linear-gradient(to right, rgb(0, 48, 87), rgb(0, 48, 87, 0.7) 500px)",
            },
            // "& .MuiTableRow-root": {
            //   background:
            //     "linear-gradient(to right, rgb(0, 48, 87), rgb(0, 48, 87, 0.7) 500px)",
            // },
            "& .MuiBox-root:hover .Mui-TableHeadCell-ResizeHandle-Wrapper .MuiDivider-vertical":
              {
                border: "0.125rem solid white !important",
              },
          },
        },
      },

      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: "grey",
            "&.Mui-active": {
              color: "#069DEB",
            },
          },
        },
      },

      MuiStepLabel: {
        styleOverrides: {
          root: {
            "& .MuiStepLabel-label": {
              color: "grey",
              "&.Mui-active": {
                color: "#069DEB",
              },
            },
          },
        },
      },

      MuiSlider: {
        styleOverrides: {
          root: {
            "&.MuiSlider-valueLabelLabel": {
              color: "red",
            },
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: "transparent",
            },
            borderRadius: 3,
            transition: "transform 0.5s ease",
            "&:hover": {
              transform: "scale(1.3)", // This makes the button 10% larger when hovered
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: 3,
            border: "none",
            padding: "7px",
            transition: "transform 0.5s ease",
            "&:hover": {
              transform: "scale(1.3)", // This makes the button 10% larger when hovered
            },
          },
        },
      },

      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            "&.Mui-active .MuiTableSortLabel-icon": {
              color: "white !important",
            },
          },
        },
      },
    },

    typography: {
      fontFamily: "Roboto, sans-serif",
      h1: {
        fontSize: "2rem",
        fontWeight: 500,
        // Add any other styles you want for h1
      },
      h2: {
        fontSize: "1.90rem",
        fontWeight: 400,
        // Add any other styles you want for h2
      },
      h3: {
        fontSize: "1.70rem",
        fontWeight: 400,
        // Add any other styles you want for h3
      },
      h4: {
        fontSize: "1.50rem",
        fontWeight: 400,
        // Add any other styles you want for h4
      },
      h5: {
        fontSize: "1.30rem",
        fontWeight: 400,
        // Add any other styles you want for h5
      },
      h6: {
        fontSize: "1.10rem",
        fontWeight: 400,
        // Add any other styles you want for h6
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 500,
        // Add any other styles you want for subtitle1
      },
      subtitle2: {
        fontSize: "0.875rem",
        fontWeight: 500,
        // Add any other styles you want for subtitle2
      },
      body1: {
        fontSize: "1rem",
        fontWeight: 400,
        // Add any other styles you want for body1
      },
      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        // Add any other styles you want for body2
      },
      button: {
        fontSize: "1.1rem",
        fontWeight: 500,
        letterSpacing: "0.05rem",
        // Add any other styles you want for button
      },
      caption: {
        fontSize: "0.65rem",
        fontWeight: 400,
        // Add any other styles you want for caption
      },
      overline: {
        fontSize: "0.75rem",
        fontWeight: 400,
        // Add any other styles you want for overline
      },
    },
  });

export default LightTheme;
