import { Tab, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function CustomTab<T>({
  value,
  label,
  to,
  setActiveTab,
  disabled = false,
}: {
  value: T;
  label: React.ReactNode;
  setActiveTab: (value: T) => void;
  to?: string;
  disabled?: boolean;
}) {
  const theme = useTheme();

  if (to)
    return (
      <Tab
        onClick={() => setActiveTab(value)}
        label={label}
        disableRipple
        disabled={disabled}
        style={{ color: theme.palette.primary.main }}
        id={`tab-${String(label)}`}
        aria-controls={`tabpanel-${String(label)}`}
        to={to}
        component={Link}
      />
    );
  return (
    <Tab
      onClick={() => setActiveTab(value)}
      label={label}
      disableRipple
      disabled={disabled}
      style={{ color: theme.palette.primary.main }}
      id={`tab-${String(label)}`}
      aria-controls={`tabpanel-${String(label)}`}
    />
  );
}
