import { RouteObject } from "react-router-dom";

import NotFound from "@/app/pages/404";
import Signin from "@/app/pages/auth/Signin";
import Signup from "@/app/pages/auth/signup/Signup";

/**
 * Returns an array of RouteObjects for the public routes.
 * @returns {RouteObject[]} An array of RouteObjects for the public routes.
 */
const PublicRoutes = (): RouteObject[] => {
  return [
    {
      path: "/",
      element: <Signin />,
      index: true,
    },
    {
      path: "login",
      element: <Signin />,
    },
    {
      path: "register",
      element: <Signup />,
    },
    // @important Fallback route (*) - Must be the last one
    {
      path: "*",
      element: <NotFound />,
    },
  ];
};

export default PublicRoutes;
