import { WBSEntryModel } from "@/shared/types/projectWBSModel";

import { OverviewTableModel } from "../models/overviewTableModel";

const populateSchema = (schema: OverviewTableModel[], entry: WBSEntryModel | null) => {
  if (entry === null) {
    return schema;
  }

  let updatedSchema = schema.map((item) => {
    // Cases that always happen
    switch (item.name) {
      case "Budget":
        return {
          ...item,
          cost: entry.budgetForCost ?? 0,
          duration: entry.budgetForDuration ?? 0,
          emissions: entry.budgetForEmission ?? 0,
        };
      case "Contingency":
        return {
          ...item,
          isEditable: entry.level === 3,
          cost: entry.contingencyForCost ?? 0,
          duration: entry.contingencyForDuration ?? 0,
          emissions: entry.contingencyForEmission ?? 0,
        };
      case "Baseline":
        return {
          ...item,
          cost: entry.baselineForCost ?? 0,
          duration: entry.baselineForDuration ?? 0,
          emissions: entry.baselineForEmission ?? 0,
        };
      case "Allowance":
        return {
          ...item,
          isEditable: entry.level === 3,
          cost: entry.allowanceForCost ?? 0,
          duration: entry.allowanceForDuration ?? 0,
          emissions: entry.allowanceForEmission ?? 0,
        };
      case "Estimate":
        return {
          ...item,
          cost: entry.estimateForCost ?? 0,
          duration: entry.estimateForDuration ?? 0,
          emissions: entry.estimateForEmissions ?? 0,
        };
      default:
        // Do nothing
        break;
    }

    // Cases that only happen when level is 0
    if (entry.level === 0) {
      switch (item.name) {
        case "Project":
          return {
            ...item,
            cost: entry.costForProject ?? 0,
            duration: entry.durationForProject ?? 0,
            emissions: entry.emissionForProject ?? 0,
          };
        case "Reserve":
          return {
            ...item,
            isEditable: true,
            cost: entry.reserveForCost ?? 0,
            duration: entry.reserveForDuration ?? 0,
            emissions: entry.reserveForEmission ?? 0,
          };
        default:
          // Do nothing
          break;
      }
    }

    return item;
  });

  if (entry.level !== 0) {
    updatedSchema = updatedSchema.filter(
      (item) => item.name !== "Project" && item.name !== "Reserve"
    );
  }

  return updatedSchema;
};
export default populateSchema;
