import { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

import { OverviewItemsModel } from "@/shared/components/baselineOverview/model/baselineOverviewModels";
import Footer from "@/shared/components/FooterForColumns";
import { formatCurrency } from "@/shared/utils";

import { CostModelForProjectBaseline } from "../models/costModelForProjectBaseline";

const ColumnsCostForProjectBaseline = ({
  OverviewItems,
}: {
  OverviewItems: OverviewItemsModel[];
}) => {
  return useMemo<MRT_ColumnDef<CostModelForProjectBaseline>[]>(
    () => [
      {
        accessorKey: "category",
        header: "Category",
        minSize: 90,
        size: 275,
        maxSize: 275,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        accessorFn: (row: CostModelForProjectBaseline) =>
          `${String(row.category)} ${String(row.subCategory)}`,

        Cell: ({ row }) => (
          <span>
            <div
              style={{
                fontWeight: "bold",
              }}>
              {row.original.category}
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "var(--ecerto-cool-grey-dark)",
              }}>{`${String(row.original.subCategory)}`}</div>
          </span>
        ),
      },
      {
        accessorKey: "name",
        header: "Resource Name",
        minSize: 90,
        size: 250,
        maxSize: 250,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        accessorFn: (row: CostModelForProjectBaseline) =>
          `${String(row.name)} ${String(row.supplier)}`,
        Cell: ({ row }) => (
          <span>
            <div
              style={{
                fontWeight: "bold",
              }}>
              {row.original.name as React.ReactNode}
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "var(--ecerto-cool-grey-dark)",
              }}>{`by ${String(row.original.supplier)}`}</div>
          </span>
        ),
      },
      {
        accessorKey: "unit",
        header: "Unit",
        minSize: 90,
        size: 100,
        maxSize: 150,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "left",
        },
        Cell: ({ row }) => <div>{row.original.unit}</div>,
      },
      {
        accessorKey: "fixedCost",
        header: "Fixed Price",
        minSize: 90,
        size: 150,
        maxSize: 150,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => <div>{formatCurrency(row.original.fixedCost as number)}</div>,
      },
      // {
      //   accessorKey: "dynamicCost",
      //   header: "Dynamic Price",
      //   minSize: 90,
      //   size: 150,
      //   maxSize: 150,
      //   enableEditing: false,
      //   muiTableBodyCellProps: {
      //     align: "right",
      //   },
      //   Cell: ({ cell }) => (
      //     <div
      //       style={{
      //         fontWeight: "inherit",
      //       }}>
      //       <span style={{ color: "#85b09a" }}>$ </span>
      //       {cell.getValue<number>().toFixed(2)}
      //     </div>
      //   ),
      // },
      {
        accessorKey: "lowerBound",
        header: "Min Quantity",
        minSize: 90,
        size: 100,
        maxSize: 150,
        muiTableBodyCellProps: {
          align: "right",
        },
        enableEditing: false,
        enableHiding: false,
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        minSize: 90,
        size: 100,
        maxSize: 150,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => <div>{row.original.quantity as number}</div>,
      },
      // {
      //   accessorKey: "upperBound",
      //   header: "Max Quantity",
      //   minSize: 90,
      //   size: 100,
      //   maxSize: 150,
      //   muiTableBodyCellProps: {
      //     align: "center",
      //   },
      //   enableEditing: false,
      //   enableHiding: false,
      // },
      // {
      //   accessorKey: "distribution",
      //   header: "Distribution",
      //   minSize: 90,
      //   size: 150,
      //   maxSize: 150,
      //   muiTableBodyCellProps: {
      //     align: "right",
      //   },
      //   enableHiding: false,
      //   enableEditing: false,
      //   editVariant: "select",
      //   // TODO: This currently doesn't work
      //   editSelectOptions: Object.values(Distributions).filter(
      //     (value) => typeof value === "string"
      //   ) as string[],
      // },
      {
        accessorKey: "deterministicFixedCost",
        header: "Cost",
        minSize: 90,
        size: 150,
        maxSize: 150,
        enableEditing: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        Cell: ({ row }) => (
          <div>{formatCurrency(row.original.deterministicFixedCost as number)}</div>
        ),
        Footer: () => <Footer id="deterministicFixedCost" items={OverviewItems} />,
      },
      // {
      //   accessorKey: "deterministicDynamicCost",
      //   header: "Deterministic Cost Dynamic Pricing",
      //   minSize: 90,
      //   size: 150,
      //   maxSize: 150,
      //   enableEditing: false,
      //   muiTableBodyCellProps: {
      //     align: "right",
      //   },
      //   Cell: ({ cell }) => (
      //     <div
      //       style={{
      //         fontWeight: "inherit",
      //       }}>
      //       <span style={{ color: "#85b09a" }}>$ </span>
      //       {cell.getValue<number>().toFixed(2)}
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "probabilisticDistributinon",
      //   header: "Probabilistic Distributinon",
      //   minSize: 90,
      //   size: 150,
      //   maxSize: 150,
      //   enableEditing: false,
      //   muiTableBodyCellProps: {
      //     align: "right",
      //   },
      //   Cell: ({ cell }) => (
      //     <div
      //       style={{
      //         fontWeight: "inherit",
      //       }}>
      //       {cell.getValue<number>().toFixed(2)}
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "probabilisticCost",
      //   header: "Probabilistic Distributinon Fixed Pricing",
      //   minSize: 90,
      //   size: 150,
      //   maxSize: 150,
      //   enableEditing: false,
      //   muiTableBodyCellProps: {
      //     align: "right",
      //   },
      //   Cell: ({ cell }) => (
      //     <div
      //       style={{
      //         fontWeight: "inherit",
      //       }}>
      //       <span style={{ color: "#85b09a" }}>$ </span>
      //       {cell.getValue<number>().toFixed(2)}
      //     </div>
      //   ),
      // },
      // {
      //   accessorKey: "probabilisticCostDynamicCost",
      //   header: "Probabilistic Distributinon Dynamic Pricing",
      //   minSize: 90,
      //   size: 150,
      //   maxSize: 150,
      //   enableEditing: false,
      //   muiTableBodyCellProps: {
      //     align: "right",
      //   },
      //   Cell: ({ cell }) => (
      //     <div
      //       style={{
      //         fontWeight: "inherit",
      //       }}>
      //       <span style={{ color: "#85b09a" }}>$ </span>
      //       {cell.getValue<number>().toFixed(2)}
      //     </div>
      //   ),
      // },
    ],
    [OverviewItems]
  );
};

export default ColumnsCostForProjectBaseline;
