import { AxiosError, AxiosResponse } from "axios";

import { PROJECTS_API_ENDPOINTS } from "@/shared/api/api";
import apiRequests from "@/shared/config/axiosConfig";

import { EditConceptModel } from "../models/editConceptModel";
type EditConceptResponse = {
  message: string;
  statusCode: number;
};

export default async function editConcept(project: EditConceptModel) {
  try {
    const url = PROJECTS_API_ENDPOINTS.GET_PROJECTS;
    const response: AxiosResponse<EditConceptResponse> = await apiRequests.put(
      url,
      project
    );
    const { data, status } = response;

    if (status >= 200 && status < 300) {
      return data;
    }
    throw new Error(`Unknown error occurred with status code ${status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}
