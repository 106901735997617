import { Skeleton, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";

import {
  getBaselineOverviewStyles,
  getOverviewItemStyles,
} from "./baselineOverviewStyles";

export default function BaselineOverviewSkeleton() {
  const theme = useTheme();
  const { gridOverviewItemStyle } = getOverviewItemStyles(theme);
  const {
    containerBaselineOverviewStyle,
    divBaselineOverviewStyle,
    titleLineStyle,
    bottomLineStyle,
  } = getBaselineOverviewStyles(theme);

  return (
    <div style={containerBaselineOverviewStyle}>
      <Grid container>
        <Grid item xs={12} mt={3}>
          <div style={divBaselineOverviewStyle}>
            <Skeleton variant="text" width={280} height={40} />
            <div style={titleLineStyle} />
          </div>
        </Grid>
        <Grid container spacing={2}>
          <Grid item style={gridOverviewItemStyle}>
            <Grid item>
              <Skeleton variant="text" width={180} height={30} />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={150} height={40} />
            </Grid>
          </Grid>
          <Grid item style={gridOverviewItemStyle}>
            <Grid item>
              <Skeleton variant="text" width={180} height={30} />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={150} height={40} />
            </Grid>
          </Grid>
          <Grid item style={gridOverviewItemStyle}>
            <Grid item>
              <Skeleton variant="text" width={180} height={30} />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={150} height={40} />
            </Grid>
          </Grid>
          <Grid item style={gridOverviewItemStyle}>
            <Grid item>
              <Skeleton variant="text" width={180} height={30} />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={150} height={40} />
            </Grid>
          </Grid>
        </Grid>
        <div style={bottomLineStyle} />
      </Grid>
    </div>
  );
}
