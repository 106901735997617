import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Dialog, Toolbar, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useContext, useState } from "react";

import BreakdownStructures from "@/features/breakdownStructures";
import { WBSLevelsContext } from "@/shared/context/WBSLevelsContext";

type BreakdownStructuresButtonProps = {
  setIsBreakdownStructuresButtonOpen: (isOpen: boolean) => void;
  setIsHovering: (hovering: boolean) => void;
};

export default function BreakdownStructuresButton({
  setIsBreakdownStructuresButtonOpen,
  setIsHovering,
}: BreakdownStructuresButtonProps) {
  const { setHighlightedWBSEntries } = useContext(WBSLevelsContext);

  const [isModalOpen, setModalOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setModalOpen(true);
    setIsBreakdownStructuresButtonOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setIsBreakdownStructuresButtonOpen(false);
    setIsHovering(false);
    setHighlightedWBSEntries([]);
  };

  return (
    <Box>
      <Button
        onClick={handleOpen}
        variant="contained"
        size="large"
        sx={{
          backgroundColor: "var(--ecerto-pantone-876)",
          color: theme.palette.primary.light,
          width: "100%",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
          },
        }}>
        Breakdown Structures
      </Button>
      <Dialog fullScreen open={isModalOpen} onClose={handleClose} sx={{ zIndex: 3000 }}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
              Breakdown Structures
            </Typography>
            <Button
              variant="text"
              size="large"
              onClick={handleClose}
              sx={{
                color: "#fff",
                marginTop: "10px",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}>
              <CloseIcon fontSize="large" />
            </Button>
          </Toolbar>
        </AppBar>

        <BreakdownStructures />
      </Dialog>
    </Box>
  );
}
