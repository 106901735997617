import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosHeaders,
} from "axios";

import { API_BASE_URL } from "@/shared/api/api";
import msalInstance, { loginRequest } from "@/shared/config/authConfig";

type InternalAxiosRequestConfig = {
  retry?: boolean;
  headers: AxiosRequestHeaders; // This is not optional
} & AxiosRequestConfig;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 1800000,
});

// Set default headers for the request
const useDefaultHeaders = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const account = msalInstance.getActiveAccount();

  if (account) {
    const token = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });
    const updatedConfig = {
      ...config,
      headers: new AxiosHeaders({
        ...config.headers?.toJSON(), // Convert existing headers to a plain object
        Authorization: `Bearer ${token.accessToken}`,
        "X-ID-Token": token.idToken, // Custom header for ID token
      }),
    };
    return updatedConfig;
  }

  return config;
};

// Handle request errors
const handleRequestError = (error: AxiosError): Promise<never> => {
  return Promise.reject(error);
};

// function createAxiosResponseInterceptor() {
//   const interceptor = axiosInstance.interceptors.response.use(
//     (response) => response,
//     (error: AxiosError) => {
//       if (error.response?.status !== 401) {
//         return Promise.reject(error);
//       }

//       axiosInstance.interceptors.response.eject(interceptor);

//       return refreshUserToken(getCookie("refresh_token") || "")
//         .then((response) => {
//           if (!error.response) return Promise.reject(error);
//           // eslint-disable-next-line no-param-reassign
//           error.response.config.headers.Authorization = `Bearer ${response.accessToken}`;
//           // Retry the initial call, but with the updated token in the headers.
//           // Resolves the promise if successful
//           return axios(error.response.config);
//         })
//         .catch(() => {
//           return Navigate({ to: "/login" });
//         })
//         .finally(createAxiosResponseInterceptor); // Re-attach the interceptor by running the method;
//     }
//   );
// }
axiosInstance.interceptors.request.use(useDefaultHeaders, handleRequestError);
// createAxiosResponseInterceptor();

export default axiosInstance;
