import {
  WBSEntryModel,
  WBSEntryViewModel,
} from "@/shared/context/projectWBS/projectWBSModel";
import { formatCurrencyNoDecimals, formatEmissions, formatDays } from "@/shared/utils";

import styles from "./OrgChart.module.css";

export default function OrgChartTable({
  display,
  showCost,
  showTime,
  showEmissions,
  data,
  WBSEntry,
}: {
  display: string;
  showCost: boolean;
  showTime: boolean;
  showEmissions: boolean;
  data: WBSEntryViewModel;
  WBSEntry: WBSEntryModel | null;
}) {
  return (
    display !== "WBS" && (
      <table className={styles["table-container"]}>
        <thead>
          <tr>
            {}
            <th></th>
            {showCost && <th>Cost</th>}
            {showTime && <th>Duration</th>}
            {showEmissions && <th>Emissions</th>}
          </tr>
        </thead>
        <tbody>
          {data.level === 0 && (
            <>
              <tr>
                <td>Project</td>
                {showCost && (
                  <td>{formatCurrencyNoDecimals(WBSEntry?.costForProject ?? 0)}</td>
                )}
                {showTime && <td>{formatDays(WBSEntry?.durationForProject ?? 0)}</td>}
                {showEmissions && (
                  <td>{formatEmissions(WBSEntry?.emissionForProject ?? 0)}</td>
                )}
              </tr>
              <tr>
                <td>Reserve</td>
                {showCost && (
                  <td>{formatCurrencyNoDecimals(WBSEntry?.reserveForCost ?? 0)}</td>
                )}
                {showTime && <td>{formatDays(WBSEntry?.reserveForDuration ?? 0)}</td>}
                {showEmissions && (
                  <td>{formatEmissions(WBSEntry?.reserveForEmission ?? 0)}</td>
                )}
              </tr>
            </>
          )}

          <tr>
            <td>Budget</td>
            {showCost && <td>{formatCurrencyNoDecimals(data.budgetForCost)}</td>}
            {showTime && <td>{formatDays(data.budgetForDuration)}</td>}
            {showEmissions && <td>{formatEmissions(data.budgetForEmission)}</td>}
          </tr>
          <tr>
            <td>Contingency</td>
            {showCost && <td>{formatCurrencyNoDecimals(data.contingencyForCost)}</td>}
            {showTime && <td>{formatDays(data.contingencyForDuration)}</td>}
            {showEmissions && <td>{formatEmissions(data.contingencyForEmission)}</td>}
          </tr>
          <tr style={{ background: "var(--primary-color)", color: "white" }}>
            <td>Baseline</td>
            {showCost && <td>{formatCurrencyNoDecimals(data.baselineForCost)}</td>}
            {showTime && <td>{formatDays(data.baselineForDuration)}</td>}
            {showEmissions && <td>{formatEmissions(data.baselineForEmission)}</td>}
          </tr>
          <tr>
            <td>Allowance</td>
            {showCost && <td>{formatCurrencyNoDecimals(data.allowanceForCost)}</td>}
            {showTime && <td>{formatDays(data.allowanceForDuration)}</td>}
            {showEmissions && <td>{formatEmissions(data.allowanceForEmission)}</td>}
          </tr>
          <tr>
            <td>Estimate</td>
            {showCost && <td>{formatCurrencyNoDecimals(data.estimateForCost)}</td>}
            {showTime && <td>{formatDays(data.estimateForDuration)}</td>}
            {showEmissions && <td>{formatEmissions(data.estimateForEmissions)}</td>}
          </tr>
        </tbody>
      </table>
    )
  );
}
