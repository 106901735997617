/*eslint-disable @typescript-eslint/no-empty-function -- allowing us to pass empty functions in createContext is the least verbose solution */
import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useMemo,
} from "react";

import {
  getTopLevelWBSEntry,
  findEntryInProjectWBS,
  convertToScheduleItems,
} from "@/shared/context/projectWBS/cleanCodeHelpers";
import {
  ProjectWBS,
  WBSEntryModel,
  WBSEntryViewModel,
} from "@/shared/context/projectWBS/projectWBSModel";
import getProjectWBS from "@/shared/context/projectWBS/projectWBSService";
import { IScheduleItems } from "@/shared/types/scheduleItems";

type ProjectWBSContextType = {
  projectWBS: ProjectWBS | null;
  isLoading: boolean;
  refetchProjectWBS: () => void;
  getWBSEntryById: (id?: string) => WBSEntryModel | null;
  getTopLevelWBSEntry: (projectWBS: ProjectWBS) => WBSEntryModel | null;
  getScheduleEntries: () => IScheduleItems[];
};

export const ProjectWBSContext = createContext<ProjectWBSContextType>({
  projectWBS: null,
  isLoading: true,
  refetchProjectWBS: () => {},
  getWBSEntryById: () => null,
  getTopLevelWBSEntry: () => null,
  getScheduleEntries: () => [],
});

type ProjectWBSProviderProps = {
  children: ReactNode;
  conceptId: string;
};

// @todo Temporary fix for WBS code

export default function ProjectWBSProvider({
  children,
  conceptId,
}: ProjectWBSProviderProps) {
  const [projectWBS, setProjectWBS] = useState<ProjectWBS | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Function to sort WBS entries recursively
  const sortWBSEntries = useCallback((entry: WBSEntryViewModel) => {
    if (entry.children && entry.children.length > 0) {
      entry.children.sort((a, b) =>
        a.wbsCode.localeCompare(b.wbsCode, undefined, { numeric: true })
      );
      entry.children.forEach(sortWBSEntries);
    }
  }, []);
  // Function to fetch data
  const refetchProjectWBS = useCallback(async () => {
    // setIsLoading(true);
    try {
      const latestProjectWBS = await getProjectWBS(conceptId);
      // Call assignWBSCodes for the root of the conceptWBS tree
      // Sort the conceptWBS tree
      if (latestProjectWBS.conceptWBS) {
        latestProjectWBS.conceptWBS.children.forEach(sortWBSEntries);
      }
      setProjectWBS(latestProjectWBS);
    } catch (error) {
      throw new Error("Failed to fetch project WBS");
    }
  }, [conceptId, sortWBSEntries]);

  /**
   * This useEffect manages the initial data load and manages the loading state.
   * A minimum loading time of 2.5 seconds is enforced to prevent flickering if the data loads very quickly.
   */
  useEffect(() => {
    const timeout = new Promise<void>((resolve) => setTimeout(resolve, 2500));

    const initialDataLoad = async () => {
      await refetchProjectWBS();
      await timeout;
      setIsLoading(false);
    };

    void initialDataLoad();
  }, [refetchProjectWBS]);
  const getWBSEntryById = useCallback(
    (id?: string): WBSEntryModel | null => {
      if (!projectWBS) return null;
      if (id) {
        return findEntryInProjectWBS(projectWBS, id);
      }
      return getTopLevelWBSEntry(projectWBS);
    },
    [projectWBS]
  );

  // The getScheduleEntries function, converts the project's Work Breakdown Structure (WBS) into schedule items, if projectWBS exists.
  // It uses the convertToScheduleItems function with projectWBS.conceptWBS, theme, and an empty array as arguments, or returns an empty array if projectWBS does not exist.
  const getScheduleEntries = useCallback((): IScheduleItems[] => {
    return projectWBS ? convertToScheduleItems(projectWBS.conceptWBS) : [];
  }, [projectWBS]);

  // Create a memoized context value that only changes when `projectWBS` or `refetchProjectWBS` changes.
  // This prevents unnecessary re-renders in context consumers.
  const ctxValue = useMemo(
    () => ({
      projectWBS,
      isLoading,
      refetchProjectWBS,
      getWBSEntryById,
      getTopLevelWBSEntry,
      getScheduleEntries,
    }),
    [projectWBS, isLoading, refetchProjectWBS, getWBSEntryById, getScheduleEntries]
  );

  return (
    <ProjectWBSContext.Provider value={ctxValue}>{children}</ProjectWBSContext.Provider>
  );
}
