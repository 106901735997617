import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import { CloneModel } from "@/features/portfolio/models/cloneModel";
import { PortfolioRowModel } from "@/features/portfolio/models/portfolioModel";
import ProjectType from "@/shared/enums/projectType";

type CloneFormProps = {
  portfolioItem: PortfolioRowModel;
  projectCode?: number;
  onClone: (newProject: CloneModel) => void;
  innerRef: RefObject<FormikProps<CloneModel>>;
};

export default function CloneForm({
  portfolioItem,
  projectCode,
  onClone,
  innerRef,
}: CloneFormProps) {
  const initialValues = {
    id: portfolioItem.id ?? "",
    newName: `CLONE- ${portfolioItem.name}`,
    projectId: portfolioItem.parentId ?? "",
    projectCode: projectCode ?? 0,
  };
  const validationSchema = yup.object({
    newName: yup
      .string()
      .max(100, "Name should not be longer than 75 characters")
      .required("Name is required"),
    ...(portfolioItem.type === ProjectType.concept && {
      projectCode: yup.number(),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={innerRef}
      onSubmit={(values, { setStatus }) => {
        try {
          onClone(values);
        } catch (err) {
          if (err instanceof Error) {
            setStatus({ errorMessage: err.message });
            // Set a timeout to clear the error message after 5 seconds
            setTimeout(() => setStatus(null), 5000);
          }
        }
      }}>
      {({ values, handleChange, errors, touched }) => (
        <Box sx={{ "& .MuiTextField-root": { mb: 2 }, paddingTop: "1rem" }}>
          <Form>
            <TextField
              required
              label="New Name"
              name="newName"
              onChange={handleChange}
              value={values.newName}
              error={touched.newName && Boolean(errors.newName)}
              helperText={touched.newName && errors.newName}
              fullWidth
            />
            {portfolioItem.type === ProjectType.concept && ( // Conditionally render this block
              <TextField
                label="Project Code"
                name="projectCode"
                value={values.projectCode}
                error={touched.projectCode && Boolean(errors.projectCode)}
                helperText={touched.projectCode && errors.projectCode}
                fullWidth
              />
            )}
          </Form>
        </Box>
      )}
    </Formik>
  );
}
