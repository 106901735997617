import { Box, TextField } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";

import { addNewDeliverable } from "../services/projectScopeService";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string().max(999, "Max length is 1000 characters"),
});

export type AddDeliverableFormType = {
  handleClose: () => void;
  data?: WBSEntryViewModel | null;
  clearRowSelection: () => void;
  innerRef: RefObject<FormikProps<WBSEntryViewModel>>;
};

export default function NewDeliverableForm({
  data,
  handleClose,
  clearRowSelection,
  innerRef,
}: AddDeliverableFormType): JSX.Element {
  const { refetchProjectWBS } = useProjectWBSContext();

  return (
    <Formik
      onSubmit={async (values) => {
        const modelPayload: WBSEntryViewModel = {
          projectId: data?.projectId,
          parentId: data?.id, // This is the id of the selected deliverable
          categoryType: data?.categoryType ?? "null", // @check this was the default value sent in the action
          level: data?.level ? Number(data.level) + 1 : 1,
          wbsCode: "",
          name: values.name,
          description: values.description,
        } as unknown as WBSEntryViewModel;

        try {
          await addNewDeliverable(modelPayload);
        } catch (error) {
          // @todo handle error
        } finally {
          refetchProjectWBS();
          clearRowSelection();
          handleClose();
        }
      }}
      initialValues={{
        projectId: "",
        parentId: "",
        id: "",
        wbsCode: "",
        order: 0,
        name: "",
        description: "",
        level: 0,
        categoryType: "",
        deliverableType: "",
        startDate: new Date(),
        estimateEndDate: new Date(),
        budgetEndDate: new Date(),
        children: [],
        standbyCost: 0,
        height: 0,
        estimateForCost: 0,
        allowanceForCost: 0,
        baselineForCost: 0,
        contingencyForCost: 0,
        budgetForCost: 0,

        estimateForDuration: 0,
        allowanceForDuration: 0,
        baselineForDuration: 0,
        contingencyForDuration: 0,
        budgetForDuration: 0,

        estimateForEmissions: 0,
        allowanceForEmission: 0,
        baselineForEmission: 0,
        contingencyForEmission: 0,
        budgetForEmission: 0,
      }}
      validationSchema={validationSchema}
      innerRef={innerRef}>
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Box>
          <Form style={{ gap: "0.5rem", flexDirection: "column", display: "flex" }}>
            <TextField
              required
              label="Deliverable Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              fullWidth
            />
            <TextField
              label="Scope Statement"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              fullWidth
              multiline
              rows={5}
              inputProps={{ maxLength: 1000 }}
            />
            {/* <Box display="flex" justifyContent="flex-end" mt={1} gap={1}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!isValid || isSubmitting}>
                Create
              </Button>
            </Box> */}
          </Form>
        </Box>
      )}
    </Formik>
  );
}
