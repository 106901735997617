import * as d3 from "d3";
import { HierarchyNode } from "d3-hierarchy";
import { OrgChart } from "d3-org-chart";
import { useContext, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import OrgChartTable from "@/features/breakdownStructures/components/OrgChartSummaryTable";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { WBSLevelsContext } from "@/shared/context/WBSLevelsContext";

import { IData } from "../models/orgChartModels";
import expandNodesByIds, {
  getAllDescendantIdsIncludingChildren,
} from "../utils/orgChartHelpers";

import styles from "./OrgChart.module.css";

export default function OrgChartComponent(
  // WBSENTRIES
  data: IData[],
  ref: React.MutableRefObject<null>,
  display: string
) {
  const chart: OrgChart<IData> = useMemo(() => new OrgChart(), []);

  const nodeWidthRef = useRef(590); // Initialize the ref with the default width

  // @important this comes from the context of the same nodes in the Sidebar
  const { highlightedWBSEntries, expandedWBSEntries, setExpandedWBSEntries } =
    useContext(WBSLevelsContext);

  const { getWBSEntryById } = useProjectWBSContext();

  const WBSEntry = getWBSEntryById();

  const showCost = display === "overview" || display === "CBS";
  const showTime = display === "TBS" || display === "overview";
  const showEmissions = display === "EBS" || display === "overview";

  useEffect(() => {
    if (display === "WBS") {
      nodeWidthRef.current = 350;
    } else if (display === "overview") {
      nodeWidthRef.current = 957;
    } else {
      nodeWidthRef.current = 650;
    }
    if (expandedWBSEntries) {
      expandNodesByIds(chart, expandedWBSEntries);
      chart.fit();
    }
  }, [chart, expandedWBSEntries, display]);

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (data && ref.current) {
      chart
        .container(ref.current)
        .data(data)
        .nodeWidth(() => nodeWidthRef.current)
        .nodeHeight((d: HierarchyNode<IData>) => (d.data.level === 0 ? 300 : 220))
        .linkUpdate(function updateLink(this: SVGGElement) {
          d3.select(this)
            .attr("stroke-width", 3)
            .attr("stroke", "var(--ecerto-pantone-876)");
        })
        .nodeUpdate(function updateNode(
          this: SVGGElement,
          d: HierarchyNode<IData>
        ): void {
          const isHighlighted = highlightedWBSEntries.includes(d.data.id);
          d3.select(this)
            .select(".node-rect")
            .attr("stroke", () =>
              isHighlighted ? "var(--ecerto-secondary-lightblue)" : "none"
            )
            .attr("stroke-width", () => (isHighlighted ? 40 : 1))
            .attr("stroke-linejoin", "miter")
            .attr("stroke-linecap", "butt")
            .attr("rx", 0) // Ensure square corners by setting the x radius to 0
            .attr("ry", 0); // Ensure square corners by setting the y radius to 0
        })
        // .onNodeClick((d: HierarchyNode<IData>) => {
        // @todo determine what to do when clicking on a node
        // })
        .onExpandOrCollapse((node: HierarchyNode<IData>) => {
          if (node.depth === 0) return;

          // @todo make the chart nodes that were expanded persist later
          const currentExpandedNodes = expandedWBSEntries || [];
          const nodeId = node.id!;
          const isNodeOpen = currentExpandedNodes.includes(nodeId);

          if (isNodeOpen) {
            // Record the expanded state of all descendants
            const allAffectedIds = getAllDescendantIdsIncludingChildren(
              nodeId,
              chart.data() ?? []
            );
            const updatedExpandedNodes = currentExpandedNodes.filter(
              (id) => !allAffectedIds.includes(id)
            );
            setExpandedWBSEntries(updatedExpandedNodes);
          } else {
            const updatedExpandedNodes = [...currentExpandedNodes, nodeId];

            setExpandedWBSEntries(updatedExpandedNodes);
          }
        })

        .nodeId((d) => d.id)
        .svgHeight(window.innerHeight - 200)
        .compact(false)
        .neighbourMargin(() => 250)
        .childrenMargin(() => 150)
        .siblingsMargin(() => 25)
        // WBSentry
        .nodeContent((d: HierarchyNode<IData>) => {
          return renderToStaticMarkup(
            <div
              className={styles["chart-wrapper"]}
              style={{
                display: "flex",
              }}>
              <div
                className={styles.box}
                style={{
                  width: `${nodeWidthRef.current}px`,
                  height: `${d.height}px`,
                }}>
                <h3>
                  {d.data.wbsCode} {d.data.name.toUpperCase()}
                </h3>
                <p>Level {d.data.level}</p>
                <p className={styles.secondary}>Scenario:</p>
                <p
                  style={{
                    margin: "0 !important",
                    color: "#fff",
                    fontSize: "16px",
                    marginBottom: "10px !important",
                  }}>
                  Transactional
                </p>
                <div className={styles.dates}>
                  <div>
                    <p className={styles.secondary}>From</p>
                    <p>
                      {new Date(d.data.startDate).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                  <div>
                    <p className={styles.secondary}>To</p>
                    <p>
                      {d.data.level === 0 && WBSEntry?.projectEndDate
                        ? new Date(WBSEntry.projectEndDate).toLocaleDateString()
                        : new Date(d.data.budgetEndDate).toLocaleDateString()}
                      {/* {new Date(d.data.).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",ç
                      })} */}
                    </p>
                  </div>
                </div>
              </div>
              <OrgChartTable
                display={display}
                showCost={showCost}
                showTime={showTime}
                showEmissions={showEmissions}
                data={d.data}
                WBSEntry={WBSEntry}
              />
            </div>
          );
        })
        .render();
      d3.select(ref.current)
        .select("svg")
        .style("background-color", "#f2f3f4")
        .style("height", "100%")
        .style("width", "100%");
    }
  }, [
    data,
    chart,
    ref,
    display,
    expandedWBSEntries,
    setExpandedWBSEntries,
    showCost,
    showTime,
    showEmissions,
    highlightedWBSEntries,
    WBSEntry,
  ]);

  return chart;
}
