import LifeCycleStage from "@/shared/enums/lifeCycleStage";
import ProjectRegion from "@/shared/enums/projectRegion";
import ProjectType from "@/shared/enums/projectType";
import Risk from "@/shared/enums/risk";
import ProjectScenarios from "@/shared/enums/scenarios";

// A type guard, that checks if the row is a project or a concept - based on the type, 1 is project, 2 is concept
export default function determineIfIsProject(row: PortfolioRowModel): boolean {
  return row.type === ProjectType.project;
}

export type Project = {
  projectId: string;
  order: number;
  type: ProjectType;
  projectName: string;
  programmeName: string;
  region: ProjectRegion;
  selectedConceptId: string;
  concepts: Concept[];
  projectCode: number;
};

export type Concept = {
  parentId: string;
  id: string;
  order: number;
  type: ProjectType;
  conceptName: string;
  projectCode: number;
  riskLevel: Risk;
  lifeCycleStage: LifeCycleStage;
  estimatedStartDate: string;
  estimatedEndDate: string;
  selectedScenario: ProjectScenarios;
  standbyCost: number;
  estimate: number;
  allowance: number;
  baseline: number;
  contingency: number;
  budget: number;
  reserve: number;
  projectCost: number;
  projectDuration: number;
  projectEmissions: number;
  netPresentValue: number;
  internalRateOfReturn: number;
};

// PortfolioRowModel
export type PortfolioRowModel = {
  id: string;
  type: ProjectType;
  parentId: string | null;
  parentName: string | null;
  budget: number;
  cO2Emissions: number;
  order?: number;
  projectCode: number;
  selectedDevelopmentOption: PortfolioRowModel | null | undefined;
  selectedDevelopmentOptionId: string;
  name: string;
  region: ProjectRegion;
  programmeName: string;
  lifeCycleStage: LifeCycleStage;
  selectedDevelopmentOptionName: string;
  selectedScenario: ProjectScenarios;
  riskLevel: number;
  projectCostBaseline: number;
  projectScopeAllowance: number;
  projectControlEstimate: number;
  projectExecutionContingency: number;
  projectManagementReserve: number;
  estimatedCost: number;
  estimatedStart: string;
  estimatedEnd: string;
  projectDurationBaseline: number;
  netPresentValue: number;
  internalRateOfReturn: number;
  children: PortfolioRowModel[];
};
