import { Alert, Snackbar, SnackbarContent, useTheme } from "@mui/material";
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
} from "material-react-table";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import changeOrder from "@/features/portfolio/services/changeOrderService";
import mapProjectsToPortfolioRowModels, {
  conceptToPortfolioRowModel,
} from "@/features/portfolio/utils/portfolioHelpers";
import ProjectType from "@/shared/enums/projectType";
import ProjectScenarios from "@/shared/enums/scenarios";

import { EditConceptModel } from "../models/editConceptModel";
import { Concept, PortfolioRowModel, Project } from "../models/portfolioModel";
import editConcept from "../services/editConceptService";
import getPortfolio from "../services/getPortfolioService";

import PortfolioColumns from "./columns/PortfolioTableColumns";
import PortfolioRowActions from "./PortfolioRowActions";
import PortfolioToolbar from "./PortfolioToolbar";

export default function PortfolioTable() {
  sessionStorage.removeItem("selectedWbsEntryId");
  sessionStorage.removeItem("baselineTab");
  const projects = useLoaderData() as PortfolioRowModel[];
  const [data, setData] = useState<PortfolioRowModel[]>(projects);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [snackbar, setSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const columns = PortfolioColumns();
  const theme = useTheme();

  const addCloneProject = (project: Project) => {
    const formattedProject = mapProjectsToPortfolioRowModels([project])[0];
    setData([...data, formattedProject]);
  };

  const addCloneConcept = (clonedConcept: Concept) => {
    const newData = data.map((project) => {
      if (project.id === clonedConcept.parentId) {
        const formattedConcept = conceptToPortfolioRowModel(clonedConcept, project);
        return {
          ...project,
          children: [...project.children, formattedConcept],
        };
      }
      return project;
    });
    setData(newData);
  };

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const res = await getPortfolio();
      setData(res);
    } catch {
      setIsError(true);
    }

    // Reset loading state when data is fetched

    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleSaveCell = async (
    conceptToUpdate: EditConceptModel,
    name: string,
    value: string
  ) => {
    const updatedConcept = {
      ...conceptToUpdate,
      [name]: value,
    };
    try {
      await editConcept(updatedConcept).then(() => {
        void fetchData();
      });
    } catch (error) {
      // @todo handle error
      throw new Error();
    }
  };

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };

  const handleDragAndDrop = async (sourceRowId: string, targetRowId: string) => {
    try {
      await changeOrder(sourceRowId, targetRowId);
      void fetchData();
    } catch (error) {
      // @todo handle error
      throw new Error();
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      density: "compact",
      columnPinning: {
        left: ["mrt-row-drag", "mrt-row-select", "mrt-row-expand", "name"],
        right: ["mrt-row-actions"],
      },
      pagination: {
        pageIndex: 0,
        pageSize: 15,
      },
    },
    muiTableHeadCellProps: {
      sx: () => ({
        background: "var(--primary-color)",
        color: "#fff",
        fontSize: "12px",
      }),
    },
    muiTopToolbarProps: {
      sx: () => ({
        // @check this changes the toolbar at the top of the table
        // @todo pass this to themes
        // background: "var(--ecerto-green)",
        // color: "white !important",
        // "& .MuiIconButton-root": {
        //   color: "white !important",
        // },
        "& .MuiIconButton-root": {
          color: "var(--primary-color) !important",
        },
      }),
    },
    positionToolbarAlertBanner: "none",
    muiTableBodyRowProps: ({ row }) => ({
      className: `portfolio type-${row.original.type}`,
      // @todo here we change row colors what about pinned columns?
      sx: {
        backgroundColor: () => {
          switch (row.original.type) {
            case ProjectType.project:
              return theme.palette.primary.light;
            case ProjectType.concept:
              return theme.palette.secondary.light;
            default:
              return theme.palette.levelColors.light;
          }
        },
      },
    }),
    muiTableBodyCellProps: {
      align: "center",
      // @check below change the background on hover for each cell
      // sx: {
      //   "&:hover": {
      //     backgroundColor: "var(--ecerto-red) !important",
      //     color: "white !important",
      //   },
      // },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "70vh",
        overflow: "auto", // Enable scrolling
        // make only horizontal scroll bar visible
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "8px !important",
          backgroundColor: "#f5f5f5",
          height: "13px !important",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          backgroundColor: "#ccc",
          "&:hover": {
            backgroundColor: "#b3b3b3",
          },
          "&:active": {
            backgroundColor: "var(--primary-color)",
          },
        },
      },
    },
    muiExpandAllButtonProps: {
      sx: {
        margin: "0 10px",
      },
    },
    enableStickyHeader: true,
    enableColumnFilterModes: true,
    enableRowOrdering: true,
    enableColumnPinning: true,
    enableColumnActions: false,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
    },
    getRowId: (originalRow) => originalRow.id,
    enableExpanding: true,
    enableExpandAll: true,
    // https://www.material-react-table.com/docs/guides/expanding-sub-rows#expanded-rows-pagination-behavior
    paginateExpandedRows: false,
    displayColumnDefOptions: {
      "mrt-row-drag": { size: 10, enablePinning: false },
      "mrt-row-select": { size: 10, enablePinning: false },
      "mrt-row-expand": { size: 2, enablePinning: false },
      "mrt-row-actions": { size: 10, enablePinning: false },
    },
    muiRowDragHandleProps: ({ table: dragTable }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = dragTable.getState();

        // Checks if the Ids exist and weather they are the same in case user drag and drop the same row
        if (
          !hoveredRow?.original?.id ||
          !draggingRow?.original?.id ||
          hoveredRow.original.id === draggingRow.original.id
        )
          return;

        // restricts dragging within the same parent
        if (
          draggingRow &&
          hoveredRow &&
          draggingRow.parentId === hoveredRow.parentId &&
          typeof draggingRow.original.order === "number" &&
          typeof hoveredRow.original.order === "number"
        ) {
          void handleDragAndDrop(draggingRow.original.id, hoveredRow.original.id);
        } else {
          setSnackbar(true);
        }
      },
    }),
    enableRowActions: true,
    // @important These are the row actions (Edit, Add Concept)
    renderRowActions: ({ row }) => [
      <PortfolioRowActions
        key={row.original.id}
        row={row}
        addCloneProject={addCloneProject}
        addCloneConcept={addCloneConcept}
        refetchProjects={fetchData}
      />,
    ],
    muiEditTextFieldProps: ({ row }) => ({
      onBlur: (event) => {
        const { name, value } = event.target;

        void handleSaveCell(row.original, name, value);
      },
    }),
    editDisplayMode: "cell",
    enableEditing: true,
    enableGlobalFilterModes: true,
    autoResetPageIndex: false,
    getSubRows: (originalRow) => {
      if (originalRow.type === ProjectType.project && originalRow.children.length > 0)
        return originalRow.children;
      if (originalRow.type === ProjectType.concept) {
        return [
          {
            ...originalRow,
            name: `${originalRow.name} - Transactional`,
            id: `${originalRow.id} - Transactional`,
            type: 3,
            selectedScenario: ProjectScenarios.Transactional,
            sx: {
              backgroundColor: "blue",
            },
          },
          {
            ...originalRow,
            name: `${originalRow.name} - Bundled`,
            id: `${originalRow.id} - Bundled`,
            type: 3,
            selectedScenario: ProjectScenarios.Bundled,
          },
          {
            ...originalRow,
            name: `${originalRow.name} - Integrated`,
            id: `${originalRow.id} - Integrated`,
            type: 3,
            selectedScenario: ProjectScenarios.Integrated,
          },
          {
            ...originalRow,
            name: `${originalRow.name} - Cost`,
            id: `${originalRow.id} - Cost`,
            type: 3,
            selectedScenario: ProjectScenarios.Cost,
          },
          {
            ...originalRow,
            name: `${originalRow.name} - Duration`,
            id: `${originalRow.id} - Duration`,
            type: 3,
            selectedScenario: ProjectScenarios.Duration,
          },
          {
            ...originalRow,
            name: `${originalRow.name} - Emissions`,
            id: `${originalRow.id} - Emissions`,
            type: 3,
            selectedScenario: ProjectScenarios.Emissions,
          },
        ];
      }

      return [];
    },
  });

  if (isError)
    return <Alert severity="error">Network Error. Could not fetch data.</Alert>;

  return (
    <div style={{ position: "relative" }}>
      <PortfolioToolbar
        refetchProjects={fetchData}
        tableInstanceRef={table}
        rowSelection={rowSelection}
      />
      <MaterialReactTable table={table} />
      <Snackbar
        open={snackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={4000}
        onClose={handleClose}>
        <SnackbarContent
          style={{ backgroundColor: theme.palette.primary.dark, color: "white" }}
          message="Please note, items can only be rearranged within their own group."
        />
      </Snackbar>
    </div>
  );
}
