import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Formik, Form, FormikProps } from "formik";
import { RefObject } from "react";
import * as yup from "yup";

import ProjectRegion from "@/shared/enums/projectRegion";

import { EditProjectModel } from "../../models/editProjectModel";
import { PortfolioRowModel } from "../../models/portfolioModel";
import editProject from "../../services/editProjectService";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .max(100, "Name should not exceed 75 characters"),
  programmeName: yup
    .string()
    .max(100, "Programme name should not be longer than 75 characters")
    .required("Programme name is required"),

  selectedDevelopmentOptionId: yup
    .string()
    .required("Please select a development option"),
  lifeCycleStage: yup.number().required("Please select a life cycle stage"),
  riskLevel: yup.number().required("Please select a risk level"),
});

export type EditProjectFormProps = {
  project: PortfolioRowModel;
  onClose: () => void;
  refetchProjects: () => Promise<void>;
  innerRef: RefObject<FormikProps<EditProjectModel>>;
};

export default function EditProjectForm({
  project,
  onClose,
  refetchProjects,
  innerRef,
}: EditProjectFormProps) {
  const initialValues: EditProjectModel = {
    id: project.id,
    name: project.name,
    programmeName: project.programmeName,
    region: project.region,
    lifeCycleStage: project.lifeCycleStage,
    riskLevel: project.riskLevel,
    selectedDevelopmentOptionId: project.selectedDevelopmentOptionId,
    type: project.type,
  };

  const concepts = project.children.map((concept) => {
    return { id: concept.id, name: concept.name };
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={innerRef}
      onSubmit={async (values) => {
        try {
          await editProject(values);

          onClose();
          await refetchProjects();
          // You can use 'response' here as needed
        } catch (err) {
          if (err instanceof Error) {
            // Throw the error to be handled by a higher-level error boundary or handler
            throw new Error(`Failed to edit project: ${err.message}`);
          } else {
            // Throw a generic error if the error is not an instance of Error
            throw new Error("Failed to edit project: An unknown error occurred");
          }
        }
      }}>
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <Box
            sx={{ "& .MuiTextField-root": { mb: 2, width: "100%" }, paddingTop: "1rem" }}>
            <TextField
              name="name"
              label="Project Name"
              required
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              name="programmeName"
              label="Programme Name"
              required
              value={values.programmeName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.programmeName && Boolean(errors.programmeName)}
              helperText={touched.programmeName && errors.programmeName}
            />
            <TextField
              name="region"
              label="Region"
              required
              select
              value={values.region}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.region && Boolean(errors.region)}
              helperText={touched.region && errors.region}>
              {Object.values(ProjectRegion)
                .filter((value) => typeof value === "string")
                .map((key, index) => (
                  <MenuItem key={key} value={index}>
                    {key}
                  </MenuItem>
                ))}
            </TextField>

            <TextField
              name="selectedDevelopmentOptionId"
              label="Selected Concept"
              required
              select
              value={values.selectedDevelopmentOptionId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.selectedDevelopmentOptionId &&
                Boolean(errors.selectedDevelopmentOptionId)
              }
              helperText={
                touched.selectedDevelopmentOptionId && errors.selectedDevelopmentOptionId
              }>
              {concepts.map((child) => (
                <MenuItem key={child.id} value={child.id}>
                  {child.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
