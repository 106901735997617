import { Box, InputAdornment, TextField, TypographyVariant } from "@mui/material";
import { useRef, useState } from "react";

import NumberLabel from "@/shared/components/NumberLabel";
import { Units, UnitsAbbreviations } from "@/shared/enums/units";

const TextFieldStyle = () => ({
  whiteSpace: "nowrap",
  borderBottom: "0.15rem solid var(--primary-color-900)",
  "& .MuiInputBase-input": {
    textAlign: "right",
    fontSize: "1.30rem",
    fontWeight: 500,
    paddingBottom: "0rem",
  },
});
export const InputAdornmentStyle = () => ({
  "&.MuiInputAdornment-root p": {
    color: "var(--primary-color-900)",
    fontWeight: 500,
  },
});

type NumberFieldProps = {
  type: "currency" | "duration" | "emission" | "quantity" | "percentage";
  number: number;
  hasDecimals: boolean;
  isEditable: boolean;
  unit?: Units;
  variant?: TypographyVariant;
  color?: string;
  handleBlur?: (...args: unknown[]) => Promise<void> | void;
  maximumDigits?: number;
  // maxValue?: number;
  // minValue?: number;
  isDisabled?: boolean;
  // allowNegatives?: boolean;
  // isAnimated?: boolean; // Added animated as a new parameter
};

export default function NumberField({
  type = "quantity",
  number = 0,
  hasDecimals = true,
  isEditable = false,
  maximumDigits = 12, // not including commas or dot
  isDisabled = false,
  unit,
  variant,
  color,
  handleBlur, // Added handleBlur parameter
  // isAnimated = true, // Added animated parameter with default value true
}: NumberFieldProps) {
  // @todo we will need to remove styles from these components except color. This  best handled in a .css file
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const onBlur = async () => {
    if (handleBlur && inputRef.current) {
      try {
        await handleBlur(inputRef.current.value);
      } catch (error) {
        throw new Error(`Error handling blur: ${(error as Error).message}`);
      }
    }
    setIsEditing(false);
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Limit input to maximumDigits
    if (event.target.value.length > maximumDigits) {
      inputRef.current!.value = event.target.value.slice(0, maximumDigits);
    }
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };
  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      void onBlur();
    }
  };

  return isEditable && isEditing ? (
    <TextField
      defaultValue={number}
      type="number"
      variant="standard"
      size="small"
      onKeyDown={onKeyDown}
      onChange={onChange}
      disabled={isDisabled}
      /* eslint-disable-next-line jsx-a11y/no-autofocus -- This component needs to be reworked anyways so this will be deleted */
      autoFocus
      InputProps={{
        endAdornment: (
          <InputAdornment sx={InputAdornmentStyle} position="end">
            {unit && UnitsAbbreviations.get(unit)}
          </InputAdornment>
        ),
      }}
      onBlur={() => void onBlur}
      inputRef={inputRef}
      sx={{ ...TextFieldStyle() }}
      key={number}
    />
  ) : (
    <Box
      onDoubleClick={isEditable ? handleDoubleClick : undefined}
      sx={
        isEditable
          ? {
              cursor: "pointer",
              borderBottom: "0.15rem solid var(--primary-color-100)",
              "&:hover": {
                borderBottom: "0.15rem solid var(--primary-color-900)",
                transform: "scale(1.05)",
                transition: "transform 0.2s ease-in-out",
              },
            }
          : {}
      }>
      <NumberLabel
        number={number}
        type={type}
        unit={unit}
        hasDecimals={hasDecimals}
        // isAnimated={isAnimated}
        variant={variant}
        color={color}
      />
    </Box>
  );
}
