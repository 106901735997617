import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeView } from "@mui/x-tree-view/";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import { WBSEntryViewModel } from "@/shared/context/projectWBS/projectWBSModel";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import { WBSLevelsContext } from "@/shared/context/WBSLevelsContext";
import { formatCurrencyNoDecimals } from "@/shared/utils";

import CustomTreeItem from "./CustomTreeItem";

const renderTree = (nodes: WBSEntryViewModel, selectedOutcomeId?: string) => {
  const isAnOutcomeSelected = nodes.id === selectedOutcomeId;
  const labelStyle = isAnOutcomeSelected
    ? {
        color: "var(--ecerto-secondary-B06C95)",
        fontWeight: "bold",
      }
    : {};

  return (
    <CustomTreeItem
      key={nodes.id}
      nodeId={nodes.id}
      border="var(--ecerto-pantone-876)"
      label={
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            ...labelStyle,
          }}>
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>
            {nodes.name}
          </span>
          <span>{formatCurrencyNoDecimals(nodes.estimateForCost || 0)}</span>
        </span>
      }>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node, selectedOutcomeId))
        : null}
    </CustomTreeItem>
  );
};

export default function DeliverableTree() {
  const { expandedWBSEntries: expandedNodes, setExpandedWBSEntries: setExpandedNodes } =
    useContext(WBSLevelsContext);
  const { outcome: selectedOutcomeId } = useParams();

  const { projectWBS } = useProjectWBSContext();

  return (
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={["root"]}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expandedNodes}
      onNodeToggle={(_event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpandedNodes(nodeIds);
      }}>
      {projectWBS?.conceptWBS.children.map((item) => {
        return renderTree(item, selectedOutcomeId);
      })}
    </TreeView>
  );
}
