// import AddBoxIcon from "@mui/icons-material/AddBox";
// import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { OrgChart } from "d3-org-chart";

import { IData } from "../models/orgChartModels";

export default function OrgChartButtons({ chart }: { chart: OrgChart<IData> }) {
  return (
    <Grid container spacing={2} justifyContent="end" flexWrap="nowrap">
      <Grid item>
        <Tooltip title="Fit to screen" arrow>
          <Button variant="contained" onClick={() => chart.fit()}>
            <ZoomOutMapIcon />
          </Button>
        </Tooltip>
      </Grid>
      {/* <Grid item>
        <Tooltip title="Expand all" arrow>
          <Button
            variant="contained"
            onClick={() => {
              chart.expandAll();
              chart.fit();
            }}>
            <AddBoxIcon />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Collapse all" arrow>
          <Button
            variant="contained"
            onClick={() => {
              chart.collapseAll();
              chart.fit();
            }}>
            <IndeterminateCheckBoxIcon />
          </Button>
        </Tooltip>
      </Grid> */}
      <Grid item>
        <Tooltip title="Zoom out" arrow>
          <Button variant="contained" onClick={() => chart.zoomOut()}>
            <ZoomOutIcon />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Zoom in" arrow>
          <Button variant="contained" onClick={() => chart.zoomIn()}>
            <ZoomInIcon />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
