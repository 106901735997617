// @info The RiskTable component is a table display for project risks. It receives columns, risks data, and risk totals data as props. It uses the useState hook to manage state for risks, risk totals, and a loading state. It also provides a function handleSaveCell to update a risk and refresh the table. The updated risks and totals are fetched from a service updateRisk.

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AddRisk from "@/features/risk/components/AddRisk";
import TableForRisk from "@/features/risk/components/TableForRisk";
import { RiskBaseModel } from "@/features/risk/models/riskModels";
import updateRisk from "@/features/risk/services/editRiskService";
import getProjectBaseRisks from "@/features/risk/services/riskBaselineService";
import {
  BaselineOverviewSchema,
  updateBaselineOverviewSchema,
  BaselineOverview,
} from "@/shared/components/baselineOverview";
import useProjectWBSContext from "@/shared/context/projectWBS/useProjectWBSContext";
import ParametricCostTypes from "@/shared/enums/parametricCostTypes";
import { ParameterModels } from "@/shared/types/parameterModels";

type RiskTableProps = {
  risksData: RiskBaseModel[];
  parametricData: ParameterModels[];
};

export default function RiskTable({ risksData, parametricData }: RiskTableProps) {
  const [risks, setRisks] = useState<RiskBaseModel[]>([]);
  const { outcome: wbsEntryId } = useParams();

  const { getWBSEntryById, isLoading, refetchProjectWBS } = useProjectWBSContext();
  const WBSEntry = getWBSEntryById(wbsEntryId);
  const parametricOutcome = parametricData.find(
    //eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- we need to add a type to the parameter to compare enum with enum rather than name with enum. Must be fixed when refactoring
    (item) => item.name === ParametricCostTypes.Outcome
  );
  const OverviewItems = updateBaselineOverviewSchema(
    BaselineOverviewSchema,
    WBSEntry,
    parametricOutcome
  );
  const baselineOverviewTitle = WBSEntry
    ? `Level ${WBSEntry.level} — ${WBSEntry.name}`
    : "Error: Title was unable to load";

  useEffect(() => {
    setRisks(risksData);
  }, [risksData]);
  const handleSaveCell = async (
    riskToUpdate: RiskBaseModel,
    name: string,
    value: string
  ) => {
    const updatedRisk = {
      ...riskToUpdate,
      [name]: value,
    };
    try {
      const RiskBaseline = await updateRisk(updatedRisk);
      setRisks(RiskBaseline);
      refetchProjectWBS();
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Failed to save cell: ${error.message}`);
      } else {
        throw new Error("An unknown error occurred while saving cell");
      }
    }
  };

  const handleRefreshRisk = async () => {
    try {
      if (!wbsEntryId) {
        throw new Error("Outcome is null");
      }
      const updatedRisks = await getProjectBaseRisks(wbsEntryId);
      refetchProjectWBS();
      setRisks(updatedRisks);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Failed to refresh risks: ${error.message}`);
      } else {
        throw new Error("An unknown error occurred while refreshing risks");
      }
    }
  };

  return (
    <>
      <BaselineOverview
        OverviewItems={OverviewItems}
        isLoading={isLoading}
        title={baselineOverviewTitle}
      />
      <AddRisk refetchRisk={() => void handleRefreshRisk()} />
      <TableForRisk
        risks={risks}
        handleSaveCell={(riskToUpdate, columnId, newValue) =>
          void handleSaveCell(riskToUpdate, columnId, newValue)
        }
        refetchRisk={() => void handleRefreshRisk()}
        overviewItems={OverviewItems}
      />
    </>
  );
}
