import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Units } from "@/shared/enums/units";
import { formatNumberLabel } from "@/shared/utils";

type TypographyVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline"
  | "inherit";

type NumberLabelProps = {
  number: number;
  type: "currency" | "duration" | "emission" | "quantity" | "percentage";
  hasDecimals: boolean;
  unit?: Units;
  // isAnimated?: boolean;
  variant?: TypographyVariant;
  color?: string;
};
export default function NumberLabel({
  type,
  number,
  hasDecimals,
  variant,
  color,
  // isAnimated,
  unit,
}: NumberLabelProps) {
  const { formattedNumber, subScript } = formatNumberLabel(
    type,
    number,
    hasDecimals,
    unit
  );
  // @todo add typography prop for elipsis
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-end",
      }}>
      <Typography
        className="numberLabel"
        variant={variant}
        sx={{
          color,
        }}>
        {formattedNumber}
      </Typography>
      {subScript && (
        <Typography
          className="subscript"
          variant="body1"
          sx={{
            color,
            marginLeft: 0.5,
          }}>
          {subScript}
        </Typography>
      )}
    </Box>
  );
}
