// import { AxiosError, AxiosResponse } from "axios";
import { AxiosError } from "axios";

import { UserFormType } from "@/shared/types/userInfoModels";

// import { PortfolioRowModel } from "@/features/portfolio/models/portfolioModel";
// import { PROJECTS_API_ENDPOINTS } from "@/shared/api/api";
// import apiRequests from "@/shared/config/axiosConfig";

export default async function editAccount(values: UserFormType) {
  try {
    // eslint-disable-next-line no-console -- temporary
    console.log("values:", values);
    await Promise.resolve();
    // const url = PROJECTS_API_ENDPOINTS.GET_PROJECTS;
    // const response: AxiosResponse<PortfolioRowModel> = await apiRequests.put(url, values);

    // const { data, status } = response;

    // if (status === 200) {
    //   return data;
    // }

    // if (status === 204) {
    //   // Handle 204 status as successful
    //   return data || { message: "No content", statusCode: 204 };
    // }

    throw new Error(`Unknown error occurred with status code ${status}`);
  } catch (e) {
    // @todo Handle Error Below
    const error = e as AxiosError;
    switch (error.response?.status) {
      case 400:
        throw new Error("400: Bad Request");
      case 401:
        throw new Error("401: Unauthorized");
      case 404:
        throw new Error("404: Not Found");
      default:
        throw new Error(
          `Unknown error occurred with status code ${error.response?.status}`
        );
    }
  }
}
