// ReusableModal.tsx
import { SxProps, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

type ModalProps = {
  isOpen: boolean;
  title: string;
  content: React.ReactNode;
  onClose: () => void;
  onConfirm?: () => void;
  shouldCloseOnConfirm?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  confirmButtonText?: string;
  sx?: SxProps;
  fullScreen?: boolean;
};

export default function ReusableModal({
  isOpen,
  title,
  content,
  onClose,
  onConfirm,
  confirmButtonText = "Confirm",
  shouldCloseOnConfirm = true,
  maxWidth = "sm",
  sx,
  fullScreen = false,
}: ModalProps) {
  const theme = useTheme();
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth} fullScreen={fullScreen}>
      {title && (
        <DialogTitle
          sx={{
            paddingBottom: "0px !important",
          }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          paddingTop: `8px ${title ? "!important" : ""}`,
          overflowY: "hidden",
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "8px",
          },
          ...sx,
        }}>
        {content}
      </DialogContent>
      {onConfirm && (
        <DialogActions sx={{ padding: "1rem" }}>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              if (shouldCloseOnConfirm) {
                onClose();
              }
            }}
            variant="contained"
            color="primary">
            {confirmButtonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
