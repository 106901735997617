import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Navigate, RouteObject, redirect } from "react-router-dom";

import NotFound from "@/app/pages/404";
import Settings from "@/app/pages/accountManagement/Settings";
import Portfolio from "@/app/pages/Portfolio";
import ProjectBaseline, {
  ProjectBaselineRouteProps,
} from "@/app/pages/strategyDefinition/ProjectBaseline";
import ProjectSchedule from "@/app/pages/strategyDefinition/ProjectSchedule";
import ProjectScope from "@/app/pages/strategyDefinition/ProjectScope";
import ProcurementScenarios from "@/app/pages/strategyDefinition/Scenarios";
import StrategyDefinitionProvider from "@/app/pages/strategyDefinition/StrategyDefinitionProvider";
import { getCostDataService } from "@/features/cost";
import { getProjectBaselineParameters } from "@/features/parametric";
import { getPortfolio } from "@/features/portfolio";
import { loader as projectScopeLoader } from "@/features/projectScope";
import { getProjectBaseRisks } from "@/features/risk";
import MainNavigationLayout from "@/layouts/MainNavigationLayout";
import StrategyDefinitionLayout from "@/layouts/strategyDefinitionLayout/StrategyDefinitionLayout";
import { loginRequest } from "@/shared/config/authConfig";
import useConceptContext from "@/shared/context/conceptContext/useProjectContext";
import getUserFromGraph from "@/shared/store/authThunks";
import { useAppDispatch } from "@/shared/store/hooks";
import { ParameterModels } from "@/shared/types/parameterModels";

/**
 * A component that renders the given component only if the user is authenticated.
 * If the user is not authenticated, it redirects to the login page.
 * @param {JSX.Element} component - The component to render if the user is authenticated.
 * @returns {JSX.Element} The protected component or a redirect to the login page.
 */
const ProtectedRoute = ({ component }: { component: JSX.Element }): JSX.Element => {
  const dispatch = useAppDispatch();

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    void instance.handleRedirectPromise();

    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      void instance.loginPopup(loginRequest);
    } else {
      void dispatch(getUserFromGraph());
    }
  }, [dispatch, inProgress, instance, isAuthenticated]);

  return component;
};

/**
 * Returns an array of RouteObjects for the private routes of the application.
 * These routes require authentication to access.
 * @returns {RouteObject[]} An array of RouteObjects representing the private routes.
 */
export default function PrivateRoutes(): RouteObject[] {
  const { baselineTab } = useConceptContext();

  return [
    {
      path: "/",
      element: <MainNavigationLayout />,
      children: [
        {
          index: true,
          element: <ProtectedRoute component={<Navigate to="/portfolio" replace />} />,
          errorElement: <div>Oops! There was an error in the Portfolio.</div>,
        },
        {
          path: "login",
          element: <ProtectedRoute component={<Navigate to="/portfolio" replace />} />,
          errorElement: <div>Oops! There was an error in the Portfolio.</div>,
        },
        {
          path: "portfolio",
          element: <ProtectedRoute component={<Portfolio />} />,
          errorElement: <div>Oops! There was an error in the Portfolio.</div>,
          loader: async () => getPortfolio(),
        },
        {
          path: "settings",
          element: <ProtectedRoute component={<Settings />} />,
        },
        {
          path: "strategy-definition/:id",
          element: (
            <ProtectedRoute
              component={
                <StrategyDefinitionProvider>
                  <StrategyDefinitionLayout />
                </StrategyDefinitionProvider>
              }
            />
          ),
          errorElement: <div>Oops! There was an error in Strategy Definition.</div>,
          children: [
            {
              path: "project-scope",
              element: <ProtectedRoute component={<ProjectScope />} />,
              errorElement: <div>Oops! There was an error in Project Scope.</div>,
              loader: projectScopeLoader,
            },
            {
              path: "project-schedule",
              element: <ProtectedRoute component={<ProjectSchedule />} />,
              errorElement: <div>Oops! There was an error in Project Schedule.</div>,
            },
            {
              path: "project-baseline/:outcome?/:tab?",
              element: <ProtectedRoute component={<ProjectBaseline />} />,
              errorElement: <div>Oops! There was an error in Project Baseline.</div>,
              loader: async ({ params }) => {
                // This object helps us pass the appropriate types to the <ProjectBaseline /> component
                // allowing us to pass the expected data and columns types to Parametric, Cost, Risk components
                const wbsEntryBaselineData: ProjectBaselineRouteProps = {
                  parametric: [],
                  cost: [],
                  risks: [],
                };
                // checks if the user is in step 3 but has not selected an outcome yet
                if (!params.outcome || !params.id) return wbsEntryBaselineData; // needs to be before the tab check
                // checks if the user is in step 3 but has not selected a tab yet
                if (!params.tab) return redirect(baselineTab); // if no tab is selected, default to parametric

                if (params.tab === "risk") {
                  const risks = await getProjectBaseRisks(params.outcome);
                  wbsEntryBaselineData.risks = risks;
                }
                if (params.tab === "cost") {
                  wbsEntryBaselineData.cost = await getCostDataService(
                    params.id,
                    params.outcome
                  );
                }
                const parameters: ParameterModels[] = await getProjectBaselineParameters(
                  params.outcome
                );

                wbsEntryBaselineData.parametric = parameters;

                return wbsEntryBaselineData;
              },
            },
            {
              path: "project-scenarios/:outcome?/:tab?",
              element: <ProtectedRoute component={<ProcurementScenarios />} />,
              errorElement: <div>Oops! There was an error in Procurement Scenarios.</div>,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];
}
