/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison -- many errors not worth fixing since this feature needs to be refactored */
import ParametricCostTypes from "@/shared/enums/parametricCostTypes";
import { Units } from "@/shared/enums/units";

import { ParameterModels } from "../../../shared/types/parameterModels";

const parameterDefaultTableSchema: ParameterModels[] = [
  {
    wbsEntryId: "",
    id: undefined,
    order: 1,
    name: ParametricCostTypes.Outcome,
    unit: Units.Unit,
    quantity: 0,
  },
  {
    wbsEntryId: "",
    id: undefined,
    order: 0,
    name: ParametricCostTypes.Duration,
    unit: Units.Day,
    quantity: 0,
  },
  {
    wbsEntryId: "",
    id: undefined,
    order: 3,
    name: ParametricCostTypes.Emissions,
    unit: Units.tCO2e,
    quantity: 0,
  },
];

/**
 * This function returns the default rows for a given level.
 *
 * @param {boolean} includeOnlyOutcome - A flag indicating whether to include only outcome parameters.
 *
 * @returns {ParameterModels[]} - The default rows for the level.
 *
 * The function works as follows:
 * If `includeOnlyOutcome` is true, it filters the `parameterDefaultTableSchema` array to include only the rows where the `name` property is equal to `ParametricCostTypes.Outcome`. This is done using the `filter` method of the array, which creates a new array with all elements that pass the test implemented by the provided function.
 * If `includeOnlyOutcome` is false, it returns the entire `parameterDefaultTableSchema` array.
 *
 * This allows the function to return either all default rows or only the outcome rows based on the `includeOnlyOutcome` flag.
 */
const getDefaultRowsForLevel = (includeOnlyOutcome: boolean): ParameterModels[] => {
  if (includeOnlyOutcome) {
    return parameterDefaultTableSchema.filter(
      (row) => row.name === ParametricCostTypes.Outcome
    );
  }
  return parameterDefaultTableSchema;
};

/**
 * This function checks if a given row exists in the parameters array.
 *
 * @param {ParameterModels[]} parameters - The existing parameters in the table.
 * @param {ParameterModels} row - The row to check for existence.
 *
 * @returns {boolean} - Returns true if the row exists in the parameters, false otherwise.
 *
 * The function works as follows:
 * It uses the `some` method of the `parameters` array to check if there is any parameter that matches the given row.
 * The `some` method tests each parameter in the array using a test function. The test function returns true if the `name` property of the parameter is equal to the `name` property of the `row`.
 * If the `some` method finds a parameter that passes the test, it returns true, indicating that the row exists in the parameters. If no parameter passes the test, it returns false, indicating that the row does not exist in the parameters.
 */
const rowExistsInParameters = (
  parameters: ParameterModels[],
  row: ParameterModels
): boolean => {
  return parameters.some((param) => param.name === row.name);
};

/**
 * Initializes a parametric table with default rows based on the level.
 *
 * @param {ParameterModels[]} parameters - Existing parameters in the table.
 * @param {string | undefined} wbsEntryId - WBS entry ID for the table.
 * @param {boolean} includeOnlyOutcome - Flag to include only outcome parameters.
 * @returns {ParameterModels[]} - Parameters including the default rows.
 *
 * The function gets default rows, initializes `parametersData` with existing parameters, adds missing default rows associated with `wbsEntryId`, and returns the updated `parametersData`.
 */
export const initializingParametricTable = (
  parameters: ParameterModels[],
  wbsEntryId: string | undefined,
  includeOnlyOutcome: boolean
): ParameterModels[] => {
  // Get the default rows for the according level
  const defaultRowsForLevel = getDefaultRowsForLevel(includeOnlyOutcome);
  // initialize results array
  const parametersData = [...parameters];
  // iterating over the default rows and adding them to the parameters if they are missing
  defaultRowsForLevel.forEach((rowInSchema) => {
    if (!rowExistsInParameters(parameters, rowInSchema)) {
      parametersData.push({ ...rowInSchema, wbsEntryId });
    }
  });
  return parametersData;
};

/**
 * Retrieves a parameter from the parameters array by its name.
 *
 * @param {ParameterModels[]} parameters - Parameters in the table.
 * @param {number} name - Name of the parameter to retrieve.
 * @returns {ParameterModels} - Parameter with the given name.
 *
 * The function uses the `find` method to locate the parameter. The `!` operator asserts that a parameter with the given name will always exist.
 * If a parameter might not exist, remove the `!` operator to avoid potential runtime errors.
 */
export const getParameterToUpdateByName = (
  parameters: ParameterModels[],
  name: number
): ParameterModels => {
  return parameters.find((param) => param.name === name)!;
};

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} string - Input string.
 * @returns {string} - String with first letter capitalized.
 *
 * The function converts the first character to uppercase and appends the rest of the string.
 */
export const updateParameterColumn = (
  parameterToUpdate: ParameterModels,
  columnId: string,
  newCellValue: number | string
): ParameterModels => {
  return {
    ...parameterToUpdate,
    [columnId]: newCellValue, // Only update the specific field that was edited
  };
};

/**
 * Sorts an array of parameters based on their names.
 *
 * @param {ParameterModels[]} parameters - The parameters to sort.
 * @returns {ParameterModels[]} - The sorted parameters.
 *
 * The function creates a copy of `parameters` and sorts it. The sort order is defined by a compare function:
 * - `ParametricCostTypes.Outcome` comes first,
 * - followed by `ParametricCostTypes.Duration`,
 * - all other parameters are considered equal.
 */
export const sortParameters = (parameters: ParameterModels[]) => {
  return [...parameters].sort((a, b) => {
    if (a.name === ParametricCostTypes.Outcome) return -1;
    if (b.name === ParametricCostTypes.Outcome) return 1;
    if (a.name === ParametricCostTypes.Duration) return -1;
    if (b.name === ParametricCostTypes.Duration) return 1;
    return 0;
  });
};

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} string - The string to capitalize.
 * @returns {string} - The string with the first letter capitalized.
 *
 * The function gets the first character, converts it to uppercase, and concatenates it with the rest of the string.
 */
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
