/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison -- many errors not worth fixing since this feature needs to be refactored */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

import ParametricCostTypes from "@/shared/enums/parametricCostTypes";
import {
  OutcomeUnitsNames,
  EmissionUnitsNames,
  DurationUnitsNames,
  Units,
} from "@/shared/enums/units";
// Helper function to check if a unit is disabled
const isUnitDisabled = (unit: Units) => {
  const disabledUnits = [
    Units.Hour,
    Units.Minute,
    Units.Month,
    Units.Week,
    Units.Year,
    Units.CO2e,
    Units.ktCO2e,
    Units.MtCO2e,
    Units.GtCO2e,
  ];
  return disabledUnits.includes(unit);
};

type DropdownUnitProps = {
  selectedValue: number;
  parameterName: number;
  onUnitChanged: (newUnit: number) => void;
  // Add any other props that DropdownUnit expects
};

export default function DropdownUnit({
  selectedValue,
  parameterName,
  onUnitChanged,
}: DropdownUnitProps) {
  // const [selectedItem, setSelectedItem] = React.useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const getUnitMapping = () => {
    switch (parameterName) {
      case ParametricCostTypes.Duration:
        return DurationUnitsNames;
      case ParametricCostTypes.Emissions:
        return EmissionUnitsNames;
      default:
        return OutcomeUnitsNames;
    }
  };
  const unitMapping = getUnitMapping();
  const displayedValue = unitMapping.get(selectedValue);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: number) => {
    onUnitChanged(value);
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        // @todo We need to make a global style for all buttons to use pascal case
        style={{ textTransform: "none" }}>
        {displayedValue}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        // @todo - When we have unit conversion in our API we can remove this check
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            maxHeight: "300px",
            overflow: "scroll",
            "&::-webkit-scrollbar": {
              width: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.1)",
              borderRadius: "20px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgba(0,0,0,.1)",
            },
          },
        }}>
        {Array.from(unitMapping.entries())
          .sort((a, b) => a[1].localeCompare(b[1]))
          .map(([value, label]) => (
            <MenuItem
              key={value}
              disabled={isUnitDisabled(value)}
              onClick={() => handleMenuItemClick(value)}>
              {label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
