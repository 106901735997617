export const sideMenuStyles = {
  box: {
    display: "flex",
    flexDirection: "column",
    height: "100%", // Corrected typo from "hight" to "height"
  },
  listItem: {
    marginBottom: "1rem",
    paddingLeft: "0.5rem",
  },
  listItemText: {
    marginLeft: "1rem",
  },

  profileName: {
    fontSize: "1.4rem",
    marginTop: "0.5rem",
  },
  roleName: {
    display: "inline",
    color: "var(--ecerto-cool-grey)",
  },
  divider: {
    borderWidth: "0.12rem",
    marginBottom: "1rem",
  },
  logOutBox: {
    marginTop: "auto",
    marginBottom: "1rem",
  },
  logOutButton: {
    color: "#c66e4e",
    border: "0.1rem solid #c66e4e",
    textTransform: "none",
    marginLeft: "0.5rem",
    "&:hover": {
      backgroundColor: "rgba(198, 110, 78, 0.1)",
      border: "0.1rem solid #c66e4e",
    },
  },
};
