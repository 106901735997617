import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function SidebarSkeleton() {
  const skellyStyles = {
    bgcolor: "var(--primary-color-light)",
    "&::after": {
      bgcolor: "text.primary",
    },
    marginBottom: 2,
  };

  return (
    <Box sx={{ width: "100%" }} mx={0.5} my={0} mt="1.2rem">
      <Skeleton sx={skellyStyles} variant="rounded" height={43} />
      <Skeleton sx={skellyStyles} variant="text" height={40} />
      <Skeleton sx={skellyStyles} variant="rounded" height={40} />
      <Box sx={{ ...skellyStyles, borderRadius: "4px 4px 0 0" }} component="div">
        <Skeleton variant="rectangular" height={29} />
      </Box>
      <Skeleton sx={skellyStyles} variant="text" height={20} width="90%" />
      <Skeleton sx={skellyStyles} variant="text" height={20} width="80%" />
      <Skeleton sx={skellyStyles} variant="text" height={20} width="85%" />
      <Skeleton sx={skellyStyles} variant="text" height={20} width="75%" />
      <Box sx={{ ...skellyStyles, borderRadius: "0 0 4px 4px" }} component="div">
        <Skeleton variant="rectangular" height={29} />
      </Box>
      <>
        <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
          <Skeleton sx={skellyStyles} variant="circular" width={28} height={22} />
          <Skeleton
            sx={{ ...skellyStyles, mx: 2.3 }}
            variant="text"
            width="60%"
            height={20}
          />
          <Skeleton sx={skellyStyles} variant="text" width="40%" height={20} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Skeleton sx={skellyStyles} variant="circular" width={24} height={24} />
          <Skeleton
            sx={{ ...skellyStyles, mx: 2.3 }}
            variant="text"
            width="60%"
            height={20}
          />
          <Skeleton sx={skellyStyles} variant="text" width="40%" height={20} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Skeleton sx={skellyStyles} variant="circular" width={24} height={24} />
          <Skeleton
            sx={{ ...skellyStyles, mx: 2.3 }}
            variant="text"
            width="60%"
            height={20}
          />
          <Skeleton sx={skellyStyles} variant="text" width="40%" height={20} />
        </Box>
      </>
    </Box>
  );
}
